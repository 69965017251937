import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API,{setAuthToken} from '../../server/api';
import * as ApiRouter from '../../server/apiRoutes';
import API2,{setAuthToken2} from '../../server/api2';
export const getModelList = createAsyncThunk(
  'model/getModelList',
  async () => {
     const result =  await API.get(ApiRouter.getModelList);
     console.log("result",JSON.stringify(result))
     return {result:result.data}
  }              
);
export const createModel = createAsyncThunk(
  'model/createModel',
  async (body) => {
    console.log("body",body);
    const formdata = new FormData();
    formdata.append("campaign", body.campaign);
    formdata.append("name", body.name);
    formdata.append("train_data", body.train_data);
     const result =  await API2.post(ApiRouter.getModelList,formdata);
     console.log("result",JSON.stringify(result))
     return {result:result.data}
  }              
);
export const generateModel = createAsyncThunk(
  'model/generateModel',
  async (data) => {
   
    console.log("result generateModel",ApiRouter.generateModel(data.id))
     const result =  await API2.post(ApiRouter.generateModel(data.id));
     console.log("result generateModel",JSON.stringify(result))
     return {result:result.data}
  }              
);

export const updateModel = createAsyncThunk(
  'model/updateModel',
  async (data) => {
    console.log("body",data);
    const formdata = new FormData();
    formdata.append("campaign", data.body.campaign);
    formdata.append("name", data.body.name);
    formdata.append("train_data", data.body.train_data);
     const result =  await API2.put(ApiRouter.updateModel(data.id),formdata);
     console.log("result",JSON.stringify(result))
     return {result:result.data}
  }              
);

export const deleteModel = createAsyncThunk(
  'model/deleteModel',
  async (modelId) => {
     const result =  await API2.delete(ApiRouter.deleteModel(modelId));
     console.log("result generateModel",JSON.stringify(result))
     return {result:result.data}
  }              
);



const modelSlice = createSlice({
  name: 'model',
  initialState: {
    modelLoader: false,
    modelFullList:[],
    createModelStatus:"",
    createModelMessage:"",
    deleteModelStatus:"",
    deleteModelMessage:"",
    modelListStatus:""

  },
  reducers: {
  cleanCreateModel:(state,action)=>{
   state.createModelStatus="";
   state.createModelMessage="";
  },
  clearDeleteModel:(state,action)=>{
    state.deleteModelStatus="";
    state.deleteModelMessage="";
   },
   clearModelListStatus:(state,action)=>{
    state.modelListStatus="";
   },

  },
  extraReducers: (builder) => {


    builder.addCase(getModelList.pending, (state) => {
      state.modelLoader = true;
    });
    builder.addCase(getModelList.fulfilled, (state, action) => {
      state.modelLoader = false;
       state.modelFullList = action.payload.result;
       state.modelListStatus ="success";
    });
    builder.addCase(getModelList.rejected, (state, action) => {
      state.modelLoader = false;
      state.modelListStatus ="failed";
    });
    builder.addCase(createModel.pending, (state) => {
      state.modelLoader = true;
    });
    builder.addCase(createModel.fulfilled, (state, action) => {
      state.modelLoader = false;
       state.createModelStatus = "success";
    });
    builder.addCase(createModel.rejected, (state, action) => {
      state.modelLoader = false;
      state.createModelStatus = "failed";
      state.createModelMessage = action.error.message;
      console.log("result",action.error)
    });


    builder.addCase(generateModel.pending, (state) => {
      state.modelLoader = true;
    });
    builder.addCase(generateModel.fulfilled, (state, action) => {
      state.modelLoader = false;
       state.createModelStatus = "success";
    });
    builder.addCase(generateModel.rejected, (state, action) => {
      console.log("action.error",action);
      state.modelLoader = false;
      state.createModelStatus = "failed";
      state.createModelMessage = action.error.message;
    });


    builder.addCase(updateModel.pending, (state) => {
      state.modelLoader = true;
    });
    builder.addCase(updateModel.fulfilled, (state, action) => {
      state.modelLoader = false;
       state.createModelStatus = "success";
    });
    builder.addCase(updateModel.rejected, (state, action) => {
      console.log("action.error",action);
      state.modelLoader = false;
      state.createModelStatus = "failed";
      state.createModelMessage = action.error.message;
    });

    builder.addCase(deleteModel.pending, (state) => {
      state.modelLoader = true;
    });
    builder.addCase(deleteModel.fulfilled, (state, action) => {
      state.modelLoader = false;
       state.deleteModelStatus = "success";
    });
    builder.addCase(deleteModel.rejected, (state, action) => {
      console.log("action.error",action);
      state.modelLoader = false;
      state.deleteModelStatus = "failed";
      state.deleteModelMessage = action.error.message;
    });
  },
});
export const {
  cleanCreateModel,
  clearDeleteModel,
  clearModelListStatus
} = modelSlice.actions;
export default modelSlice.reducer;
