export const Login = "/";
export const Home = "/process";
export const agent = "/process/agent";
export const Campain = "/process/campain";
export const Executor = "/process/executor";
export const Model = "/process/model";
export const Constructor = "/process/constructor";
export const Manifest = "/process/Manifest"; 
export const ManifestResult = "/process/ManifestResult"; 
export const Profile = "/process/Profile"; 
export const Organization = "/process/OrganizationHome"; 



