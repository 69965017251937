import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API,{setAuthToken} from '../../server/api';
import * as ApiRouter from '../../server/apiRoutes'

export const getExecutorList = createAsyncThunk(
  'executor/getExecutorList',
  async () => {
     const result =  await API.get(ApiRouter.getExecutorList);
     console.log("result",JSON.stringify(result))
     return {result:result.data}
  }              
);

export const createModel = createAsyncThunk(
  'executor/createModel',
  async (body) => {
    console.log(body);
     const result =  await API.post(ApiRouter.getExecutorList,JSON.stringify(body));
     console.log("result",JSON.stringify(result))
     return {result:result.data}
  }              
);
export const updateExecutor = createAsyncThunk(
  'executor/updateModel',
  async (data) => {
    console.log(data);
     const result =  await API.put(ApiRouter.updateExecutor(data.id),JSON.stringify(data.body));
     console.log("result",JSON.stringify(result))
     return {result:result.data}
  }              
);

export const deleteExecutor = createAsyncThunk(
  'executor/deleteExecutor',
  async (executorID) => {
     const result =  await API.delete(ApiRouter.updateExecutor(executorID));
     console.log("result",JSON.stringify(result))
     return {result:result.data}
  }              
);



const executorSlice = createSlice({
  name: 'executor',
  initialState: {
    executorLoader: false,
    executorFullList:[],
    createExecutorMessage:"",
    createExecutorState:"",
    deleteExecutorMessage:"",
    deleteExecutorStatus:"",
    executorFullState:"",
    executorFullMessage:""

  },
  reducers: {
  cleanOrganizationListForAddAgentStatus:(state,action)=>{
   state.organizationListForAddAgentStatus="";
   state.organizationListForAddAgentMessage="";
  },
  clearCreateExecutor:(state)=>{
    state.createExecutorMessage="";
    state.createExecutorState="";
   },
   clearDeleteExecutor:(state)=>{
    state.deleteExecutorMessage="";
    state.deleteExecutorStatus="";
   },
   clearExecutorFull:(state)=>{
    state.executorFullState="";
    state.executorFullMessage="";
   }
 
  },
  extraReducers: (builder) => {


    builder.addCase(getExecutorList.pending, (state) => {
      state.executorLoader = true;
    });
    builder.addCase(getExecutorList.fulfilled, (state, action) => {
      state.executorLoader = false;
       state.executorFullList = action.payload.result;
       state.executorFullState="success";
  
    });
    builder.addCase(getExecutorList.rejected, (state, action) => {
      state.executorLoader = false;
      state.executorFullState="failed";
    });

    builder.addCase(createModel.pending, (state) => {
      state.executorLoader = true;
    });
    builder.addCase(createModel.fulfilled, (state, action) => {
      state.executorLoader = false;
       state.createExecutorState = "success";
    });
    builder.addCase(createModel.rejected, (state, action) => {
      state.executorLoader = false;
      state.createExecutorState = "failed";
      state.createExecutorMessage = action.error.message;
    });

    builder.addCase(updateExecutor.pending, (state) => {
      state.executorLoader = true;
    });
    builder.addCase(updateExecutor.fulfilled, (state, action) => {
      state.executorLoader = false;
       state.createExecutorState = "success";
    });
    builder.addCase(updateExecutor.rejected, (state, action) => {
      state.executorLoader = false;
      state.createExecutorState = "failed";
      state.createExecutorMessage = action.error.message;
    });
    builder.addCase(deleteExecutor.pending, (state) => {
      state.executorLoader = true;
    });
    builder.addCase(deleteExecutor.fulfilled, (state, action) => {
      state.executorLoader = false;
       state.deleteExecutorStatus = "success";
    });
    builder.addCase(deleteExecutor.rejected, (state, action) => {
      state.executorLoader = false;
      state.deleteExecutorStatus = "failed";
      state.deleteExecutorMessage = action.error.message;
    });

    
  },
});
export const {
  cleanOrganizationListForAddAgentStatus,
  clearCreateExecutor,
  clearDeleteExecutor,
  clearExecutorFull
} = executorSlice.actions;
export default executorSlice.reducer;
