import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './auth';
import agentReducer from './agent';
import organizationReducer from './organization';
import campaignReducer from './campaign';
import executorReducer from './executor';
import modelReducer from './model';
import constructordataReducer from './constructordata';
import manifestReducer from './manifest';
import manifestresultReducer from './manifestResult';

const rootReducer = combineReducers({
  auth:authReducer,
  agent: agentReducer,
  organization:organizationReducer,
  campaign:campaignReducer,
  executor: executorReducer,
  model:modelReducer,
  constructordata:constructordataReducer,
  manifest:manifestReducer,
  manifestresult:manifestresultReducer
});
export default rootReducer;
 