import React, { useState, useMemo, useEffect } from "react";
import './styles.css';
import { encode } from 'js-base64';
import { Link, useNavigate } from "react-router-dom";
import * as Routing from '../../routes/routes';

import {
    Stack,
    Button,
    Container,
    Row,
    Col,
    ThemeProvider
} from 'react-bootstrap';
import { Loader ,DialogBox,Toaster} from '../../component';
import { useDispatch, useSelector } from 'react-redux';
import {
    login,
    cleanLoginStatus,
    setLoggedIn
} from '../../redux/reducers/auth';
import { toast } from 'react-toastify';


function Login() {
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const authStore = useSelector((state) => state.auth);
    const {
        authLoader,
        loginStatus,
        loginMessage
    } = authStore;
    const [loader, setLoader] = useState(false);
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    useEffect(() => {
        if (loginStatus == "success") {
            dispatch(cleanLoginStatus());
            dispatch(setLoggedIn(true));
            toast.success(`Login Successfully`);
            navigation(Routing.Home);
        } else if (loginStatus == "failed") {
            toast.error(`${loginMessage}`);
            dispatch(cleanLoginStatus());   
        }
    }, [loginStatus])

    const loginFunc = () => {
        console.log(userName, password);
        let encodeData = encode(`${userName}:${password}`);
        dispatch(login(`${encodeData}`));
       
    }



    return (
        <div class="container">
           


            <div class="screen">
                <div class="screen__content">

                    <form class="login">
                        <div class="login__field">


                            <h3>DevTel A.I</h3>
                        </div>
                        <div class="login__field">
                            <i class="login__icon fas fa-user"></i>
                            <input
                                type="text"
                                class="login__input"
                                placeholder="User name"
                                id="usernamedevtel"
                                value={userName}
                                onChange={(e) => {
                                    setUserName(e.target.value);
                                }}
                            />
                        </div>
                        <div class="login__field">
                            <i class="login__icon fas fa-lock"></i>
                            <input
                                type="password"
                                class="login__input"
                                placeholder="Password"
                                id="passworddevtel"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                            />
                        </div>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                loginFunc();
                                // navigation(Routing.Home)
                                // setLoader(!loader);     
                            }}
                            class="button login__submit">
                            <span class="button__text">Log In Now</span>
                            {/* <i class="button__icon fas fa-chevron-right"></i> */}
                        </button>

                    </form>
                    <div class="social-login">
                        <h3></h3>

                    </div>
                </div>
                <div class="screen__background">
                    <span class="screen__background__shape screen__background__shape4"></span>
                    <span class="screen__background__shape screen__background__shape3"></span>
                    <span class="screen__background__shape screen__background__shape2"></span>
                    <span class="screen__background__shape screen__background__shape1"></span>
                </div>
            </div>
            <Loader active={authLoader} title={"Authenticating"} />
            {/* <DialogBox/> */}
            <Toaster/>
        </div>
    );
}

export default Login;
