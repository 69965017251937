import React, { useState, useEffect } from "react";

import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import './index.css';
import {
    Title,
    SearchContainerStyle,
    SearchInputText,
    TableHeader,
    TableData,
    CreateAgent,
    IconMenuTitle
} from './styles';
import { colors } from '../../theme';
import {
    SupportAgentOutlined,
    Search,
    GroupAdd,
    Edit, Delete
} from '@mui/icons-material';
import { Loader, DialogBox, Toaster } from '../../component';
import { useSelector, useDispatch } from 'react-redux';
import {
    getAllAgentList,
    addAgent,
    cleanaddAgentStatus,
    updateAgent,
    cleanDeleteAgentStatus,
    deleteAgent,
    clearAgentFullListStatus
} from '../../redux/reducers/agent';
import {
    getOrganizationList,
    cleanOrganizationListForAddAgentStatus
} from '../../redux/reducers/organization';
import moment from "moment";
import CreateAgentDialog from "./createAgentDialog";
import { toast } from 'react-toastify';
const AgentHome = () => {
    const dispatch = useDispatch();
    const agentStore = useSelector((state) => state.agent);
    const organizationStore = useSelector((state) => state.organization);
    const {
        agentLoader,
        agentFullList,
        addAgentStatus,
        addAgentMessage,
        deleteAgentStatus,
        deleteAgentMessage,
        agentFullListStatus
    } = agentStore;
    const {
        organizationLoader,
        organizationFullList,
        organizationListForAddAgentStatus,
        organizationListForAddAgentMessage
    } = organizationStore;

    const [count, setCount] = useState(0);
    const [agentSearchText, setAgentSearchText] = useState("");
    const [showCreateAgentDialog, setShowCreateAgentDialog] = useState(false);
    const [showOrganizationSelection, setShowOrganizationSelection] = useState(false);
    const [selectedOrganizationDetails, setSelectedOrganizationDetails] = useState({});
    const [localOrganizationFullList, setLocalOrganizationFullList] = useState([]);
    const [addAgentName, setAddAgentName] = useState("");
    const [showAddAgentConfimation, setShowAddAgentConfimation] = useState(false);
    const [isForUpdateAgent, setIsForUpdateAgent] = useState(false);
    const [selectedItemForUpdate, setSelectedItemForUpdate] = useState({});
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);
    const [localAgentList, setLocalAgentList] = useState([]);
    useEffect(() => {
        dispatch(getAllAgentList());
    }, []);
    useEffect(() => {
        if (organizationListForAddAgentStatus == "success") {
            setLocalOrganizationFullList([...organizationFullList]);
            setShowCreateAgentDialog(!showCreateAgentDialog);
            dispatch(cleanOrganizationListForAddAgentStatus());
        } else if (organizationListForAddAgentStatus == "failed") {
            toast.error("Orgnization is not found");
            dispatch(cleanOrganizationListForAddAgentStatus());
        }
    }, [organizationListForAddAgentStatus]);
    useEffect(() => {
        if (addAgentStatus == "success") {
            toast.success(isForUpdateAgent ? `Update Successfully` : `Added Successfully`);
            dispatch(getAllAgentList());
            dispatch(cleanaddAgentStatus());
        } else if (addAgentStatus == "failed") {
            toast.error(`${addAgentMessage}`);
            dispatch(cleanaddAgentStatus());
        }
    }, [addAgentStatus]);
    useEffect(() => {
        if (deleteAgentStatus == "success") {
            toast.success(`Deleted Successfully`);
            dispatch(getAllAgentList());
            dispatch(cleanDeleteAgentStatus());
        } else if (deleteAgentStatus == "failed") {
            toast.error(`${deleteAgentMessage}`);
            dispatch(cleanDeleteAgentStatus());
        }
    }, [deleteAgentStatus]);
    useEffect(() => {
        if (agentFullListStatus == "success") {
            setLocalAgentList([...agentFullList]);
            dispatch(clearAgentFullListStatus());
        } else if (agentFullListStatus == "failed") {
           
            dispatch(clearAgentFullListStatus());
        }
    }, [agentFullListStatus]);
    
    


    const closeCreateAgentDialog = () => {
        setShowCreateAgentDialog(!showCreateAgentDialog);
    }
    const handleFilterOrganization = (text) => {
        if (text !== "") {
            let searchedOrganizationList = organizationFullList.filter((item) =>
                item.name.toLowerCase().includes(text.toLowerCase()) ? item : null
            );
            setLocalOrganizationFullList([...searchedOrganizationList]);

        } else {
            setLocalOrganizationFullList([...organizationFullList]);
        }
    };
    const handleSearch = (text) => {
        setAgentSearchText(text);
        if (text !== "") {
            let searchedAgentList = agentFullList.filter((item) =>
                item.name.toLowerCase().includes(text.toLowerCase()) ? item : null
            );
            setLocalAgentList([...searchedAgentList]);

        } else {
            setLocalAgentList([...agentFullList]);
        }
    }

    return (
        <div>
            <Container fluid style={{
                backgroundColor: colors.backgroundColorTheme,
                height: '100vh',
                overflow:"auto"
            }}>

                <Row >

                </Row>
                <Row style={{ marginTop: 20, marginLeft: 20 }}>

                    <Col  >
                        <SupportAgentOutlined
                            style={{ width: 80, height: 80, color: "#fff" }} />
                        <Title >Agents</Title>
                    </Col>
                    <Col style={{ textAlign: "right" }} >
                        <SearchContainerStyle

                        >
                            <Search style={{ width: 28, height: 28, color: "#fff" }} />
                            <SearchInputText
                                id="agentSearch"
                                type="text" value={agentSearchText}
                                onChange={(e) => { 
                                    console.log("mani")
                                    handleSearch(e.target.value)
                                 }}
                                placeholder="Search agent..."
                                className="agentSearchClass"

                            />
                        </SearchContainerStyle>
                    </Col>


                </Row>
                <Row style={{ margin: 20 }}>
                    <Col style={{ textAlign: "right" }} onClick={() => {
                        // setShowCreateAgentDialog(!showCreateAgentDialog);
                        dispatch(getOrganizationList());
                        setSelectedOrganizationDetails({});
                        setAddAgentName("");
                        setIsForUpdateAgent(false);

                    }} >
                        <GroupAdd
                            style={{ width: 24, height: 24, color: colors.activeBlue }} />
                        <CreateAgent >Create Agent</CreateAgent>
                    </Col>
                </Row>
                <Row >
                    <div class="row" >
                        <div class="col-xs-12">
                            <div class="table-responsive" data-pattern="priority-columns">
                                <table
                                    summary="This table shows how to create responsive tables using RWD-Table-Patterns' functionality"
                                    class="table table-bordered table-hover"
                                >

                                    <thead>
                                        <tr style={{ backgroundColor: colors.inputBackgroundColor }}>
                                            <TableHeader>ID</TableHeader>
                                            <TableHeader data-priority="2">Name</TableHeader>
                                            <TableHeader data-priority="1">ORG Code</TableHeader>
                                            <TableHeader data-priority="2">Created</TableHeader>
                                            <TableHeader data-priority="1">Action</TableHeader>

                                        </tr>
                                    </thead>
                                  
                                    <tbody >
                                     
                                        {localAgentList.map((data, i) => {
                                            return (
                                                <tr>
                                                    <TableData>{data.id}</TableData>
                                                    <TableData>{data.name}</TableData>
                                                    <TableData>{data.organization}</TableData>
                                                    <TableData>{moment(data.created_at).format('LLL')}</TableData>
                                                    <TableData
                                                    // onClick={()=>{
                                                    //     dispatch(getOrganizationList());
                                                    //     setSelectedOrganizationDetails({});
                                                    //     setAddAgentName("");
                                                    // }}
                                                    >
                                                        <Col>

                                                            <Edit
                                                                onClick={() => {
                                                                    setIsForUpdateAgent(true);
                                                                    dispatch(getOrganizationList());
                                                                    setSelectedOrganizationDetails({ id: data.organization, name: data.organization });
                                                                    setAddAgentName(`${data.name}`);
                                                                    setSelectedItemForUpdate(data);

                                                                }}
                                                                style={{ width: 20, height: 20, color: "orange" }} />
                                                            <IconMenuTitle
                                                                onClick={() => {
                                                                    setIsForUpdateAgent(true);
                                                                    dispatch(getOrganizationList());
                                                                    setSelectedOrganizationDetails({ id: data.organization, name: data.organization });
                                                                    setAddAgentName(`${data.name}`);
                                                                    setSelectedItemForUpdate(data);
                                                                }}
                                                                style={{ color: "orange" }}
                                                            >Edit</IconMenuTitle>
                                                            <Delete
                                                                onClick={() => {
                                                                    setShowDeleteWarning(true);
                                                                    setSelectedItemForUpdate(data);
                                                                }}

                                                                style={{ width: 20, height: 20, color: colors.FailedTheme, marginLeft: 20 }} />
                                                            <IconMenuTitle
                                                                onClick={() => {
                                                                    console.log("Data",data);
                                                                    setShowDeleteWarning(true);
                                                                    setSelectedItemForUpdate(data);
                                                                }}
                                                                style={{ color: colors.FailedTheme }}
                                                            >Remove</IconMenuTitle>

                                                        </Col>




                                                    </TableData>

                                                </tr>
                                            );
                                        })}


                                    </tbody>

                                </table>
                                {localAgentList.length == 0 ? 
                                        
                                        <center> <label style={{marginTop:5,color:colors.inputBackgroundColor,fontWeight:"bolder"}}>Agent not found</label></center>:null}
                            </div>
                        </div>
                    </div>



                </Row>


            </Container>
            <Loader active={agentLoader || organizationLoader} title="Loading..." />
            <CreateAgentDialog
                active={showCreateAgentDialog}
                handleClose={closeCreateAgentDialog}
                showOrganizationSelection={showOrganizationSelection}
                setShowOrganizationSelection={(value) => { setShowOrganizationSelection(value) }}
                organizationFullList={organizationFullList}
                selectedOrganizationDetails={selectedOrganizationDetails}
                setSelectedOrganizationDetails={(value) => { setSelectedOrganizationDetails(value) }}
                localOrganizationFullList={localOrganizationFullList}
                title={isForUpdateAgent ? "Update Agent" : "Add Agent"}
                handleFilterOrganization={handleFilterOrganization}
                setAddAgentName={(value) => { setAddAgentName(value) }}
                addAgentName={addAgentName}
                setShowAddAgentConfimation={() => {
                    if (addAgentName && selectedOrganizationDetails) {
                        setShowAddAgentConfimation(true);

                    } else {
                        toast.error("Empty input field found!")
                    }

                }}
                isForUpdateAgent={isForUpdateAgent}

            />
            <DialogBox
                subTitle={isForUpdateAgent ? "Do you want to update?" : "Do you need to add this agent?"}
                title={"Confirm"}
                active={showAddAgentConfimation}

                twoButton={true}
                lefttButtonTitle="Yes"
                rightButtonTitle="No"
                rightButtonClick={() => {
                    setShowAddAgentConfimation(false);

                }}
                leftButtonClick={() => {
                    setShowAddAgentConfimation(false);
                    setShowCreateAgentDialog(false);
                    if (isForUpdateAgent) {
                        dispatch(updateAgent({
                            body: {
                                name: addAgentName,
                                organization: selectedOrganizationDetails.id
                            },
                            id: selectedItemForUpdate.id
                        }))
                    } else {
                        dispatch(addAgent({ name: addAgentName, organization: selectedOrganizationDetails.id }))
                    }

                }}

            />

            <DialogBox
                subTitle={"Do you want to remove agent?"}
                title={"Remove"}
                active={showDeleteWarning}

                twoButton={true}
                lefttButtonTitle="Yes"
                rightButtonTitle="No"
                rightButtonClick={() => {
                    setShowDeleteWarning(false);
                    

                }}
                leftButtonClick={() => {
                    setShowDeleteWarning(false);
                  
                    console.log("selectedItemForUpdate",selectedItemForUpdate)
                    dispatch(deleteAgent(selectedItemForUpdate.id))


                }}

            />
            <Toaster />


        </div>

    );
}

export default AgentHome;