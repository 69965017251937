import React, { useState, useEffect } from "react";

import {
    Container,
    Row,
    Col,

} from 'react-bootstrap';
import './index.css';
import {
    Title,
    SearchContainerStyle,
    SearchInputText,
    TableHeader,
    TableData,
    CreateAgent,
    IconMenuTitle
} from './styles';
import { Button } from '@mui/material';
import { colors } from '../../theme';
import {

    Campaign,
    Search,

    Edit, Delete,
    PostAdd,
    Article,
    FilterAlt
} from '@mui/icons-material';
import { Loader, DialogBox, Toaster } from '../../component';
import { useSelector, useDispatch } from 'react-redux';

import {
    getCampaignList,
    cleanCampaignListStatus,
    clearCreateCampaign,
    createCampaign,
    updateCampaign,
    clearDeleteCampaign,
    deleteCampaign,
    generateCampaign,
    clearCampaignAction,
    transcribeCampaign,

} from '../../redux/reducers/campaign';

import moment from "moment";
import FilterDialog from "./filterDialog";
import { toast } from 'react-toastify';
import ShowCampain from "./showCampaignDetails";
import CampaignAction from "./campaignActions";
import { getManifestResultList,clearManifestResultStatus } from "../../redux/reducers/manifestResult";
import { TextArea } from "../manifest/styles";
import { clearAgentFullListStatus, getAllAgentList } from "../../redux/reducers/agent";
import { clearConstructorFullListStatus, getConstructorList } from "../../redux/reducers/constructordata";
import { clearExecutorFull, getExecutorList } from "../../redux/reducers/executor";
const ManifestResultHome = () => {
    const dispatch = useDispatch();

    const manifestresultStore = useSelector((state) => state.manifestresult);
    const agentStore = useSelector((state) => state.agent);
    const campaignStore = useSelector((state) => state.campaign);
    const constructordataStore = useSelector((state) => state.constructordata);
    const executorStore = useSelector((state) => state.executor);
    const {
        manifestResultLoader,
        manifestResultList,
        manifestResultStatus
    } = manifestresultStore;

    const {
        agentLoader,
        agentFullList,
        agentFullListStatus,
    } = agentStore;
    const {
        campaignListStatus,
        campaignList,
        campaignLoader
    } = campaignStore;
    const {
        constructorLoader,
        constructorFullList,
        constructorFullListStatus
    } = constructordataStore;

    const {
        executorLoader,
        executorFullList,
        executorFullState,
    } = executorStore;



    const [agentSearchText, setAgentSearchText] = useState("");
    const [showCreateAgentDialog, setShowCreateAgentDialog] = useState(false);

    const [selectedManifestResultForMore, setSelectedManifestResultForMore] = useState({})
    const [showManifestResult, setShowManifestResult] = useState(false);
    const [showResultDialog, setshowResultDialog] = useState(false);
    const [selectedResult, setSelectedResult] = useState("");

    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const [selectedAgentForFilter, setSelectedAgentForFilter] = useState({});
    const [selectedConstructorForFilter, setSelectedConstructorForFilter] = useState({});
    const [selectedCampaignForFilter, setSelectedCampaignForFilter] = useState({});
    const [selectedExecutorForFilter, setSelectedExecutorForFilter] = useState({});
    const [localListAgent, setLocalListAgent] = useState([]);
    const [showAgentDialog, setShowAgentDialog] = useState(false);
    const [showCampaignDialog, setShowCampaignDialog] = useState(false);
    const [localListCampaign, setLocalListCampaign] = useState([]);
    const [localListConstructor, setLocalListConstructor] = useState([]);
    const [showselectConstructor, setShowselectConstructor] = useState(false);
    const [localExecutorList,setLocalExecutorList]=useState([]);
    const [showSelecteExecutor, setShowSelecteExecutor] = useState(false);
    const [localManifeastResult,setLocalManifeastResult]=useState([]);
   
                
 
    
    

    useEffect(() => {
        dispatch(getManifestResultList());
    }, []);
    useEffect(() => {
        if (manifestResultStatus == "success") {
            setLocalManifeastResult([...manifestResultList]);
            
            dispatch(clearManifestResultStatus());
        } else if (manifestResultStatus == "failed") {
            dispatch(clearManifestResultStatus());
        }
    }, [manifestResultStatus]);
    
    useEffect(() => {
        if (agentFullListStatus == "success") {
            setLocalListAgent(agentFullList);
            setShowFilterDialog(true);
            dispatch(clearAgentFullListStatus());
        } else if (agentFullListStatus == "failed") {
            dispatch(clearAgentFullListStatus());
        }
    }, [agentFullListStatus]);
    useEffect(() => {
        if (campaignListStatus == "success") {
            setLocalListCampaign(campaignList);
         
            dispatch(cleanCampaignListStatus());
            setLocalListCampaign(campaignList);
        } else if (campaignListStatus == "failed") {
            dispatch(cleanCampaignListStatus());
        }
    }, [campaignListStatus]);
    useEffect(() => {
        if (constructorFullListStatus == "success") {
            setLocalListConstructor(constructorFullList);
            dispatch(clearConstructorFullListStatus());
        } else if (constructorFullListStatus == "failed") {
            dispatch(clearConstructorFullListStatus());
        }
    }, [constructorFullListStatus]);

    useEffect(() => {
        if (executorFullState == "success") {
            setLocalExecutorList(executorFullList); 
            dispatch(clearExecutorFull());
        } else if (executorFullState == "failed") {
            dispatch(clearExecutorFull());
        }
    }, [executorFullState])
    const handleFilterAgent = (text) => {
        if (text !== "") {
            let searchedCampaignList = agentFullList.filter((item) =>
                item.name.toLowerCase().includes(text.toLowerCase()) ? item : null
            );
            setLocalListAgent([...searchedCampaignList]);

        } else {
            setLocalListAgent([...agentFullList]);
        }
    };
    const handleFilterCampaign = (text) => {
        if (text !== "") {
            let searchedCampaignList = campaignList.filter((item) =>
                item.name.toLowerCase().includes(text.toLowerCase()) ? item : null
            );
            setLocalListCampaign([...searchedCampaignList]);

        } else {
            setLocalListCampaign([...campaignList]);
        }
    };

    const handleFilterConstructor = (text) => {
        if (text !== "") {
            let searchedCampaignList = constructorFullList.filter((item) =>
                item.name.toLowerCase().includes(text.toLowerCase()) ? item : null
            );
            setLocalListConstructor([...searchedCampaignList]);

        } else {
            setLocalListConstructor([...constructorFullList]);
        }
    };
    const handleFilterExecutor = (text) => {
        if (text !== "") {
            let searchedOrganizationList = executorFullList.filter((item) =>
                item.name.toLowerCase().includes(text.toLowerCase()) ? item : null
            );
            setLocalExecutorList([...searchedOrganizationList]);

        } else {
            setLocalExecutorList([...executorFullList]);
        }
    }

    const applyFilter = () => {
    
        console.log("selectedConstructorForFilter",selectedConstructorForFilter);
        
        let obj = {}
        if (selectedAgentForFilter.id) {
            obj.agent = selectedAgentForFilter.id
        }
        if (selectedCampaignForFilter?.id) {
            obj.campaign = selectedCampaignForFilter.id
        }
        if (selectedConstructorForFilter?.id) {
            obj.constructor = selectedConstructorForFilter.id
        }
        if (selectedExecutorForFilter?.id) {
            obj.executors = selectedExecutorForFilter.id
        }
        setAgentSearchText("");
        dispatch(getManifestResultList(obj));
    }

    const clearFilter = () => {
      
        setAgentSearchText("");
        setSelectedAgentForFilter({});
        setSelectedCampaignForFilter({});
        setSelectedConstructorForFilter({});
        setSelectedExecutorForFilter({})
        dispatch(getManifestResultList());
    }

    const handleSearch = (text) => {
        setAgentSearchText(text);
        if (text !== "") {
            let searchedAgentList = manifestResultList.filter((item) =>
                item.campaign.toLowerCase().includes(text.toLowerCase()) || 
                item.agent.toLowerCase().includes(text.toLowerCase()) ||
                item.constructor.toLowerCase().includes(text.toLowerCase()) ||
                item.executors.toLowerCase().includes(text.toLowerCase()) ||
                item.result.toLowerCase().includes(text.toLowerCase())
                ? item : null
            );
            setLocalManifeastResult([...searchedAgentList]);

        } else {
            setLocalManifeastResult([...manifestResultList]);
        }
    }












    return (
        <div>
            <Container fluid style={{
                backgroundColor: colors.backgroundColorTheme,
                height: '100vh',
                overflow: "auto"
            }}>

                <Row >

                </Row>
                <Row style={{ marginTop: 20, marginLeft: 20 }}>

                    <Col  >
                        <Campaign
                            style={{ width: 80, height: 80, color: "#fff" }} />
                        <Title >Manifest Result</Title>
                    </Col>
                    <Col className="searchOutContainer" style={{ textAlign: "right", }} >


                        <SearchContainerStyle
                            style={{ float: "right", }}
                        >
                            <Search style={{ width: 28, height: 28, color: "#fff" }} />
                            <SearchInputText
                                id="agentSearch"
                                type="text" value={agentSearchText}
                                onChange={(e) => { handleSearch(e.target.value) }}
                                placeholder="Search manifest result..."
                                className="agentSearchClass"
                                onSubmit={(value) => {
                                    value.preventDefault();

                                }}

                            />


                        </SearchContainerStyle>
                        <FilterAlt
                            onClick={() => {
                                // setShowFilterDialog(!showFilterDialog);
                                dispatch(getAllAgentList());
                                dispatch(getCampaignList());
                                dispatch(getConstructorList());
                                dispatch(getExecutorList())
                            }}
                            style={{ width: 28, height: 28, color: "#fff", marginLeft: 10 }} />
                    </Col>


                </Row>

                <Row >
                    <div class="row" >
                        <div class="col-xs-12">
                            <div class="table-responsive" data-pattern="priority-columns">
                                <table
                                    summary="This table shows how to create responsive tables using RWD-Table-Patterns' functionality"
                                    class="table table-bordered table-hover"
                                >

                                    <thead>
                                        <tr style={{ backgroundColor: colors.inputBackgroundColor }}>
                                            <TableData>Agent</TableData>
                                            <TableData>Campaign</TableData>

                                            <TableData>Constructor</TableData>
                                            <TableData>Executor</TableData>
                                            <TableData>Result</TableData>

                                            <TableHeader style={{ width: 100 }}></TableHeader>

                                        </tr>
                                    </thead>

                                    <tbody >
                                        {localManifeastResult.map((data, i) => {
                                            return (
                                                <tr>
                                                    <TableData>{data.agent ? data.agent : ""}</TableData>
                                                    <TableData>{data.campaign ? data.campaign : ""}</TableData>
                                                    <TableData>{data.constructor ? data.constructor : "None"}</TableData>
                                                    <TableData scope="2"> {data.executors ? data.executors : "None"}</TableData>
                                                    <TableData
                                                        onClick={() => {
                                                            setSelectedResult(data.result);
                                                            setshowResultDialog(!showResultDialog);
                                                        }}
                                                        className="truncate"> {data.result}</TableData>
                                                    <TableData
                                                    // onClick={()=>{
                                                    //     dispatch(getOrganizationList());
                                                    //     setSelectedOrganizationDetails({});
                                                    //     setAddAgentName("");
                                                    // }}
                                                    >
                                                        <Col>

                                                            <Article
                                                                onClick={() => {
                                                                    setSelectedManifestResultForMore(data);
                                                                    setShowManifestResult(!showManifestResult);

                                                                }}
                                                                style={{ width: 20, height: 20, color: colors.activeBlue }} />

                                                            <IconMenuTitle
                                                                onClick={() => {
                                                                    setSelectedManifestResultForMore(data);
                                                                    setShowManifestResult(!showManifestResult);
                                                                }}
                                                                style={{ color: colors.activeBlue }}
                                                            >Details</IconMenuTitle>
                                                        </Col>




                                                    </TableData>

                                                </tr>
                                            );
                                        })}


                                    </tbody>

                                </table>
                            </div>
                            {localManifeastResult.length == 0 ? 
                                        
                                        <center> <label style={{marginTop:5,color:colors.inputBackgroundColor,fontWeight:"bolder"}}>Manifeast result not found</label></center>:null}
                        </div>
                    </div>



                </Row>


            </Container>
            <Loader active={manifestResultLoader || agentLoader || campaignLoader || constructorLoader || executorLoader} title="Loading..." />
            <ShowCampain
                active={showManifestResult}
                handleClose={() => { setShowManifestResult(!showManifestResult) }}
                campainDetails={selectedManifestResultForMore}
                selectedResult={selectedResult}
                showResultDialog={showResultDialog}
                setshowResultDialog={(value) => { setshowResultDialog(value) }}
                setSelectedResult={(value) => {
                    setSelectedResult(value);
                }}
            />

            <FilterDialog
                active={showFilterDialog}
                handleClose={(value) => {
                    setShowFilterDialog(false);
                }}
                title={"Filter"}
                selectedAgentForFilter={selectedAgentForFilter}
                setSelectedAgentForFilter={(value) => {
                    setSelectedAgentForFilter(value);
                }}
                selectedConstructorForFilter={selectedConstructorForFilter}
                setSelectedConstructorForFilter={(value) => {
                    setSelectedConstructorForFilter(value);
                }}
                selectedCampaignForFilter={selectedCampaignForFilter}
                setSelectedCampaignForFilter={(value) => {
                    setSelectedCampaignForFilter(value);
                }}
                selectedExecutorForFilter={selectedExecutorForFilter}
                setSelectedExecutorForFilter={(value) => {
                    setSelectedExecutorForFilter(value);
                }}
                localListAgent={localListAgent}
                setShowAgentDialog={(value) => {
                    setShowAgentDialog(value);
                }}
                showAgentDialog={showAgentDialog}
                applyFilter={applyFilter}
                localListCampaign={localListCampaign}
                handleFilterAgent={handleFilterAgent}
                showCampaignDialog={showCampaignDialog}
                setShowCampaignDialog={(value) => {
                    setShowCampaignDialog(value);
                }}
                handleFilterCampaign={handleFilterCampaign}
                localListConstructor={localListConstructor}
                handleFilterConstructor={handleFilterConstructor}
                showselectConstructor={showselectConstructor}
                setShowselectConstructor={(value) => {
                    setShowselectConstructor(value);
                }}
                localExecutorList={localExecutorList}
                handleFilterExecutor={handleFilterExecutor}
                showSelecteExecutor={showSelecteExecutor}
                setShowSelecteExecutor={(value) => {
                    setShowSelecteExecutor(value);
                }}
                clearFilter={clearFilter}
            />


            <Toaster />


        </div>

    );
}

export default ManifestResultHome;