import React, { useState, useEffect } from "react";

import {
    Container,
    Row,
    Col,

} from 'react-bootstrap';
import './index.css';
import {
    Title,
    SearchContainerStyle,
    SearchInputText,
    TableHeader,
    TableData,
    CreateAgent,
    IconMenuTitle
} from './styles';
import { Button } from '@mui/material';
import { colors } from '../../theme';
import {

    Campaign,
    Search,

    Edit, Delete,
    PostAdd,
    Article
} from '@mui/icons-material';
import { Loader, DialogBox, Toaster } from '../../component';
import { useSelector, useDispatch } from 'react-redux';

import {
    getCampaignList,
    cleanCampaignListStatus,
    clearCreateCampaign,
    createCampaign,
    updateCampaign,
    clearDeleteCampaign,
    deleteCampaign,
    generateCampaign,
    clearCampaignAction,
    transcribeCampaign,

} from '../../redux/reducers/campaign';

import moment from "moment";
import CreateCampaignDialog from "./createCampainDialog";
import { toast } from 'react-toastify';
import ShowCampain from "./showCampaignDetails";
import CampaignAction from "./campaignActions";
import { 
    clearCreateManifest, 
    clearDeleteManifest, 
    createManifest, 
    getManifestList, 
    updateManifest, 
    deleteManifest,
    clearManifestAction, 
    generateManifest,
    transcribeManifest,
    clearUpdateManifest,
    cleanManifestList
 } from "../../redux/reducers/manifest";
import { getAllAgentList, clearAgentFullListStatus } from "../../redux/reducers/agent";
import { clearConstructorFullListStatus, getConstructorList } from "../../redux/reducers/constructordata";
import ManifestOutput from "./manifestOutput";
const ManifestHome = () => {
    const dispatch = useDispatch();

    const organizationStore = useSelector((state) => state.organization);
    const campaignStore = useSelector((state) => state.campaign);
    const manifestStore = useSelector((state) => state.manifest);
    const constructordataStore = useSelector((state) => state.constructordata);
    const agentStore = useSelector((state) => state.agent);
    const {
        agentLoader,
        agentFullList,
        agentFullListStatus,
    } = agentStore;

    const [agentSearchText, setAgentSearchText] = useState("");
    const {
        constructorLoader,
        constructorFullList,
        constructorFullListStatus
    } = constructordataStore;

    const {
        campaignListStatus,
        campaignList,
        campaignLoader
    } = campaignStore;
    const {
        manifestLoader,
        manifestList,
        createManifestStatus,
        createManifestMessage,
        deleteManifestMessage,
        deleteManifestStatus,
        manifestActionStatus,
        manifestActionMessage,
        updateManifestStatus,
    updateManifestMessage,
    manifestStatus
    } = manifestStore;

    useEffect(() => {
        dispatch(getManifestList());
    }, []);
    const [showManifestDetails, setShowManifestDetails] = useState(false);
    const [selectedManifestForMoreDetails, setSelectedManifestForMoreDetails] = useState({});
    const [showTranscript, setShowTranscript] = useState(false);
    const [selectedTranscript, setSelectedTranscript] = useState("");
    const [showCreateManifest, setShowCreateManifest] = useState(false);
    const [isForUpdateManifest, setIsForUpdateManifest] = useState("");
    const [selectedConstructor, setSelectedConstructor] = useState({});
    const [selectedCampaign, setSelectedCampaign] = useState({});
    const [selectedAgent, setSelectedAgent] = useState({});
    const [localListAgent, setLocalListAgent] = useState([]);
    const [localListCampaign, setLocalListCampaign] = useState([]);
    const [showselectChampign, setShowselectChampign] = useState(false);
    const [localListConstructor, setLocalListConstructor] = useState([]);
    const [showselectConstructor, setShowselectConstructor] = useState(false);
    const [showselectAgent, setShowselectAgent] = useState(false);
    const [selectedAudioFile, setSelectedAudioFile] = useState([]);
    const [showCreateManifestWarning, setShowCreateManifestWarning] = useState(false);
    const [showDeleteManifest, setShowDeleteManifest] = useState(false);
    const [isForGenerate, setIsForGenerate] = useState(false);
    const [showManifestActionWarning, setShowManifestActionWarning] = useState(false);
    const [completedItem,setCompletedItem]=useState(0);
    const [showManifestResult,setShowManifestResult]=useState(false);
    const [localManifeastList, setLocalManifeastList] = useState([]);

  




    useEffect(() => {
        if (campaignListStatus == "success") {
            setLocalListCampaign(campaignList);
            setShowCreateManifest(!showCreateManifest);
            dispatch(cleanCampaignListStatus());
        } else if (campaignListStatus == "failed") {
            dispatch(cleanCampaignListStatus());
        }
    }, [campaignListStatus]);
    useEffect(() => {
        if (manifestStatus == "success") {
            setLocalManifeastList([...manifestList])
           dispatch(cleanManifestList())
        } else if (manifestStatus == "failed") {
            dispatch(cleanManifestList())
        }
    }, [manifestStatus]);

    
    useEffect(() => {
        if (constructorFullListStatus == "success") {
            setLocalListConstructor(constructorFullList);
            dispatch(clearConstructorFullListStatus());
        } else if (constructorFullListStatus == "failed") {
            dispatch(clearConstructorFullListStatus());
        }
    }, [constructorFullListStatus]);
    useEffect(() => {
        if (agentFullListStatus == "success") {
            setLocalListAgent(agentFullList);
            dispatch(clearAgentFullListStatus());
        } else if (agentFullListStatus == "failed") {
            dispatch(clearAgentFullListStatus());
        }
    }, [agentFullListStatus]);
    useEffect(() => {
        if (createManifestStatus == "success") {
            toast.success(isForUpdateManifest ? "Update Successfully" : `Audio ${completedItem} Create Successfully`);
            dispatch(clearCreateManifest());
            // dispatch(getManifestList());
            if(completedItem < selectedAudioFile.length){
                dispatch(createManifest({
                    constructor: selectedConstructor.id,
                    agent: selectedAgent.id,
                    campaign: selectedCampaign.id,
                    audio_file: selectedAudioFile[completedItem]
                }));
                setCompletedItem(completedItem+1);
            }else{
                dispatch(getManifestList());
            }
        } else if (createManifestStatus == "failed") {
            dispatch(clearCreateManifest());
            toast.error(`${createManifestMessage}`);
            if(completedItem < selectedAudioFile.length){
                dispatch(createManifest({
                    constructor: selectedConstructor.id,
                    agent: selectedAgent.id,
                    campaign: selectedCampaign.id,
                    audio_file: selectedAudioFile[completedItem]
                }));
                setCompletedItem(completedItem+1);
            }else{
                dispatch(getManifestList());
            }
        }
    }, [createManifestStatus]); 
    useEffect(() => {
        if (updateManifestStatus == "success") {
            toast.success("Update Successfully");
            dispatch(clearUpdateManifest());
            dispatch(getManifestList());
        } else if (updateManifestStatus == "failed") {
            dispatch(clearUpdateManifest());
            toast.error(`${createManifestMessage}`);
        }
    }, [updateManifestStatus]);
    
    useEffect(() => {
        if (deleteManifestStatus == "success") {
            toast.success("Deleted Successfully");
            dispatch(clearDeleteManifest());
            dispatch(getManifestList());
        } else if (deleteManifestStatus == "failed") {
            dispatch(clearDeleteManifest());
            toast.error(`${deleteManifestMessage}`);
        }
    }, [deleteManifestStatus]);
    useEffect(() => {
        if (manifestActionStatus == "success") {
            toast.success(isForGenerate ? "Generate Successfully" : `Transcribe Successfully`);
            dispatch(clearManifestAction());
            dispatch(getManifestList());
        } else if (manifestActionStatus == "failed") {
            dispatch(clearManifestAction());
            toast.error(`${manifestActionMessage}`);
        }
        }, [manifestActionStatus]);







    // const closeCreateAgentDialog = () => {
    //     setShowCreateAgentDialog(!showCreateAgentDialog);
    // }
    const handleFilterCampaign = (text) => {
        if (text !== "") {
            let searchedCampaignList = campaignList.filter((item) =>
                item.name.toLowerCase().includes(text.toLowerCase()) ? item : null
            );
            setLocalListCampaign([...searchedCampaignList]);

        } else {
            setLocalListCampaign([...campaignList]);
        }
    };
    const handleFilterConstructor = (text) => {
        if (text !== "") {
            let searchedCampaignList = constructorFullList.filter((item) =>
                item.name.toLowerCase().includes(text.toLowerCase()) ? item : null
            );
            setLocalListConstructor([...searchedCampaignList]);

        } else {
            setLocalListConstructor([...constructorFullList]);
        }
    };
    const handleFilterAgent = (text) => {
        if (text !== "") {
            let searchedCampaignList = agentFullList.filter((item) =>
                item.name.toLowerCase().includes(text.toLowerCase()) ? item : null
            );
            setLocalListAgent([...searchedCampaignList]);

        } else {
            setLocalListAgent([...agentFullList]);
        }
    };


    const handleCreateInManifest = () => {
        if (selectedAgent && selectedAudioFile && selectedCampaign && selectedConstructor) {
            setShowCreateManifest(!showCreateManifest);
            setShowCreateManifestWarning(!showCreateManifestWarning);
        } else {
            toast.error(`Empty Field Found!`);
        }
    }
    const handleSearch = (text) => {
        setAgentSearchText(text);
        if (text !== "") {
            let searchedManifeastList = manifestList.filter((item) =>
                item.name.toLowerCase().includes(text.toLowerCase()) ? item : null
            );
            setLocalManifeastList([...searchedManifeastList]);

        } else {
            setLocalManifeastList([...manifestList]);
        }
    }

    return (
        <div>
            <Container fluid style={{
                backgroundColor: colors.backgroundColorTheme,
                height: '100vh',
                overflow:"auto"
            }}>

                <Row >

                </Row>
                <Row style={{ marginTop: 20, marginLeft: 20 }}>

                    <Col  >
                        <Campaign
                            style={{ width: 80, height: 80, color: "#fff" }} />
                        <Title >Manifest</Title>
                    </Col>
                    <Col style={{ textAlign: "right" }} >
                        <SearchContainerStyle

                        >
                            <Search style={{ width: 28, height: 28, color: "#fff" }} />
                            <SearchInputText
                                id="agentSearch"
                                type="text" value={agentSearchText}
                                onChange={(e) => {handleSearch(e.target.value) }}
                                placeholder="Search Campaign..."
                                className="agentSearchClass"

                            />
                        </SearchContainerStyle>
                    </Col>


                </Row>
                <Row style={{ margin: 20 }}>
                    <Col style={{ textAlign: "right" }} onClick={() => {

                        setSelectedAgent({});
                        setSelectedCampaign({});
                        setSelectedConstructor({});
                        dispatch(getCampaignList())
                        dispatch(getConstructorList());
                        dispatch(getAllAgentList())
                        setIsForUpdateManifest(false);

                    }} >
                        <PostAdd
                            style={{ width: 24, height: 24, color: colors.activeBlue }} />
                        <CreateAgent >Create Manifest</CreateAgent>
                    </Col>
                </Row>
                <Row >
                    <div class="row" >
                        <div class="col-xs-12">
                            <div class="table-responsive" data-pattern="priority-columns">
                                <table
                                    summary="This table shows how to create responsive tables using RWD-Table-Patterns' functionality"
                                    class="table table-bordered table-hover"
                                >

                                    <thead>
                                        <tr style={{ backgroundColor: colors.inputBackgroundColor }}>
                                            <TableHeader>ID</TableHeader>
                                            <TableHeader data-priority="2">Name</TableHeader>
                                            <TableHeader data-priority="2">Audio</TableHeader>
                                            <TableHeader data-priority="2">Created</TableHeader>
                                            <TableHeader data-priority="1">Action</TableHeader>
                                            <TableHeader data-priority="1"></TableHeader>

                                        </tr>
                                    </thead>

                                    <tbody >
                                        {localManifeastList.map((data, i) => {
                                            return (
                                                <tr>
                                                    <TableData>{data.id}</TableData>
                                                    <TableData>{data.name}</TableData>
                                                    <TableData
                                                    // onClick={()=>{
                                                    //     setShowManifestResult(!showManifestResult);
                                                    //     setSelectedManifestForMoreDetails(data);
                                                    // }}
                                                    >
                                                      
                                                        <a href={`${data.audio_file_url}`} target="_blank" rel="noopener noreferrer">File</a>
                                                    </TableData>
                                                    <TableData>{moment(data.created_at).format('LLL')}</TableData>
                                                    <TableData>
                                                        <Col >
                                                            <Button
                                                                onClick={() => {
                                                                    setIsForGenerate(true);
                                                                    setShowManifestActionWarning(true);
                                                                    setSelectedManifestForMoreDetails(data);
                                                                }}
                                                                style={{
                                                                    color: colors.white,
                                                                    fontWeight: "bolder",
                                                                    backgroundColor: colors.generate
                                                                }}
                                                            >Generate</Button>
                                                            <Button
                                                                onClick={() => {
                                                                    setIsForGenerate(false);
                                                                    setShowManifestActionWarning(true);
                                                                    setSelectedManifestForMoreDetails(data);
                                                                }}
                                                                style={{
                                                                    color: colors.white,
                                                                    fontWeight: "bolder",
                                                                    backgroundColor: colors.transcribe,
                                                                    marginLeft: 10
                                                                }}
                                                            >Transcribe</Button>

                                                        </Col>
                                                    </TableData>
                                                    <TableData
                                                    // onClick={()=>{
                                                    //     dispatch(getOrganizationList());
                                                    //     setSelectedOrganizationDetails({});
                                                    //     setAddAgentName("");
                                                    // }}
                                                    >
                                                        <Col>

                                                            <Article
                                                                onClick={() => {
                                                                    setShowManifestDetails(!showManifestDetails);
                                                                    setSelectedManifestForMoreDetails(data);

                                                                }}
                                                                style={{ width: 20, height: 20, color: colors.activeBlue }} />

                                                            <IconMenuTitle
                                                                onClick={() => {
                                                                    setShowManifestDetails(!showManifestDetails);
                                                                    setSelectedManifestForMoreDetails(data);
                                                                }}
                                                                style={{ color: colors.activeBlue }}
                                                            >Details</IconMenuTitle>
                                                            <Edit
                                                                onClick={() => {
                                                                    setSelectedAgent({ name: data.agent, id: data.agent });
                                                                    setSelectedCampaign({ name: data.campaign, id: data.campaign });
                                                                    setSelectedConstructor({ name: data.constructor, id: data.constructor });
                                                                    dispatch(getCampaignList())
                                                                    dispatch(getConstructorList());
                                                                    dispatch(getAllAgentList())
                                                                    setIsForUpdateManifest(true);
                                                                    setSelectedManifestForMoreDetails(data);

                                                                }}
                                                                style={{ width: 20, height: 20, color: "orange", marginLeft: 20 }} />
                                                            <IconMenuTitle
                                                                onClick={() => {
                                                                    setSelectedAgent({ name: data.agent, id: data.agent });
                                                                    setSelectedCampaign({ name: data.campaign, id: data.campaign });
                                                                    setSelectedConstructor({ name: data.constructor, id: data.constructor });
                                                                    dispatch(getCampaignList())
                                                                    dispatch(getConstructorList());
                                                                    dispatch(getAllAgentList())
                                                                    setIsForUpdateManifest(true);
                                                                    setSelectedManifestForMoreDetails(data);
                                                                }}
                                                                style={{ color: "orange" }}
                                                            >Edit</IconMenuTitle>
                                                            <Delete
                                                                onClick={() => {
                                                                    setShowDeleteManifest(true);
                                                                    setSelectedManifestForMoreDetails(data);
                                                                }}

                                                                style={{ width: 20, height: 20, color: colors.FailedTheme, marginLeft: 20 }} />
                                                            <IconMenuTitle
                                                                onClick={() => {
                                                                    setShowDeleteManifest(true);
                                                                    setSelectedManifestForMoreDetails(data);
                                                                }}
                                                                style={{ color: colors.FailedTheme }}
                                                            >Remove</IconMenuTitle>

                                                        </Col>




                                                    </TableData>

                                                </tr>
                                            );
                                        })}


                                    </tbody>

                                </table>
                            </div>
                            {localManifeastList.length == 0 ? 
                                        
                                        <center> <label style={{marginTop:5,color:colors.inputBackgroundColor,fontWeight:"bolder"}}>Agent not found</label></center>:null}
                        </div>
                    </div>



                </Row>


            </Container>
            <Loader active={manifestLoader || campaignLoader || agentLoader || constructorLoader} title="Loading..." />
            <ShowCampain
                active={showManifestDetails}
                handleClose={() => { setShowManifestDetails(!showManifestDetails) }}
                campainDetails={selectedManifestForMoreDetails}
                setShowTranscript={(value) => {
                    setShowTranscript(value);
                }}
                showTranscript={showTranscript}
                setSelectedTranscript={(value) => {
                    setSelectedTranscript(value);
                }}
                selectedTranscript={selectedTranscript}
            />


            <CreateCampaignDialog
                active={showCreateManifest}
                handleClose={() => {
                    setShowCreateManifest(!showCreateManifest);
                }}
                title={isForUpdateManifest ? "Update Manifest" : "Create Manifest"}
                handleCreateInManifest={handleCreateInManifest}
                isForUpdateManifest={isForUpdateManifest}
                selectedConstructor={selectedConstructor}
                setSelectedConstructor={(value) => {
                    setSelectedConstructor(value);
                }}
                selectedCampaign={selectedCampaign}
                setSelectedCampaign={(value) => {
                    setSelectedCampaign(value);
                }}
                selectedAgent={selectedAgent}
                setSelectedAgent={(value) => {
                    setSelectedAgent(value);
                }}
                localListAgent={localListAgent}
                localListCampaign={localListCampaign}
                setLocalListCampaign={(value) => {
                    setLocalListCampaign(value);
                }}
                showselectChampign={showselectChampign}
                setShowselectChampign={(value) => {
                    setShowselectChampign(value);
                }}
                handleFilterCampaign={handleFilterCampaign}
                localListConstructor={localListConstructor}
                setLocalListConstructor={(value) => {
                    setLocalListConstructor(value);
                }}
                showselectConstructor={showselectConstructor}
                setShowselectConstructor={(value) => {
                    setShowselectConstructor(value);
                }}
                handleFilterConstructor={handleFilterConstructor}
                showselectAgent={showselectAgent}
                setShowselectAgent={(value) => {
                    setShowselectAgent(value);
                }}
                handleFilterAgent={handleFilterAgent}
                setSelectedAudioFile={(value) => {
                    setSelectedAudioFile(value)
                }}

            />

            <DialogBox
                active={showCreateManifestWarning}
                title={isForUpdateManifest ? "Update" : "Create"}
                subTitle={isForUpdateManifest ? "Do you want update manifest?" : "Do you want to create manifest?"}
                twoButton={true}
                leftButtonClick={() => {
                    setShowCreateManifestWarning(!showCreateManifestWarning);
                    if (isForUpdateManifest) {

                        dispatch(updateManifest({
                            body: {
                                constructor: selectedConstructor.id,
                                agent: selectedAgent.id,
                                campaign: selectedCampaign.id
                            },
                            id: selectedManifestForMoreDetails.id
                        }));
                    } else {
                        setCompletedItem(1);
                        dispatch(createManifest({
                            constructor: selectedConstructor.id,
                            agent: selectedAgent.id,
                            campaign: selectedCampaign.id,
                            audio_file: selectedAudioFile[0]
                        }));
                    }

                }}
                lefttButtonTitle={isForUpdateManifest ? " Update" : "Create"}
                rightButtonClick={() => {

                    setShowCreateManifestWarning(!showCreateManifestWarning);
                }}
                rightButtonTitle={"Close"}

            />

            <DialogBox
                subTitle={"Do you want to remove manifest?"}
                title={"Remove"}
                active={showDeleteManifest}

                twoButton={true}
                lefttButtonTitle="Yes"
                rightButtonTitle="No"
                rightButtonClick={() => {
                    setShowDeleteManifest(false);

                }}
                leftButtonClick={() => {
                    setShowDeleteManifest(false);
                    dispatch(deleteManifest(selectedManifestForMoreDetails.id))


                }}

            />

<DialogBox
                active={showManifestActionWarning}
                title={isForGenerate ? "Generate" : "Transcribe"}
                subTitle={isForGenerate ? "Do you want generate manifest?" : "Do you want to transcribe manifest?"}
                twoButton={true}
                leftButtonClick={() => {
                    setShowManifestActionWarning(!showManifestActionWarning);
                    if (isForGenerate) {
                        dispatch(generateManifest( { 
                            body:{
                            constructor: selectedManifestForMoreDetails.constructor,
                            agent: selectedManifestForMoreDetails.agent,
                            campaign: selectedManifestForMoreDetails.campaign
                        },
                        id:selectedManifestForMoreDetails.id
                        }));
                       
                    } else {
                        dispatch(transcribeManifest({ 
                            body:{
                            constructor: selectedManifestForMoreDetails.constructor,
                            agent: selectedManifestForMoreDetails.agent,
                            campaign: selectedManifestForMoreDetails.campaign
                        },
                        id:selectedManifestForMoreDetails.id
                        })); 
                    }

                }}
                lefttButtonTitle={isForGenerate ? "Generate" : "Transcribe"}
                rightButtonClick={() => {

                    setShowManifestActionWarning(!showManifestActionWarning);
                }}
                rightButtonTitle={"Close"}

            />
            <ManifestOutput
             active={showManifestResult}
             handleClose={() => {
                setShowManifestResult(!showManifestResult);
             }}
             title={ "Output"}
             selectedManifestForMoreDetails={selectedManifestForMoreDetails}
            />



            <Toaster />


        </div>

    );
}

export default ManifestHome;