import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API, { setAuthToken } from '../../server/api';
import * as ApiRouter from '../../server/apiRoutes'
import API2, { setAuthToken2 } from '../../server/api2';
export const getManifestResultList = createAsyncThunk(
  'manifestresult/getManifestList',
  async (data) => {
    console.log("data",data);
    let params = "";
    if (data?.campaign) {
      params += `&campaign=${data.campaign}`;
    }
    if (data?.constructor) {
      params += `&constructor=${data.constructor}`;
    }
    if (data?.executors) {
      params += `&executors=${data.executors}`;
    }
    if (data?.agent) {
      params += `&agent=${data.agent}`;
    }
    console.log("params",params);
    const result = await API.get(ApiRouter.getManifestResult+`${params}`);
    console.log("result", JSON.stringify(result))
    return { result: result.data }
  }
);





const manifestResultSlice = createSlice({
  name: 'manifestresult',
  initialState: {
    manifestResultLoader: false,
    manifestResultList: [],
    manifestResultStatus:""
  },
  reducers: {
clearManifestResultStatus:(state,action)=>{
  state.manifestResultStatus = "";
}


  },
  extraReducers: (builder) => {


    builder.addCase(getManifestResultList.pending, (state) => {
      state.manifestResultLoader = true;
    });
    builder.addCase(getManifestResultList.fulfilled, (state, action) => {
      state.manifestResultLoader = false;
      state.manifestResultList = action.payload.result;
      state.manifestResultStatus = "success";

    });
    builder.addCase(getManifestResultList.rejected, (state, action) => {
      state.manifestResultLoader = false;
      state.manifestResultStatus = "failed";

    });
  },
});
export const {
  clearManifestResultStatus
} = manifestResultSlice.actions;
export default manifestResultSlice.reducer;
