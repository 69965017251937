import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API,{setAuthToken} from '../../server/api';
import * as ApiRouter from '../../server/apiRoutes'

export const getCampaignList = createAsyncThunk(
  'campaign/getCampaignList',
  async () => {
     const result =  await API.get(ApiRouter.getCampaigns);
     console.log("result",JSON.stringify(result))
     return {result:result.data}
  }              
);

export const createCampaign = createAsyncThunk(
  'agent/createCampaign',
  async (body) => {
    console.log(body);
     const result =  await API.post(ApiRouter.createCampaign,JSON.stringify(body));
     console.log("result",JSON.stringify(result))
     return {result:result.data}
  }              
);
export const updateCampaign = createAsyncThunk(
  'agent/updateCampaign',
  async (data) => {
    console.log(data.body);
    console.log(data.id);
     const result =  await API.put(ApiRouter.updateCampaign(data.id),JSON.stringify(data.body));
     console.log("result",JSON.stringify(result))
     return {result:result.data}
  }              
);
export const deleteCampaign = createAsyncThunk(
  'agent/deleteCampaign',
  async (campaignID) => {
     const result =  await API.delete(ApiRouter.deleteCampaign(campaignID));
     console.log("result",JSON.stringify(result))
     return {result:result.data}
  }              
);
export const generateCampaign = createAsyncThunk(
  'agent/generateCampaign',
  async (data) => {
     const result =  await API.post(ApiRouter.generateCampaign(data.id));
     console.log("result ",JSON.stringify(result))
     return {result:result.data}
  }              
);

export const transcribeCampaign = createAsyncThunk(
  'agent/transcribeCampaign',
  async (data) => {
     const result =  await API.post(ApiRouter.transcribeCampaign(data.id));
     console.log("result",JSON.stringify(result))
     return {result:result.data}
  }              
);


const organizationSlice = createSlice({
  name: 'campaign',
  initialState: {
    campaignLoader: false,
    campaignList:[],
    campaignListStatus:"",
    campaignListMessage:"",
    createCampaignStatus:"",
    createCampaignMessage:"",
    deleteCampaignStatus:"",
    deleteCampaignMessage:"",
    campaignActionStatus:"",
    campaignActionMessage:""
  },
  reducers: {
  cleanCampaignListStatus:(state)=>{
    state.campaignListStatus="";
    state.campaignListMessage="";
  },
  clearCreateCampaign:(state)=>{
    state.createCampaignStatus="";
    state.createCampaignMessage="";
  },
  clearDeleteCampaign:(state)=>{
    state.deleteCampaignStatus="";
    state.deleteCampaignMessage="";
  },
  clearCampaignAction:(state)=>{
    state.campaignActionStatus="";
    state.campaignActionMessage="";
  }

  },
  extraReducers: (builder) => {


    builder.addCase(getCampaignList.pending, (state) => {
      state.campaignLoader = true;
      state.campaignListStatus = "";
    });
    builder.addCase(getCampaignList.fulfilled, (state, action) => {
      state.campaignLoader = false;
       state.campaignList = action.payload.result;
       state.campaignListStatus = "success";
    });
    builder.addCase(getCampaignList.rejected, (state, action) => {
      state.campaignLoader = false;
      state.campaignListStatus = "failed";
      state.campaignListMessage =action.error.message;
    });

    builder.addCase(createCampaign.pending, (state) => {
      state.campaignLoader = true;
    });
    builder.addCase(createCampaign.fulfilled, (state, action) => {
      state.campaignLoader = false;
       state.createCampaignStatus = "success";
      
    });
    builder.addCase(createCampaign.rejected, (state, action) => {
      state.campaignLoader = false;
      state.createCampaignStatus = "failed";
      state.createCampaignMessage =action.error.message;
    });

    builder.addCase(updateCampaign.pending, (state) => {
      state.campaignLoader = true;
    });
    builder.addCase(updateCampaign.fulfilled, (state, action) => {
      state.campaignLoader = false;
       state.createCampaignStatus = "success";
       
    });
    builder.addCase(updateCampaign.rejected, (state, action) => {
      state.campaignLoader = false;
      state.createCampaignStatus = "failed";
      state.createCampaignMessage =action.error.message;
    });

    builder.addCase(deleteCampaign.pending, (state) => {
      state.campaignLoader = true;
    });
    builder.addCase(deleteCampaign.fulfilled, (state, action) => {
      state.campaignLoader = false;
       state.deleteCampaignStatus = "success";
       
    });
    builder.addCase(deleteCampaign.rejected, (state, action) => {
      state.campaignLoader = false;
      state.deleteCampaignStatus = "failed";
      state.deleteCampaignMessage =action.error.message;
    });

    builder.addCase(generateCampaign.pending, (state) => {
      state.campaignLoader = true;
    });
    builder.addCase(generateCampaign.fulfilled, (state, action) => {
      state.campaignLoader = false;
       state.campaignActionStatus = "success";
       
    });
    builder.addCase(generateCampaign.rejected, (state, action) => {
      state.campaignLoader = false;
      state.campaignActionStatus = "failed";
      state.campaignActionMessage =action.error.message;
    });

    builder.addCase(transcribeCampaign.pending, (state) => {
      state.campaignLoader = true;
    });
    builder.addCase(transcribeCampaign.fulfilled, (state, action) => {
      state.campaignLoader = false;
       state.campaignActionStatus = "success";
       
    });
    builder.addCase(transcribeCampaign.rejected, (state, action) => {
      state.campaignLoader = false;
      state.campaignActionStatus = "failed";
      state.campaignActionMessage =action.error.message;
    });

    
    

    
  },
});
export const {
  cleanCampaignListStatus,
  clearCreateCampaign,
  clearDeleteCampaign,
  clearCampaignAction
} = organizationSlice.actions;
export default organizationSlice.reducer;
