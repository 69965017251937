import React, { useState, useEffect } from "react";

import {
    Container,
    Row,
    Col,

} from 'react-bootstrap';
import './index.css';
import {
    Title,
    SearchContainerStyle,
    SearchInputText,
    TableHeader,
    TableData,
    CreateAgent,
    IconMenuTitle
} from './styles';
import { Button } from '@mui/material';
import { colors } from '../../theme';
import {

    Campaign,
    Search,

    Edit, Delete,
    PostAdd,
    Article
} from '@mui/icons-material';
import { Loader, DialogBox, Toaster } from '../../component';
import { useSelector, useDispatch } from 'react-redux';

import {
    getCampaignList,
    cleanCampaignListStatus,
    clearCreateCampaign,
    createCampaign,
    updateCampaign,
    clearDeleteCampaign,
    deleteCampaign,
    generateCampaign,
    clearCampaignAction,
    transcribeCampaign,

} from '../../redux/reducers/campaign';

import moment from "moment";
import CreateCampaignDialog from "./createCampainDialog";
import { toast } from 'react-toastify';
import ShowCampain from "./showCampaignDetails";
import CampaignAction from "./campaignActions";
const CampainHome = () => {
    const dispatch = useDispatch();
   
    const organizationStore = useSelector((state) => state.organization);
    const campaignStore = useSelector((state) => state.campaign);

    const {
        organizationFullList,
    } = organizationStore;
    const {
        campaignLoader,
        campaignList,
        campaignListStatus,
        campaignListMessage,
        createCampaignStatus,
        createCampaignMessage,
        deleteCampaignStatus,
        deleteCampaignMessage,
        campaignActionStatus,
        campaignActionMessage
    } = campaignStore;


    const [agentSearchText, setAgentSearchText] = useState("");
    const [showCreateAgentDialog, setShowCreateAgentDialog] = useState(false);

    const [localOrganizationFullList, setLocalOrganizationFullList] = useState([]);
 

    // new
    const [showCampaignDetails, setShowCampaignDetails] = useState(false);
    const [selectedCampainForMoreDetails, setSelectedCampainForMoreDetails] = useState({});
    const [showCreateCampaign, setShowCreateCampaign] = useState(false);
    const [createCampaignName, setCreateCampaignName] = useState("");
    const [showCreateCampainWarning, setShowCreateCampainWarning] = useState(false);
    const [isForUpdateCampain, setIsForUpdateCampain] = useState(false);
    const [showDeleteCampaign, setShowDeleteCampaign] = useState(false);
    const [campaignAction, setCampaignAction] = useState("");
    const [showCampaignAction, setShowCampaignAction] = useState(false);
    const [campaingActionInput, setCampaingActionInput] = useState("");
    const [showCampaignActionWarning, setShowCampaignActionWarning] = useState(false);
    const [localCampaignList, setLocalCampaignList] = useState([]);
    

    useEffect(() => {
        dispatch(getCampaignList());
    }, []);
    useEffect(() => {
        if (campaignListStatus == "success") {
            setLocalCampaignList([...campaignList])
            dispatch(cleanCampaignListStatus());
        } else if (campaignListStatus == "failed") {
            toast.error(`${campaignListMessage}`);
            dispatch(cleanCampaignListStatus());
        }
    }, [campaignListStatus]);
    useEffect(() => {
        if (createCampaignStatus == "success") {
            dispatch(getCampaignList());
            dispatch(clearCreateCampaign());
            setIsForUpdateCampain(false);
            toast.success(isForUpdateCampain ? "Update Successfully" : `Create Successfully`);
        } else if (createCampaignStatus == "failed") {
            toast.error(`${createCampaignMessage}`);
            dispatch(clearCreateCampaign());
        }
    }, [createCampaignStatus]);
    useEffect(() => {
        if (deleteCampaignStatus == "success") {
            dispatch(getCampaignList());
            dispatch(clearDeleteCampaign());

            toast.success(`Deleted Successfully`);
        } else if (deleteCampaignStatus == "failed") {
            toast.error(`${deleteCampaignMessage}`);
            dispatch(clearDeleteCampaign());
        }
    }, [deleteCampaignStatus]);
    useEffect(() => {
        if (campaignActionStatus == "success") {
            dispatch(getCampaignList());
            toast.success(campaignAction == "generate" ? `Generated Successfully` : `Transcribe Successfully`);
            dispatch(clearCampaignAction());
        } else if (campaignActionStatus == "failed") {
            toast.error(`${campaignActionMessage}`);
            dispatch(clearCampaignAction());
        }
    }, [campaignActionStatus]);









    const closeCreateAgentDialog = () => {
        setShowCreateAgentDialog(!showCreateAgentDialog);
    }
    const handleFilterOrganization = (text) => {
        if (text !== "") {
            let searchedOrganizationList = organizationFullList.filter((item) =>
                item.name.toLowerCase().includes(text.toLowerCase()) ? item : null
            );
            setLocalOrganizationFullList([...searchedOrganizationList]);

        } else {
            setLocalOrganizationFullList([...organizationFullList]);
        }
    };
    const handleCreateInCampaign = () => {
        if(createCampaignName){
        setShowCreateCampaign(!showCreateCampaign);
        setShowCreateCampainWarning(!showCreateCampainWarning);
    }else{
        toast.error(`Empty Field Found!`);
    }
    }
    const handleAction = () => {
        if(campaingActionInput){
            setShowCampaignAction(!showCampaignAction);
            setShowCampaignActionWarning(!showCampaignActionWarning);
        }else{
            toast.error(`Empty Field Found!`);
        }
       
    }
    const handleSearch = (text) => {
        setAgentSearchText(text);
        if (text !== "") {
            let searchedCampaignList = campaignList.filter((item) =>
                item.name.toLowerCase().includes(text.toLowerCase()) ? item : null
            );
            setLocalCampaignList([...searchedCampaignList]);

        } else {
            setLocalCampaignList([...campaignList]);
        }
    }

    return (
        <div>
            <Container fluid style={{
                backgroundColor: colors.backgroundColorTheme,
                height: '100vh',
                overflow:"auto"
            }}>

                <Row >

                </Row>
                <Row style={{ marginTop: 20, marginLeft: 20 }}>

                    <Col  >
                        <Campaign
                            style={{ width: 80, height: 80, color: "#fff" }} />
                        <Title >Campaign</Title>
                    </Col>
                    <Col style={{ textAlign: "right" }} >
                        <SearchContainerStyle

                        >
                            <Search style={{ width: 28, height: 28, color: "#fff" }} />
                            <SearchInputText
                                id="agentSearch"
                                type="text" value={agentSearchText}
                                onChange={(e) => { handleSearch(e.target.value) }}
                                placeholder="Search Campaign..."
                                className="agentSearchClass"

                            />
                        </SearchContainerStyle>
                    </Col>


                </Row>
                <Row style={{ margin: 20 }}>
                    <Col style={{ textAlign: "right" }} onClick={() => {
                        setCreateCampaignName("");
                        setShowCreateCampaign(!showCreateCampaign);
                        setIsForUpdateCampain(false);

                    }} >
                        <PostAdd
                            style={{ width: 24, height: 24, color: colors.activeBlue }} />
                        <CreateAgent >Create Campain</CreateAgent>
                    </Col>
                </Row>
                <Row >
                    <div class="row" >
                        <div class="col-xs-12">
                            <div class="table-responsive" data-pattern="priority-columns">
                                <table
                                    summary="This table shows how to create responsive tables using RWD-Table-Patterns' functionality"
                                    class="table table-bordered table-hover"
                                >

                                    <thead>
                                        <tr style={{ backgroundColor: colors.inputBackgroundColor }}>
                                            <TableHeader>ID</TableHeader>
                                            <TableHeader data-priority="2">Name</TableHeader>

                                            <TableHeader data-priority="2">Created</TableHeader>
                                            <TableHeader data-priority="1">Action</TableHeader>
                                            <TableHeader data-priority="1"></TableHeader>

                                        </tr>
                                    </thead>

                                    <tbody >
                                        {localCampaignList.map((data, i) => {
                                            return (
                                                <tr>
                                                    <TableData>{data.id}</TableData>
                                                    <TableData>{data.name}</TableData>

                                                    <TableData>{moment(data.created_at).format('LLL')}</TableData>
                                                    <TableData>
                                                        <Col >
                                                            <Button
                                                                onClick={() => {
                                                                    setCampaignAction("generate");
                                                                    setCampaingActionInput("");
                                                                   // setShowCampaignAction(!showCampaignAction);
                                                                    setSelectedCampainForMoreDetails(data);
                                                                    setShowCampaignActionWarning(!showCampaignActionWarning);
                                                                }} 
                                                                style={{
                                                                    color: colors.white,
                                                                    fontWeight: "bolder",
                                                                    backgroundColor: colors.generate
                                                                }}
                                                            >Generate</Button>
                                                            <Button
                                                                onClick={() => {
                                                                    setCampaignAction("transcribe");
                                                                    setCampaingActionInput("");
                                                                   // setShowCampaignAction(!showCampaignAction);
                                                                   setSelectedCampainForMoreDetails(data);
                                                                   setShowCampaignActionWarning(!showCampaignActionWarning);
                                                                }}
                                                                style={{
                                                                    color: colors.white,
                                                                    fontWeight: "bolder",
                                                                    backgroundColor: colors.transcribe,
                                                                    marginLeft: 10
                                                                }}
                                                            >Transcribe</Button>

                                                        </Col>
                                                    </TableData>
                                                    <TableData
                                                    // onClick={()=>{
                                                    //     dispatch(getOrganizationList());
                                                    //     setSelectedOrganizationDetails({});
                                                    //     setAddAgentName("");
                                                    // }}
                                                    >
                                                        <Col>

                                                            <Article
                                                                onClick={() => {
                                                                    setSelectedCampainForMoreDetails(data);
                                                                    setShowCampaignDetails(!showCampaignDetails);

                                                                }}
                                                                style={{ width: 20, height: 20, color: colors.activeBlue }} />

                                                            <IconMenuTitle
                                                                onClick={() => {
                                                                    setSelectedCampainForMoreDetails(data);
                                                                    setShowCampaignDetails(!showCampaignDetails);
                                                                }}
                                                                style={{ color: colors.activeBlue }}
                                                            >Details</IconMenuTitle>
                                                            <Edit
                                                                onClick={() => {
                                                                    setCreateCampaignName(data.name);
                                                                    setIsForUpdateCampain(true);
                                                                    setShowCreateCampaign(!showCreateCampaign);
                                                                    setSelectedCampainForMoreDetails(data);


                                                                }}
                                                                style={{ width: 20, height: 20, color: "orange", marginLeft: 20 }} />
                                                            <IconMenuTitle
                                                                onClick={() => {
                                                                    setCreateCampaignName(data.name);
                                                                    setIsForUpdateCampain(true);
                                                                    setShowCreateCampaign(!showCreateCampaign);
                                                                    setSelectedCampainForMoreDetails(data);
                                                                }}
                                                                style={{ color: "orange" }}
                                                            >Edit</IconMenuTitle>
                                                            <Delete
                                                                onClick={() => {
                                                                    setSelectedCampainForMoreDetails(data);
                                                                    setShowDeleteCampaign(true);
                                                                }}

                                                                style={{ width: 20, height: 20, color: colors.FailedTheme, marginLeft: 20 }} />
                                                            <IconMenuTitle
                                                                onClick={() => {
                                                                    setSelectedCampainForMoreDetails(data);
                                                                    setShowDeleteCampaign(true);
                                                                }}
                                                                style={{ color: colors.FailedTheme }}
                                                            >Remove</IconMenuTitle>

                                                        </Col>




                                                    </TableData>

                                                </tr>
                                            );
                                        })}


                                    </tbody>

                                </table>
                            </div>
                            {localCampaignList.length == 0 ? 
                                        
                                        <center> <label style={{marginTop:5,color:colors.inputBackgroundColor,fontWeight:"bolder"}}>Campaign not found</label></center>:null}
                        </div>
                    </div>



                </Row>


            </Container>
            <Loader active={campaignLoader} title="Loading..." />
            <ShowCampain
                active={showCampaignDetails}
                handleClose={() => { setShowCampaignDetails(!showCampaignDetails) }}
                campainDetails={selectedCampainForMoreDetails}
            />
            <CreateCampaignDialog
                active={showCreateCampaign}
                handleClose={() => {
                    setShowCreateCampaign(!showCreateCampaign);
                }}
                title={isForUpdateCampain ? "Update Campaign" : "Create Campaign"}
                createCampaignName={createCampaignName}
                setCreateCampaignName={(value) => {
                    setCreateCampaignName(value);
                }}
                handleCreateInCampaign={handleCreateInCampaign}
                isForUpdateCampain={isForUpdateCampain}
            />
            <CampaignAction
                active={showCampaignAction}
                handleClose={() => {
                    setShowCampaignAction(!showCampaignAction);
                }}
                title={campaignAction == "generate" ? "Generate" : "Transcibe"}
                campaingActionInput={campaingActionInput}
                setCampaingActionInput={(value) => {
                    setCampaingActionInput(value);
                }}
                handleAction={handleAction}
                campaignAction={campaignAction}
            />
            <DialogBox
                active={showCreateCampainWarning}
                title={isForUpdateCampain ? "Update" : "Create"}
                subTitle={isForUpdateCampain ? "Do you want update campaign?" : "Do you want to create campaing?"}
                twoButton={true}
                leftButtonClick={() => {
                    setShowCreateCampainWarning(!showCreateCampainWarning);
                    if (isForUpdateCampain) {

                        dispatch(updateCampaign({
                            body: { name: createCampaignName },
                            id: selectedCampainForMoreDetails.id
                        }));
                    } else {
                        dispatch(createCampaign({
                            name: createCampaignName
                        }));
                    }

                }}
                lefttButtonTitle={isForUpdateCampain ? " Update" : "Create"}
                rightButtonClick={() => {
                    setShowCreateCampainWarning(!showCreateCampainWarning);
                }}
                rightButtonTitle={"Close"}

            />

            <DialogBox
                subTitle={"Do you want to remove campaign?"}
                title={"Remove"}
                active={showDeleteCampaign}

                twoButton={true}
                lefttButtonTitle="Yes"
                rightButtonTitle="No"
                rightButtonClick={() => {
                    setShowDeleteCampaign(false);

                }}
                leftButtonClick={() => {
                    setShowDeleteCampaign(false);
                    dispatch(deleteCampaign(selectedCampainForMoreDetails.id))


                }}

            />


            <DialogBox
                active={showCampaignActionWarning}
                title={campaignAction == "generate" ? "Generate" : "Transcribe"}
                subTitle={campaignAction == "generate"  ? "Do you want generate campaign?" : "Do you want to transcribe campaing?"}
                twoButton={true}
                leftButtonClick={() => {
                    setShowCampaignActionWarning(!showCampaignActionWarning);
                    if (campaignAction == "generate" ) {

                        dispatch(generateCampaign({
                            // body: { name: campaingActionInput },
                            id: selectedCampainForMoreDetails.id
                        }));
                    } else {
                        dispatch(transcribeCampaign({
                            // body: { name: campaingActionInput },
                            id: selectedCampainForMoreDetails.id
                        }));
                    }
    
                }}
                lefttButtonTitle={campaignAction == "generate"  ? "Generate" : "Transcribe"}
                rightButtonClick={() => {
                    setShowCampaignActionWarning(!showCampaignActionWarning);
                }}
                rightButtonTitle={"Close"}

            />


            <Toaster />


        </div>

    );
}

export default CampainHome;