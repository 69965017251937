import React from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate,Outlet } from "react-router-dom";
import * as Routing from '../../routes/routes';
import { toast } from 'react-toastify';
import { Loader, DialogBox, Toaster } from '../../component';
import './index.css';

import {
    SupportAgentOutlined,
    Campaign,
    Construction,
    Memory,
    Summarize,
    LocationCity,
    ModelTraining,
    Leaderboard,
    AssignmentInd
} from '@mui/icons-material';
import { colors } from "../../theme";


const Home = () => {
    const navigate = useNavigate();

    return (
        <>
            <nav id="navbar">
                <ul class="navbar-items flexbox-col">
                    <li class="navbar-logo flexbox-left">
                        <a class="navbar-item-inner flexbox">
                            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 1438.88 1819.54">
                                <polygon points="925.79 318.48 830.56 0 183.51 1384.12 510.41 1178.46 925.79 318.48" />
                                <polygon points="1438.88 1663.28 1126.35 948.08 111.98 1586.26 0 1819.54 1020.91 1250.57 1123.78 1471.02 783.64 1663.28 1438.88 1663.28" />
                            </svg>
                        </a>
                        <span class="link-text"
                        style={{color:colors.white,fontSize:24,
                        fontWeight:"bolder"}}
                        >DevTel A.I</span>
                    </li>
                    <li class="navbar-item flexbox-left">
                        <Link 
                        to={Routing.agent}
                        color="#ffffff"
                        class="navbar-item-inner flexbox-left">
                            <div class="navbar-item-inner-icon-wrapper flexbox">
                            
                                <SupportAgentOutlined style={{width:30,height:30,color:"#fff"}}/>
                            </div>
                            <span class="link-text">Agent</span>
                        </Link>
                    </li>
                    <li class="navbar-item flexbox-left">
                        <Link 
                          to={Routing.Campain}
                          color="#ffffff"
                        class="navbar-item-inner flexbox-left">
                            <div class="navbar-item-inner-icon-wrapper flexbox">
                            <Campaign style={{width:30,height:30,color:"#fff"}}/>
                            </div>
                            <span class="link-text">Campaigns</span>
                        </Link>
                    </li>
                    <li class="navbar-item flexbox-left">
                    <Link 
                          to={Routing.Constructor}
                          color="#ffffff" class="navbar-item-inner flexbox-left">
                            <div class="navbar-item-inner-icon-wrapper flexbox">
                                <Construction style={{width:30,height:30,color:"#fff"}}/>
                            </div>
                            <span class="link-text">Constructors</span>
                        </Link>
                    </li>
                    <li class="navbar-item flexbox-left">
                    <Link 
                          to={Routing.Executor}
                          color="#ffffff" class="navbar-item-inner flexbox-left">
                            <div class="navbar-item-inner-icon-wrapper flexbox">
                            <Memory style={{width:30,height:30,color:"#fff"}}/>
                                
                            </div>
                            <span class="link-text">Executors</span>
                        </Link>
                    </li>
                    <li class="navbar-item flexbox-left">
                    <Link 
                          to={Routing.Model}
                          color="#ffffff" class="navbar-item-inner flexbox-left">
                            <div class="navbar-item-inner-icon-wrapper flexbox">
                            <ModelTraining style={{width:30,height:30,color:"#fff"}}/>
                            </div>
                            <span class="link-text">Model</span>
                        </Link>
                    </li>
                    <li class="navbar-item flexbox-left">
                    <Link 
                          to={Routing.Manifest} class="navbar-item-inner flexbox-left">
                            <div class="navbar-item-inner-icon-wrapper flexbox">
                            <Summarize style={{width:30,height:30,color:"#fff"}}/>
                            </div>
                            <span class="link-text">Manifeast</span>
                        </Link>
                    </li>  
                    <li class="navbar-item flexbox-left">
                    <Link 
                          to={Routing.ManifestResult} class="navbar-item-inner flexbox-left">
                            <div class="navbar-item-inner-icon-wrapper flexbox">
                            <Leaderboard style={{width:30,height:30,color:"#fff"}}/>
                            </div>
                            <span class="link-text">Manifeast Result</span>
                        </Link>
                    </li>
                    <li class="navbar-item flexbox-left">
                    <Link 
                          to={Routing.Organization} class="navbar-item-inner flexbox-left">
                            <div class="navbar-item-inner-icon-wrapper flexbox">
                            <LocationCity style={{width:30,height:30,color:"#fff"}}/>
                            </div>
                            <span class="link-text">Organization</span>
                        </Link>
                    </li>
                    <li class="navbar-item flexbox-left">
                    <Link 
                          to={Routing.Profile} class="navbar-item-inner flexbox-left">
                            <div class="navbar-item-inner-icon-wrapper flexbox">
                            <AssignmentInd style={{width:30,height:30,color:"#fff"}}/>
                            </div>
                            <span class="link-text">Profile</span>
                        </Link>
                    </li>
                    
                    
                </ul>
            </nav>


            <main id="main" >
<Outlet />

            </main>

        </>
    );
}

export default Home;