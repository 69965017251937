import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API,{setAuthToken} from '../../server/api';
import * as ApiRouter from '../../server/apiRoutes';
import {setAuthToken2} from '../../server/api2';


export const login = createAsyncThunk(
  'auth/login',
  async (loginDetails) => {
     console.log(loginDetails);
      setAuthToken(loginDetails);
      setAuthToken2(loginDetails);
     const result =  await API.post(ApiRouter.Login);
     console.log("result",JSON.stringify(result))
     return {result:result.data}
  }
);



const authSlice = createSlice({
  name: 'auth',
  initialState: {
    authLoader: false,
    loginStatus:"",
    loginMessage:"",
    loggedIn:false
  },
  reducers: {
  cleanLoginStatus:(state,action)=>{
     state.loginMessage = "";
     state.loginStatus ="";
  },
  setLoggedIn:(state,action)=>{
    state.loggedIn = action.payload;
   
 },

  },
  extraReducers: (builder) => {


    builder.addCase(login.pending, (state) => {
      state.authLoader = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.authLoader = false;
      state.loginStatus = "success";
    });
    builder.addCase(login.rejected, (state, action) => {
      state.authLoader = false;
      state.loginStatus ="failed";
      state.loginMessage = action.error.message;
      console.log(action.error);

    });
  },
});
export const {
 cleanLoginStatus,
 setLoggedIn
} = authSlice.actions;
export default authSlice.reducer;
