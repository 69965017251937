import React, { useState, useEffect } from "react";

import {
    Container,
    Row,
    Col,
    Card,
    Button
} from 'react-bootstrap';
import './index.css';
import {
    Title,
    SearchContainerStyle,
    SearchInputText,
    TableHeader,
    TableData,
    CreateAgent,
    IconMenuTitle
} from './styles';
import { colors } from '../../theme';
import {
    SupportAgentOutlined,
    Search,
    GroupAdd,
    Edit, Delete,
    AccountCircle,Logout
} from '@mui/icons-material';
import { Loader, DialogBox, Toaster } from '../../component';
import { useSelector, useDispatch } from 'react-redux';
import { setAuthToken } from "../../server/api";
import { setAuthToken2 } from "../../server/api2";
import { Route, useNavigate } from "react-router-dom";
import * as Routing from '../../routes/routes';
import { setLoggedIn } from "../../redux/reducers/auth";
const ProfileHome = () => {
    const dispatch = useDispatch();
    const agentStore = useSelector((state) => state.agent);
    const authStore = useSelector((state) => state.auth);
    const {
        loggedIn
    } = authStore;
    const navigate = useNavigate();
    const [showLogoutWarning,setShowLogoutWarning]=useState(false);



    return (
        <div>
            <Container fluid style={{
                backgroundColor: colors.backgroundColorTheme,
                height: '100vh',
                overflow: "auto"
            }}>

                <center>
                    <Card
                        onClick={() => {


                        }}
                        style={{
                            backgroundColor: colors.splitLineColor,
                            marginTop: "30vh",
                            padding: 10,
                            width: "40vw",
                            
                        }}
                    >
                        <center>
                            <AccountCircle
                                style={{ width: 100, height: 100, color: colors.white }}
                            />
                            <h5 style={{ color: colors.white, marginTop: 20 }}>DevTel Al user</h5>
                            <label style={{color:colors.blueWithLight}}>All session and authentication information will be cleared upon logout.</label>

                            <Button 
                            style={{
                                backgroundColor:colors.FailedTheme,
                                width:200,
                                borderWidth:0,
                                marginTop:30
                            }}
                            onClick={() => {
                                setShowLogoutWarning(true);
                            }}>
                                 <Logout
                                style={{ width: 30, height: 30, color: colors.white }}
                            />
                                Logout</Button>
                             
                        </center>


                    </Card>
                </center>


            </Container>
            {/* <Loader active={agentLoader || organizationLoader} title="Loading..." /> */}

            <Toaster />

            <DialogBox
                subTitle={"Do you want to logout agent?"}
                title={"LOGOUT"}
                active={showLogoutWarning}

                twoButton={true}
                lefttButtonTitle="Yes"
                rightButtonTitle="No"
                rightButtonClick={() => {
                    setShowLogoutWarning(false);
                    

                }}
                leftButtonClick={() => {
                    setShowLogoutWarning(false);
                    dispatch(setLoggedIn(false));
                    setAuthToken("");
                    setAuthToken2("");
                    navigate(Routing.Login);
                    


                }}

            />


        </div>

    );
}

export default ProfileHome;