import React, { useState, useEffect } from "react";

import {
    Container,
    Row,
    Col,

} from 'react-bootstrap';
import './index.css';
import {
    Title,
    SearchContainerStyle,
    SearchInputText,
    TableHeader,
    TableData,
    CreateAgent,
    IconMenuTitle
} from './styles';
import { Button } from '@mui/material';
import { colors } from '../../theme';
import {

    Campaign,
    Search,

    Edit, Delete,
    PostAdd,
    Article,
    Memory
} from '@mui/icons-material';
import { Loader, DialogBox, Toaster } from '../../component';
import { useSelector, useDispatch } from 'react-redux';

import {
    getCampaignList,
    cleanCampaignListStatus,
    clearCreateCampaign,
    createCampaign,
    updateCampaign,
    clearDeleteCampaign,
    deleteCampaign,
    generateCampaign,
    clearCampaignAction,
    transcribeCampaign,

} from '../../redux/reducers/campaign';
import {
    
  getExecutorList,
  createModel,
  clearCreateExecutor,
  updateExecutor,
  deleteExecutor,
  clearDeleteExecutor,
  clearExecutorFull

} from '../../redux/reducers/executor';

import moment from "moment";
import CreateCampaignDialog from "./createCampainDialog";
import { toast } from 'react-toastify';
import ShowExecutor from "./showExecutorDetails";
import CampaignAction from "./campaignActions";
import { clearModelListStatus, getModelList } from "../../redux/reducers/model";
const ExecutorHome = () => {
    const dispatch = useDispatch();
   
    const organizationStore = useSelector((state) => state.organization);
    const campaignStore = useSelector((state) => state.campaign);
    const executorStore = useSelector((state) => state.executor);
    const modelStore = useSelector((state) => state.model);
    
    const {
        organizationFullList,
    } = organizationStore;
    const {
        campaignLoader,
        campaignList,
        campaignListStatus,
        campaignListMessage,
        createCampaignStatus,
        createCampaignMessage,
        deleteCampaignStatus,
        deleteCampaignMessage,
        campaignActionStatus,
        campaignActionMessage
    } = campaignStore;

    const {
        executorLoader,
        executorFullList,
        createExecutorMessage,
    createExecutorState,
    deleteExecutorMessage,
    deleteExecutorStatus,
    executorFullState,
    executorFullMessage
    } = executorStore;
    const {
        modelLoader,
        modelFullList,
        modelListStatus
       
       
    } = modelStore;


    const [agentSearchText, setAgentSearchText] = useState("");
    const [showCreateAgentDialog, setShowCreateAgentDialog] = useState(false);

    const [localOrganizationFullList, setLocalOrganizationFullList] = useState([]);
 

    // new
    const [showExecutorDetails, setShowExecutorDetails] = useState(false);
    const [selectedExecutorForMoreDetails, setSelectedExecutorForMoreDetails] = useState({});
    const [showCreateExecutor, setShowCreateExecutor] = useState(false);
    const [createExecutorName, setCreateExecutorName] = useState("");
    const [showCreateCampainWarning, setShowCreateCampainWarning] = useState(false);
    const [isForUpdateExecutor, setIsForUpdateExecutor] = useState(false);
    const [showDeleteExecutor, setShowDeleteExecutor] = useState(false);
    const [campaignAction, setCampaignAction] = useState("");
    const [showCampaignAction, setShowCampaignAction] = useState(false);
    const [campaingActionInput, setCampaingActionInput] = useState("");
    const [showCampaignActionWarning, setShowCampaignActionWarning] = useState(false);
    const [promptInput, setPromptInput] = useState("");

    const [prompt,setPrompt]=useState("");
   const [showPromptDialog,setShowPromptDialog] = useState(false);
   const [isPromptDataForMoreDetails,setIsPromptDataForMoreDetails]=useState(false);
   const [selectedExecutorForCreate, setSelectedExecutorForCreate] = useState({});
   const [executorListForCreate,setExecutorListForCreate]=useState([]);
   const [showSelecteModel,setShowSelecteModel]=useState(false);
   const [showSelectCampaign, setShowSelectCampaign] = useState(false);
   const [campaignListLocal, setCampaignListLocal] = useState([]);
   const [selectedCampaign, setSelectedCampaign] = useState({});

   const [localModelList,setLocalModelList]=useState([]);
   const [showModelSelectDialog,setShowModelSelectDialog]=useState(false)
   const [selectedModel,setSelectedModel]=useState({});
   const [localExecutorListForSearch,setLocalExecutorListForSearch]=useState([]);
   
   
    

    useEffect(() => {
        dispatch(getExecutorList());
    }, []);

    useEffect(() => {
        if (executorFullState == "success") {
            setLocalExecutorListForSearch([...executorFullList]);
            dispatch(clearExecutorFull());
        } else if (executorFullState == "failed") {
            dispatch(clearExecutorFull());
        }
    }, [executorFullState]);

    useEffect(() => {
        if (campaignListStatus == "success") {
            dispatch(cleanCampaignListStatus());
            setCampaignListLocal(campaignList)
            setShowCreateExecutor(!showCreateExecutor);
        } else if (campaignListStatus == "failed") {
            toast.error(`${campaignListMessage}`);
            dispatch(cleanCampaignListStatus());
        }
    }, [campaignListStatus]);
    useEffect(() => {
        if (createCampaignStatus == "success") {
            dispatch(getCampaignList());
            dispatch(clearCreateCampaign());
            setIsForUpdateExecutor(false);
            toast.success(isForUpdateExecutor ? "Update Successfully" : `Create Successfully`);
        } else if (createCampaignStatus == "failed") {
            toast.error(`${createCampaignMessage}`);
            dispatch(clearCreateCampaign());
        }
    }, [createCampaignStatus]);
    useEffect(() => {
        if (deleteExecutorStatus == "success") {
            dispatch(getExecutorList());
            dispatch(clearDeleteExecutor());

            toast.success(`Deleted Successfully`);
        } else if (deleteExecutorStatus == "failed") {
            toast.error(`${deleteExecutorMessage}`);
            dispatch(clearDeleteExecutor());
        }
    }, [deleteExecutorStatus]);
    useEffect(() => {
        if (campaignActionStatus == "success") {
            dispatch(getCampaignList());
            toast.success(campaignAction == "generate" ? `Generated Successfully` : `Transcribe Successfully`);
            dispatch(clearCampaignAction());
        } else if (campaignActionStatus == "failed") {
            toast.error(`${campaignActionMessage}`);
            dispatch(clearCampaignAction());
        }
    }, [campaignActionStatus]);
    useEffect(() => {
        if (modelListStatus == "success") {
            
            dispatch(clearModelListStatus());
            setLocalModelList(modelFullList);
        } else if (modelListStatus == "failed") {
            toast.error(`${campaignActionMessage}`);
            dispatch(clearModelListStatus());
        }
    }, [modelListStatus]);
    useEffect(()=>{
        if (createExecutorState == "success") {
            dispatch(clearCreateExecutor());
            dispatch(getExecutorList())
            toast.success(isForUpdateExecutor ? "Update Successfully" : `Create Successfully`);
            debugger;
        } else if (createExecutorState == "failed") {
            toast.error(`${createExecutorMessage}`);
            dispatch(clearCreateExecutor());
        }
    },[createExecutorState]);
    


    








    const closeCreateAgentDialog = () => {
        setShowCreateAgentDialog(!showCreateAgentDialog);
    }
    const handleFilterOrganization = (text) => {
        if (text !== "") {
            let searchedOrganizationList = organizationFullList.filter((item) =>
                item.name.toLowerCase().includes(text.toLowerCase()) ? item : null
            );
            setLocalOrganizationFullList([...searchedOrganizationList]);

        } else {
            setLocalOrganizationFullList([...organizationFullList]);
        }
    };
    const handleCreateInExecutor = () => {
        if(selectedCampaign?.name && selectedModel?.name && createExecutorName && prompt){
            setShowCreateExecutor(!showCreateExecutor);
        setShowCreateCampainWarning(!showCreateCampainWarning);
    }else{
        toast.error(`Empty Field Found!`);
    }
    }
    const handleAction = () => {
        if(campaingActionInput){
            setShowCampaignAction(!showCampaignAction);
            setShowCampaignActionWarning(!showCampaignActionWarning);
        }else{
            toast.error(`Empty Field Found!`);
        }
       
    }
    const handleFilterModel =(text)=>{
        if (text !== "") {
            let searchedExecutorList = executorFullList.filter((item) =>
                item.name.toLowerCase().includes(text.toLowerCase()) ? item : null
            );
            setExecutorListForCreate([...searchedExecutorList]);

        } else {
            setExecutorListForCreate([...executorFullList]);
        }  
    }
    const handleFilterCampaign = (text) => {
        if (text !== "") {
            let searchedOrganizationList = campaignList.filter((item) =>
                item.name.toLowerCase().includes(text.toLowerCase()) ? item : null
            );
            setCampaignListLocal([...searchedOrganizationList]);

        } else {
            setCampaignListLocal([...campaignList]);
        }
    }
    const handleFilterModel1 = (text) => {
        if (text !== "") {
            let searchedModelList = modelFullList.filter((item) =>
                item.name.toLowerCase().includes(text.toLowerCase()) ? item : null
            );
            setLocalModelList([...searchedModelList]);

        } else {
            setLocalModelList([...modelFullList]);
        }
    }

    const handleSearch = (text) => {
        setAgentSearchText(text);
        if (text !== "") {
            let searchedAgentList = executorFullList.filter((item) =>
                item.name.toLowerCase().includes(text.toLowerCase()) ? item : null
            );
            setLocalExecutorListForSearch([...searchedAgentList]);

        } else {
            setLocalExecutorListForSearch([...executorFullList]);
        }
    }

    return (
        <div>
            <Container fluid style={{
                backgroundColor: colors.backgroundColorTheme,
                height: '100vh',
                overflow:"auto"
            }}>

                <Row >

                </Row>
                <Row style={{ marginTop: 20, marginLeft: 20 }}>

                    <Col  >
                        <Memory
                            style={{ width: 80, height: 80, color: "#fff" }} />
                        <Title >Executors</Title>
                    </Col>
                    <Col style={{ textAlign: "right" }} >
                        <SearchContainerStyle

                        >
                            <Search style={{ width: 28, height: 28, color: "#fff" }} />
                            <SearchInputText
                                id="agentSearch"
                                type="text" value={agentSearchText}
                                onChange={(e) => { handleSearch(e.target.value) }}
                                placeholder="Search executor..."
                                className="agentSearchClass"

                            />
                        </SearchContainerStyle>
                    </Col>


                </Row>
                <Row style={{ margin: 20 }}>
                    <Col style={{ textAlign: "right" }} onClick={() => {
                        dispatch(getModelList());
                        setCreateExecutorName("");
                      //  setShowCreateExecutor(!showCreateExecutor);
                        setIsForUpdateExecutor(false);
                        setExecutorListForCreate([...executorFullList]);
                        dispatch(getCampaignList());
                        setSelectedCampaign({});
                        setSelectedModel({})
                        setPrompt("");
                        setSelectedExecutorForCreate({});
                        setPromptInput("");
                        

                    }} >
                        <PostAdd
                            style={{ width: 24, height: 24, color: colors.activeBlue }} />
                        <CreateAgent >Create Executor</CreateAgent>
                    </Col>
                </Row>
                <Row >
                    <div class="row" >
                        <div class="col-xs-12">
                            <div class="table-responsive" data-pattern="priority-columns">
                                <table
                                    summary="This table shows how to create responsive tables using RWD-Table-Patterns' functionality"
                                    class="table table-bordered table-hover"
                                >

                                    <thead>
                                        <tr style={{ backgroundColor: colors.inputBackgroundColor }}>
                                            <TableHeader>ID</TableHeader>
                                            <TableHeader data-priority="2">Name</TableHeader>
                                            <TableHeader data-priority="1">Campaign</TableHeader>
                                            <TableHeader data-priority="2">Created</TableHeader>
                                           
                                            <TableHeader data-priority="1"></TableHeader>

                                        </tr>
                                    </thead>

                                    <tbody >
                                        {localExecutorListForSearch.map((data, i) => {
                                            return (
                                                <tr>
                                                    <TableData>{data.id}</TableData>
                                                    <TableData>{data.name}</TableData>
                                                    <TableData>{data.campaign}</TableData>

                                                    <TableData>{moment(data.created_at).format('LLL')}</TableData>
                                           
                                                    <TableData
                                                    // onClick={()=>{
                                                    //     dispatch(getOrganizationList());
                                                    //     setSelectedOrganizationDetails({});
                                                    //     setAddAgentName("");
                                                    // }}
                                                    >
                                                        <Col>

                                                            <Article
                                                                onClick={() => {
                                                                    setSelectedExecutorForMoreDetails(data);
                                                                    setShowExecutorDetails(!showExecutorDetails);

                                                                }}
                                                                style={{ width: 20, height: 20, color: colors.activeBlue }} />

                                                            <IconMenuTitle
                                                                onClick={() => {
                                                                    setSelectedExecutorForMoreDetails(data);
                                                                    setShowExecutorDetails(!showExecutorDetails);
                                                                }}
                                                                style={{ color: colors.activeBlue }}
                                                            >Details</IconMenuTitle>
                                                            <Edit
                                                                onClick={() => {
                                                                    dispatch(getModelList());
                                                                    setCreateExecutorName(data.name);
                                                                  //  setShowCreateExecutor(!showCreateExecutor);
                                                                    setIsForUpdateExecutor(true);
                                                                    setExecutorListForCreate([...executorFullList]);
                                                                    dispatch(getCampaignList());
                                                                    setSelectedCampaign({name:data.campaign,id:data.campaign});
                                                                    setSelectedModel({name:data.model_name,id:data.model_name})
                                                                    setPrompt(data.prompt); 
                                                                    setSelectedExecutorForCreate({name:data.executor_connect,id:data.executor_connect});
                                                                    setPromptInput("");
                                                                    setSelectedExecutorForMoreDetails(data);


                                                                }}
                                                                style={{ width: 20, height: 20, color: "orange", marginLeft: 20 }} />
                                                            <IconMenuTitle
                                                                onClick={() => {
                                                                    dispatch(getModelList());
                                                                    setCreateExecutorName(data.name);
                                                                  //  setShowCreateExecutor(!showCreateExecutor);
                                                                    setIsForUpdateExecutor(true);
                                                                    setExecutorListForCreate([...executorFullList]);
                                                                    dispatch(getCampaignList());
                                                                    setSelectedCampaign({name:data.campaign,id:data.campaign});
                                                                    setSelectedModel({name:data.model_name,id:data.model_name})
                                                                    setPrompt(data.prompt); 
                                                                    setSelectedExecutorForCreate({name:data.executor_connect,id:data.executor_connect});
                                                                    setPromptInput("");
                                                                    setSelectedExecutorForMoreDetails(data);
                                                                }}
                                                                style={{ color: "orange" }}
                                                            >Edit</IconMenuTitle>
                                                            <Delete
                                                                onClick={() => {
                                                                    setSelectedExecutorForMoreDetails(data);
                                                                    setShowDeleteExecutor(true);
                                                                }}

                                                                style={{ width: 20, height: 20, color: colors.FailedTheme, marginLeft: 20 }} />
                                                            <IconMenuTitle
                                                                onClick={() => {
                                                                    setSelectedExecutorForMoreDetails(data);
                                                                    setShowDeleteExecutor(true);
                                                                }}
                                                                style={{ color: colors.FailedTheme }}
                                                            >Remove</IconMenuTitle>

                                                        </Col>




                                                    </TableData>

                                                </tr>
                                            );
                                        })}


                                    </tbody>

                                </table>
                            </div>
                            {localExecutorListForSearch.length == 0 ? 
                                        
                                        <center> <label style={{marginTop:5,color:colors.inputBackgroundColor,fontWeight:"bolder"}}>Executor not found</label></center>:null}
                        </div>
                    </div>



                </Row>


            </Container>
            <Loader active={executorLoader || campaignLoader ||modelLoader} title="Loading..." />
            <ShowExecutor
                active={showExecutorDetails}
                handleClose={() => { setShowExecutorDetails(!showExecutorDetails) }}
                executorDetails={selectedExecutorForMoreDetails}
            />
            <CreateCampaignDialog
                active={showCreateExecutor}
                handleClose={() => {
                    setShowCreateExecutor(!showCreateExecutor);
                }}
                title={isForUpdateExecutor ? "Update Executor" : "Create Executor"}
                createExecutorName={createExecutorName}
                setCreateExecutorName={(value) => {
                    setCreateExecutorName(value);
                }}
                handleCreateInExecutor={handleCreateInExecutor}
                isForUpdateExecutor={isForUpdateExecutor}
                setPrompt={(value)=>{
                    setPrompt(value)
                }}
                prompt={prompt}
                promptInput={promptInput}
                setPromptInput={(value)=>{
                    setPromptInput(value);
                }}
                setShowPromptDialog={(value)=>{
                    setShowPromptDialog(value);
                }}
                showPromptDialog={showPromptDialog}
                isPromptDataForMoreDetails={isPromptDataForMoreDetails}
                selectedExecutorForCreate={selectedExecutorForCreate}
                setSelectedExecutorForCreate={(value)=>{
                    setSelectedExecutorForCreate(value)
                }}
                executorListForCreate={executorListForCreate}
                setShowSelecteModel={(value)=>{
                    setShowSelecteModel(value);
                }}
                showSelecteModel={showSelecteModel}
                handleFilterModel={handleFilterModel}
                showSelectCampaign={showSelectCampaign}
                setShowSelectCampaign={(value)=>{
                    setShowSelectCampaign(value);
                }}
                campaignListLocal={campaignListLocal}
                selectedCampaign={selectedCampaign}
                setSelectedCampaign={(value)=>{
                    setSelectedCampaign(value)
                }}
                handleFilterCampaign={handleFilterCampaign}
                localModelList={localModelList}
                showModelSelectDialog={showModelSelectDialog}
                setShowModelSelectDialog={(value)=>{
                    setShowModelSelectDialog(value);
                }}
                selectedModel={selectedModel}
                setSelectedModel={(value)=>{
                    setSelectedModel(value);
                }}
                handleFilterModel1={handleFilterModel1}

               
    
            />
            <CampaignAction
                active={showCampaignAction}
                handleClose={() => {
                    setShowCampaignAction(!showCampaignAction);
                }}
                title={campaignAction == "generate" ? "Generate" : "Transcibe"}
                campaingActionInput={campaingActionInput}
                setCampaingActionInput={(value) => {
                    setCampaingActionInput(value);
                }}
                handleAction={handleAction}
                campaignAction={campaignAction}
            />
            <DialogBox
                active={showCreateCampainWarning}
                title={isForUpdateExecutor ? "Update" : "Create"}
                subTitle={isForUpdateExecutor ? "Do you want update executor?" : "Do you want to create executor?"}
                twoButton={true}
                leftButtonClick={() => {
                    setShowCreateCampainWarning(!showCreateCampainWarning);
                    if (isForUpdateExecutor) {

                        dispatch(updateExecutor({
                            body:{
                                name: createExecutorName,
                                model_name: selectedModel.id,
                                executor_connect: selectedExecutorForCreate.id ? selectedExecutorForCreate.id:null,
                                campaign: selectedCampaign.id,
                                prompt: prompt
                            },
                            id: selectedExecutorForMoreDetails.id
                        }));
                    } else {
                        dispatch(createModel({
                            name: createExecutorName,
                            model_name: selectedModel.id,
                            executor_connect: selectedExecutorForCreate.id ? selectedExecutorForCreate.id:null,
                            campaign: selectedCampaign.id,
                            prompt: prompt
                        }));
                    }

                }}
                lefttButtonTitle={isForUpdateExecutor ? " Update" : "Create"}
                rightButtonClick={() => {
                    setShowCreateCampainWarning(!showCreateCampainWarning);
                }}
                rightButtonTitle={"Close"}

            />

            <DialogBox
                subTitle={"Do you want to remove executor?"}
                title={"Remove"}
                active={showDeleteExecutor}

                twoButton={true}
                lefttButtonTitle="Yes"
                rightButtonTitle="No"
                rightButtonClick={() => {
                    setShowDeleteExecutor(false);

                }}
                leftButtonClick={() => {
                    setShowDeleteExecutor(false);
                    dispatch(deleteExecutor(selectedExecutorForMoreDetails.id))
                }}

            />


            <DialogBox
                active={showCampaignActionWarning}
                title={campaignAction == "generate" ? "Generate" : "Transcribe"}
                subTitle={campaignAction == "generate"  ? "Do you want generate campaign?" : "Do you want to transcribe campaing?"}
                twoButton={true}
                leftButtonClick={() => {
                    setShowCampaignActionWarning(!showCampaignActionWarning);
                    if (campaignAction == "generate" ) {

                        dispatch(generateCampaign({
                            body: { name: campaingActionInput },
                            id: selectedExecutorForMoreDetails.id
                        }));
                    } else {
                        dispatch(transcribeCampaign({
                            body: { name: campaingActionInput },
                            id: selectedExecutorForMoreDetails.id
                        }));
                    }
    
                }}
                lefttButtonTitle={campaignAction == "generate"  ? "Generate" : "Transcribe"}
                rightButtonClick={() => {
                    setShowCampaignActionWarning(!showCampaignActionWarning);
                }}
                rightButtonTitle={"Close"}

            />


            <Toaster />


        </div>

    );
}

export default ExecutorHome;