import React from "react";
import { useSelector, useDispatch } from 'react-redux'; 
import UnauthHome from "../app/unAuth";

const Authenticated = ({children})=>{
    const authStore = useSelector((state) => state.auth);
    const {
        loggedIn
    } = authStore; 
    console.log("loggedIn",loggedIn);
    return loggedIn == true ? children : <UnauthHome/>
}

export default Authenticated;