import React, { useState, useEffect } from "react";

import {
    Container,
    Row,
    Col,
    Card,
    Button
} from 'react-bootstrap';
import './index.css';
import {
    Title,
    SearchContainerStyle,
    SearchInputText,
    TableHeader,
    TableData,
    CreateAgent,
    IconMenuTitle
} from './styles';
import { colors } from '../../theme';
import {
    SupportAgentOutlined,
    Search,
    GroupAdd,
    Edit, Delete,
    AccountCircle, Logout,
    LocationCity
} from '@mui/icons-material';
import { Loader, DialogBox, Toaster } from '../../component';
import { useSelector, useDispatch } from 'react-redux';
import { setAuthToken } from "../../server/api";
import { setAuthToken2 } from "../../server/api2";
import { Route, useNavigate } from "react-router-dom";
import * as Routing from '../../routes/routes';
import { setLoggedIn } from "../../redux/reducers/auth";
import {
    AddAgentTitle,
    AddAgentInputTitle,
    InputText,
    OrganizationItem
} from './styles';
import { cleanOrganizationListForAddAgentStatus, getOrganizationList } from "../../redux/reducers/organization";
import moment from "moment";
const OrganizationHome = () => {
    const dispatch = useDispatch();
    const organizationStore = useSelector((state) => state.organization);
    const authStore = useSelector((state) => state.auth);
    const {
        organizationLoader,
        organizationFullList,
        organizationListForAddAgentStatus
    } = organizationStore;
    const navigate = useNavigate();
    const [showLogoutWarning, setShowLogoutWarning] = useState(false);
    const [selectedOrganization, setSelectedOrganization] = useState(false);
    useEffect(() => {
        dispatch(getOrganizationList())
    }, []);
    useEffect(() => {
        if (organizationListForAddAgentStatus == "success") {
            setSelectedOrganization(organizationFullList[0]);
            dispatch(cleanOrganizationListForAddAgentStatus());
        } else if (organizationListForAddAgentStatus == "failed") {
            dispatch(cleanOrganizationListForAddAgentStatus())
        }
    }, [organizationListForAddAgentStatus]);



    return (
        <div>
            <Container fluid style={{
                backgroundColor: colors.backgroundColorTheme,
                height: '100vh',
                overflow: "auto"
            }}>

                <center>
                    <Card
                        onClick={() => {


                        }}
                        style={{
                            backgroundColor: colors.splitLineColor,
                            marginTop: "30vh",
                            padding: 10,
                            width: "40vw",

                        }}
                    >
                        <center>
                            <LocationCity
                                style={{ width: 100, height: 100, color: colors.white }}
                            />
                             <h5 style={{ color: colors.white, marginTop: 4 }}>Organization</h5>
                             <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Campaign</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='Select Campaign'
                                    className='agentSearchClass'
                                    value={selectedOrganization?.name ? selectedOrganization?.name : ""}
                                    style={{backgroundColor:colors.backgroundColorTheme}}
                                   
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>ID</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='Select Campaign'
                                    className='agentSearchClass'
                                    value={selectedOrganization?.id ? selectedOrganization?.id : ""}
                                    style={{backgroundColor:colors.backgroundColorTheme}}
                                   
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 ,marginBottom:30}} >
                            <Col>
                                <AddAgentInputTitle>Created At</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='Select Campaign'
                                    className='agentSearchClass'
                                    value={selectedOrganization?.created_at ? moment(selectedOrganization.created_at).format('LLL') : ""}
                                    style={{backgroundColor:colors.backgroundColorTheme}}
                                   
                                />
                            </Col>
                        </Row>


                        </center>


                    </Card>
                </center>


            </Container>
            <Loader active={organizationLoader} title="Loading..." />

            <Toaster />

            <DialogBox
                subTitle={"Do you want to logout agent?"}
                title={"LOGOUT"}
                active={showLogoutWarning}

                twoButton={true}
                lefttButtonTitle="Yes"
                rightButtonTitle="No"
                rightButtonClick={() => {
                    setShowLogoutWarning(false);


                }}
                leftButtonClick={() => {
                    setShowLogoutWarning(false);
                    dispatch(setLoggedIn(false));
                    setAuthToken("");
                    setAuthToken2("");
                    navigate(Routing.Login);



                }}

            />


        </div>

    );
}

export default OrganizationHome;