import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Card } from '@mui/material';
import { colors } from '../../theme';
import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import {
    AddAgentTitle,
    AddAgentInputTitle,
    InputText,
    OrganizationItem
} from './styles';
import './index.css';
const CreateAgentDialog = ({
    active,
    handleClose,
    title,
    createCampaignName,
    setCreateCampaignName,
    handleCreateInManifest,
    isForUpdateManifest,
    selectedConstructor,
    setSelectedConstructor,
    selectedCampaign,
    setSelectedCampaign,
    selectedAgent,
    setSelectedAgent,
    localListAgent,
    localListCampaign,
    setLocalListCampaign,
    setShowselectChampign, showselectChampign,
    handleFilterCampaign,
    localListConstructor,
    setLocalListConstructor,
    showselectConstructor,
    setShowselectConstructor,
    handleFilterConstructor,
    showselectAgent,
    setShowselectAgent,
    handleFilterAgent,
    setSelectedAudioFile
}) => {

    return (
        <>
            <Dialog

                open={active}
            >
                <DialogTitle style={{
                    backgroundColor: colors.inputBackgroundColor,
                    color: colors.white
                }}>
                    <AddAgentTitle>{title}</AddAgentTitle></DialogTitle>
                <DialogContent style={{ backgroundColor: colors.inputBackgroundColor }}>
                    <Container fluid>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Agent</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='Select Agent'
                                    className='agentSearchClass'
                                    value={selectedAgent?.name ? selectedAgent?.name : ""}
                                    onClick={()=>{
                                        setShowselectAgent(!showselectAgent);
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Campaign</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='Select Campaign'
                                    className='agentSearchClass'
                                    value={selectedCampaign?.name ? selectedCampaign?.name : ""}
                                    onClick={() => {
                                        setShowselectChampign(!showselectChampign);
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Constructor</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='Select Constructor'
                                    className='agentSearchClass'
                                    value={selectedConstructor?.name ? selectedConstructor?.name : ""}
                                    onClick={() => {
                                        setShowselectConstructor(!showselectConstructor);
                                    }}
                                  
                                />
                            </Col>
                        </Row>
                        {isForUpdateManifest ? null:
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                        <Col>
                            <AddAgentInputTitle>Audio File</AddAgentInputTitle>
                        </Col>
                        <Col>

                            <InputText
                                placeholder='Select Audio File'
                                className='agentSearchClass'
                                //value={selectedConstructor?.name ? selectedConstructor?.name : ""}
                                // onClick={() => {
                                //     setShowselectConstructor(!showselectConstructor);
                                // }}
                                type="file" 
                                name="file" 
                                 onChange={(value)=>{
                                    //  console.log(value.target.files);   
                                    setSelectedAudioFile(value.target.files)
                                 }} 
                                 
                                accept="audio/*"
                                multiple="multiple"
                              
                            />
                         
                        </Col>
                    </Row>
                    }
                        

                    </Container>
                </DialogContent>
                <DialogActions style={{ backgroundColor: colors.inputBackgroundColor, paddingBottom: 16 }}>
                    <Button onClick={() => {
                        handleCreateInManifest()
                    }}
                        style={{
                            color: colors.white,
                            fontWeight: "bolder"
                        }}

                    > {isForUpdateManifest ? "Update" : "Create"}</Button>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>

            <Dialog

                open={showselectChampign}

            >
                <DialogTitle style={{
                    backgroundColor: colors.inputBackgroundColor,
                    color: colors.white
                }}>
                    <AddAgentTitle>Choose Campaign</AddAgentTitle></DialogTitle>
                <DialogContent style={{ backgroundColor: colors.inputBackgroundColor }}>
                    <Container fluid>
                        <Row style={{ width: "30vw", marginTop: 16 }} >

                            <Col>
                                <center>
                                    <InputText
                                        placeholder='Search Campaign'
                                        className='agentSearchClass'
                                        style={{ textAlign: "center", width: 200 }}
                                        onChange={(e) => {
                                            handleFilterCampaign(e.target.value);
                                        }}


                                    />
                                </center>

                            </Col>
                        </Row>

                        <ul style={{ width: "30vw", marginTop: 16, height: "40vh", listStyleType: "none", padding: 0 }} >

                            {localListCampaign.length == 0 &&

                                <OrganizationItem>Campaign not found</OrganizationItem>
                            }
                            {localListCampaign.map((data, i) => {
                                return (
                                    <Card
                                        onClick={() => {
                                            setSelectedCampaign(data);
                                            setShowselectChampign(!showselectChampign);

                                        }}
                                        style={{
                                            backgroundColor: colors.splitLineColor,
                                            marginTop: 20,
                                            padding: 10
                                        }}
                                    >
                                        <OrganizationItem>{data.name}</OrganizationItem>
                                        <OrganizationItem style={{ color: colors.activeBlue, fontWeight: "600", fontSize: 13 }}>ID : {data.id}</OrganizationItem>
                                    </Card>
                                );
                            })}


                        </ul>

                    </Container>

                </DialogContent>
                <DialogActions style={{ backgroundColor: colors.inputBackgroundColor }}>
                    <Button onClick={() => {
                        setShowselectChampign(!showselectChampign);
                    }}>Close</Button>
                </DialogActions>
            </Dialog>


            <Dialog
                open={showselectConstructor}
            >
                <DialogTitle style={{
                    backgroundColor: colors.inputBackgroundColor,
                    color: colors.white
                }}>
                    <AddAgentTitle>Choose Construction</AddAgentTitle></DialogTitle>
                <DialogContent style={{ backgroundColor: colors.inputBackgroundColor }}>
                    <Container fluid>
                        <Row style={{ width: "30vw", marginTop: 16 }} >

                            <Col>
                                <center>
                                    <InputText
                                        placeholder='Search Construction'
                                        className='agentSearchClass'
                                        style={{ textAlign: "center", width: 200 }}
                                        onChange={(e) => {
                                            handleFilterConstructor(e.target.value);
                                        }}


                                    />
                                </center>

                            </Col>
                        </Row>

                        <ul style={{ width: "30vw", marginTop: 16, height: "40vh", listStyleType: "none", padding: 0 }} >

                            {localListConstructor.length == 0 &&

                                <OrganizationItem>Construction not found</OrganizationItem>
                            }
                            {localListConstructor.map((data, i) => {
                                return (
                                    <Card
                                        onClick={() => {
                                            setSelectedConstructor(data);
                                            setShowselectConstructor(!showselectConstructor);

                                        }}
                                        style={{
                                            backgroundColor: colors.splitLineColor,
                                            marginTop: 20,
                                            padding: 10
                                        }}
                                    >
                                        <OrganizationItem>{data.name}</OrganizationItem>
                                        <OrganizationItem style={{ color: colors.activeBlue, fontWeight: "600", fontSize: 13 }}>ID : {data.id}</OrganizationItem>
                                    </Card>
                                );
                            })}


                        </ul>

                    </Container>

                </DialogContent>
                <DialogActions style={{ backgroundColor: colors.inputBackgroundColor }}>
                    <Button onClick={() => {
                        setShowselectConstructor(!showselectConstructor);
                    }}>Close</Button>
                </DialogActions>
            </Dialog>


            <Dialog
                open={showselectAgent}
            >
                <DialogTitle style={{
                    backgroundColor: colors.inputBackgroundColor,
                    color: colors.white
                }}>
                    <AddAgentTitle>Select Agent</AddAgentTitle></DialogTitle>
                <DialogContent style={{ backgroundColor: colors.inputBackgroundColor }}>
                    <Container fluid>
                        <Row style={{ width: "30vw", marginTop: 16 }} >

                            <Col>
                                <center>
                                    <InputText
                                        placeholder='Search Agent'
                                        className='agentSearchClass'
                                        style={{ textAlign: "center", width: 200 }}
                                        onChange={(e) => {
                                            handleFilterAgent(e.target.value);
                                        }}


                                    />
                                </center>

                            </Col>
                        </Row>

                        <ul style={{ width: "30vw", marginTop: 16, height: "40vh", listStyleType: "none", padding: 0 }} >

                            {localListAgent.length == 0 &&

                                <OrganizationItem>Agent not found</OrganizationItem>
                            }
                            {localListAgent.map((data, i) => {
                                return (
                                    <Card
                                        onClick={() => {
                                            setSelectedAgent(data);
                                            setShowselectAgent(!showselectAgent);

                                        }}
                                        style={{
                                            backgroundColor: colors.splitLineColor,
                                            marginTop: 20,
                                            padding: 10
                                        }}
                                    >
                                        <OrganizationItem>{data.name}</OrganizationItem>
                                        <OrganizationItem style={{ color: colors.activeBlue, fontWeight: "600", fontSize: 13 }}>ID : {data.id}</OrganizationItem>
                                    </Card>
                                );
                            })}


                        </ul>

                    </Container>

                </DialogContent>
                <DialogActions style={{ backgroundColor: colors.inputBackgroundColor }}>
                    <Button onClick={() => {
                         setShowselectAgent(!showselectAgent);
                    }}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CreateAgentDialog;