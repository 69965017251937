import React, { useState, useEffect } from "react";

import {
    Container,
    Row,
    Col,

} from 'react-bootstrap';
import './index.css';
import {
    Title,
    SearchContainerStyle,
    SearchInputText,
    TableHeader,
    TableData,
    CreateAgent,
    IconMenuTitle
} from './styles';
import { Button } from '@mui/material';
import { colors } from '../../theme';
import {
    Search,
    Edit, Delete,
    PostAdd,
    Article,
    ModelTraining
} from '@mui/icons-material';
import { Loader, DialogBox, Toaster } from '../../component';
import { useSelector, useDispatch } from 'react-redux';

import {
    getCampaignList,
    cleanCampaignListStatus,
    clearCreateCampaign,
    createCampaign,
    updateCampaign,
    clearDeleteCampaign,
    deleteCampaign,
    generateCampaign,
    clearCampaignAction,
    transcribeCampaign,

} from '../../redux/reducers/campaign';

import {
    getModelList,
    createModel,
    cleanCreateModel,
    generateModel,
    updateModel,
    deleteModel,
    clearDeleteModel,
    clearModelListStatus
} from '../../redux/reducers/model';

import moment from "moment";
import CreateModelDialog from "./createModelDialog";
import { toast } from 'react-toastify';
import ShowModel from "./showModelDetails";
import CampaignAction from "./campaignActions";

const ModelHome = () => {
    const dispatch = useDispatch();

    const organizationStore = useSelector((state) => state.organization);
    const campaignStore = useSelector((state) => state.campaign);
    const modelStore = useSelector((state) => state.model);

    const {
        organizationFullList,
    } = organizationStore;
    const {
        modelLoader,
        modelFullList,
        createModelStatus,
        createModelMessage,
        deleteModelStatus,
        deleteModelMessage,
        modelListStatus
    } = modelStore;
    const {
        campaignLoader,
        campaignList,
        campaignListStatus,
        campaignListMessage,
        createCampaignStatus,
        createCampaignMessage,
        deleteCampaignStatus,
        deleteCampaignMessage,
        campaignActionStatus,
        campaignActionMessage
    } = campaignStore;


    const [agentSearchText, setAgentSearchText] = useState("");
    const [showCreateAgentDialog, setShowCreateAgentDialog] = useState(false);

    const [localOrganizationFullList, setLocalOrganizationFullList] = useState([]);


    // new
    const [showModelDetails, setShowModelDetails] = useState(false);
    const [selectedModelForMoreDetails, setSelectedModelForMoreDetails] = useState({});
    const [showCreateModel, setShowCreateModel] = useState(false);
    const [createModelName, setCreateModelName] = useState("");
    const [showCreateModelWarning, setShowCreateModelWarning] = useState(false);
    const [isForUpdateModel, setIsForUpdateModel] = useState(false);
    const [showDeleteModel, setShowDeleteModel] = useState(false);
    const [campaignAction, setCampaignAction] = useState("");
    const [showCampaignAction, setShowCampaignAction] = useState(false);
    const [campaingActionInput, setCampaingActionInput] = useState("");
    const [showCampaignActionWarning, setShowCampaignActionWarning] = useState(false);
    const [showSelectCampaign, setShowSelectCampaign] = useState(false);
    const [showTrainDataDialog, setShowTrainDataDialog] = useState(false);

    const [campaignListLocal, setCampaignListLocal] = useState([]);
    const [selectedCampaign, setSelectedCampaign] = useState({});
    const [trainDataSet, setTrainDataSet] = useState("");
    const [trainDataSetFinal, setTrainDataSetFinal] = useState("");
    const [isForGenerateModel, setIsForGenerateModel] = useState(false);
    const [isTrainDataForMoreDetails, setIsTrainDataForMoreDetails] = useState(false);
    const [localModelList, setLocalModelList] = useState([]);



    useEffect(() => {
        dispatch(getModelList());

    }, []);
    useEffect(() => {
        if (campaignListStatus == "success") {
            setShowCreateModel(!showCreateModel);
            setCampaignListLocal(campaignList);
            dispatch(cleanCampaignListStatus());
        } else if (campaignListStatus == "failed") {
            toast.error(`${campaignListMessage}`);
            dispatch(cleanCampaignListStatus());
        }
    }, [campaignListStatus]);
    useEffect(() => {
        if (modelListStatus == "success") {
            setLocalModelList([...modelFullList]);
            dispatch(clearModelListStatus())
        } else if (modelListStatus == "failed") {
            dispatch(clearModelListStatus())
        }
    }, [modelListStatus]);

    
    useEffect(() => {
        if (createModelStatus == "success") {
            dispatch(getModelList());
            dispatch(cleanCreateModel());
            setIsForUpdateModel(false);
            toast.success(isForUpdateModel ? "Update Successfully" : `Create Successfully`);
        } else if (createModelStatus == "failed") {
            toast.error(`${createModelMessage}`);
            dispatch(cleanCreateModel());
        }
    }, [createModelStatus]);
    useEffect(() => {
        if (deleteModelStatus == "success") {
            dispatch(getModelList());
            dispatch(clearDeleteModel());

            toast.success(`Deleted Successfully`);
        } else if (deleteCampaignStatus == "failed") {
            toast.error(`${deleteCampaignMessage}`);
            dispatch(clearDeleteModel());
        }
    }, [deleteModelStatus]);
    useEffect(() => {
        if (campaignActionStatus == "success") {
            dispatch(getModelList());
            toast.success(campaignAction == "generate" ? `Generated Successfully` : `Transcribe Successfully`);
            dispatch(clearCampaignAction());
        } else if (campaignActionStatus == "failed") {
            toast.error(`${campaignActionMessage}`);
            dispatch(clearCampaignAction());
        }
    }, [campaignActionStatus]);

    const handleFilterCampaign = (text) => {
        if (text !== "") {
            let searchedOrganizationList = campaignList.filter((item) =>
                item.name.toLowerCase().includes(text.toLowerCase()) ? item : null
            );
            setCampaignListLocal([...searchedOrganizationList]);

        } else {
            setCampaignListLocal([...campaignList]);
        }
    }









    const closeCreateAgentDialog = () => {
        setShowCreateAgentDialog(!showCreateAgentDialog);
    }
    const handleFilterOrganization = (text) => {
        if (text !== "") {
            let searchedOrganizationList = organizationFullList.filter((item) =>
                item.name.toLowerCase().includes(text.toLowerCase()) ? item : null
            );
            setLocalOrganizationFullList([...searchedOrganizationList]);

        } else {
            setLocalOrganizationFullList([...organizationFullList]);
        }
    };
    const handleCreateInCampaign = () => {
        if (createModelName && selectedCampaign) {
            setShowCreateModel(!showCreateModel);
            setShowCreateModelWarning(!showCreateModelWarning);
        } else {
            toast.error(`Empty Field Found!`);
        }
    }
    const handleAction = () => {
        if (campaingActionInput) {
            setShowCampaignAction(!showCampaignAction);
            setShowCampaignActionWarning(!showCampaignActionWarning);
        } else {
            toast.error(`Empty Field Found!`);
        }

    }
    const handleSearch = (text) => {
        setAgentSearchText(text);
        if (text !== "") {
            let searchedModelList = modelFullList.filter((item) =>
                item.name.toLowerCase().includes(text.toLowerCase()) ? item : null
            );
            setLocalModelList([...searchedModelList]);

        } else {
            setLocalModelList([...modelFullList]);
        }
    }

    return (
        <div>
            <Container fluid style={{
                backgroundColor: colors.backgroundColorTheme,
                height: '100vh'
            }}>

                <Row >

                </Row>
                <Row style={{ marginTop: 20, marginLeft: 20 }}>

                    <Col  >
                        <ModelTraining
                            style={{ width: 80, height: 80, color: "#fff" }} />
                        <Title >Model</Title>
                    </Col>
                    <Col style={{ textAlign: "right" }} >
                        <SearchContainerStyle

                        >
                            <Search style={{ width: 28, height: 28, color: "#fff" }} />
                            <SearchInputText
                                id="agentSearch"
                                type="text" value={agentSearchText}
                                onChange={(e) => { handleSearch(e.target.value) }}
                                placeholder="Search Model..."
                                className="agentSearchClass"

                            />
                        </SearchContainerStyle>
                    </Col>


                </Row>
                <Row style={{ margin: 20 }}>
                    <Col style={{ textAlign: "right" }} onClick={() => {

                        setTrainDataSet("");
                        setTrainDataSetFinal("");
                        setSelectedCampaign({});
                        setCreateModelName("");
                        setIsForUpdateModel(false);
                        dispatch(getCampaignList());

                    }} >
                        <PostAdd
                            style={{ width: 24, height: 24, color: colors.activeBlue }} />
                        <CreateAgent >Create Model</CreateAgent>
                    </Col>
                </Row>
                <Row >
                    <div class="row" >
                        <div class="col-xs-12">
                            <div class="table-responsive" data-pattern="priority-columns">
                                <table
                                    summary="This table shows how to create responsive tables using RWD-Table-Patterns' functionality"
                                    class="table table-bordered table-hover"
                                >

                                    <thead>
                                        <tr style={{ backgroundColor: colors.inputBackgroundColor }}>
                                            <TableHeader>ID</TableHeader>
                                            <TableHeader data-priority="2">Name</TableHeader>

                                            <TableHeader data-priority="2">Campaign</TableHeader>
                                            <TableHeader data-priority="1">Created</TableHeader>
                                            <TableHeader data-priority="1">Action</TableHeader>
                                            <TableHeader data-priority="1"></TableHeader>

                                        </tr>
                                    </thead>

                                    <tbody >
                                        {localModelList.map((data, i) => {
                                            return (
                                                <tr>
                                                    <TableData>{data.id}</TableData>
                                                    <TableData>{data.name}</TableData>
                                                    <TableData>{data.campaign}</TableData>
                                                    <TableData>{moment(data.created_at).format('LLL')}</TableData>
                                                    <TableData>
                                                        <Col >
                                                            <Button
                                                                onClick={() => {

                                                                    setIsForUpdateModel(false);

                                                                    setIsForGenerateModel(true);
                                                                    setSelectedModelForMoreDetails(data);
                                                                    setShowCampaignActionWarning(!showCampaignActionWarning);
                                                                    setCampaignAction("generate");
                                                                }}
                                                                style={{
                                                                    color: colors.white,
                                                                    fontWeight: "bolder",
                                                                    backgroundColor: colors.generate
                                                                }}
                                                            >Generate</Button>


                                                        </Col>
                                                    </TableData>
                                                    <TableData
                                                    // onClick={()=>{
                                                    //     dispatch(getOrganizationList());
                                                    //     setSelectedOrganizationDetails({});
                                                    //     setAddAgentName("");
                                                    // }}
                                                    >
                                                        <Col>

                                                            <Article
                                                                onClick={() => {
                                                                    setSelectedModelForMoreDetails(data);
                                                                    setShowModelDetails(!showModelDetails);
                                                                    setIsTrainDataForMoreDetails(true);

                                                                }}
                                                                style={{ width: 20, height: 20, color: colors.activeBlue }} />

                                                            <IconMenuTitle
                                                                onClick={() => {
                                                                    setSelectedModelForMoreDetails(data);
                                                                    setShowModelDetails(!showModelDetails);
                                                                    setIsTrainDataForMoreDetails(true);
                                                                }}
                                                                style={{ color: colors.activeBlue }}
                                                            >Details</IconMenuTitle>
                                                            <Edit
                                                                onClick={() => {
                                                                    console.log("")
                                                                    // setTrainDataSet(data.train_data);
                                                                    // setTrainDataSetFinal(data.train_data);
                                                                    // setSelectedCampaign({ id: data.campaign, name: data.campaign });
                                                                    // setCreateModelName(data.name);
                                                                    // setSelectedModelForMoreDetails(data);
                                                                    // setIsForUpdateModel(true);
                                                                    setTrainDataSet(data.train_data);
                                                                    setTrainDataSetFinal(data.train_data);
                                                                    setSelectedCampaign({ id: data.campaign, name: data.campaign });
                                                                    setCreateModelName(data.name);
                                                                    setSelectedModelForMoreDetails(data);
                                                                    setIsForUpdateModel(true);
                                                                    // setShowCreateModel(!showCreateModel);
                                                                    setIsForGenerateModel(false);
                                                                    dispatch(getCampaignList());
                                                                    setIsTrainDataForMoreDetails(false);




                                                                }}
                                                                style={{ width: 20, height: 20, color: "orange", marginLeft: 20 }} />
                                                            <IconMenuTitle
                                                                onClick={() => {
                                                                    // setCreateModelName(data.name);
                                                                    // setIsForUpdateModel(true);
                                                                    // setShowCreateModel(!showCreateModel);
                                                                    // setSelectedModelForMoreDetails(data);
                                                                    setTrainDataSet(data.train_data);
                                                                    setTrainDataSetFinal(data.train_data);
                                                                    setSelectedCampaign({ id: data.campaign, name: data.campaign });
                                                                    setCreateModelName(data.name);
                                                                    setSelectedModelForMoreDetails(data);
                                                                    setIsForUpdateModel(true);
                                                                    //setShowCreateModel(!showCreateModel);
                                                                    setIsForGenerateModel(false);
                                                                    dispatch(getCampaignList());
                                                                    setIsTrainDataForMoreDetails(false);
                                                                }}

                                                                style={{ color: "orange" }}
                                                            >Edit</IconMenuTitle>
                                                            <Delete
                                                                onClick={() => {
                                                                    setSelectedModelForMoreDetails(data);
                                                                    setShowDeleteModel(true);
                                                                }}

                                                                style={{ width: 20, height: 20, color: colors.FailedTheme, marginLeft: 20 }} />
                                                            <IconMenuTitle
                                                                onClick={() => {
                                                                    setSelectedModelForMoreDetails(data);
                                                                    setShowDeleteModel(true);
                                                                }}
                                                                style={{ color: colors.FailedTheme }}
                                                            >Remove</IconMenuTitle>

                                                        </Col>




                                                    </TableData>

                                                </tr>
                                            );
                                        })}


                                    </tbody>

                                </table>
                            </div>
                            {localModelList.length == 0 ? 
                                        
                                        <center> <label style={{marginTop:5,color:colors.inputBackgroundColor,fontWeight:"bolder"}}>Agent not found</label></center>:null}
                        </div>
                    </div>



                </Row>


            </Container>
            <Loader active={modelLoader || campaignLoader} title="Loading..." />
            <ShowModel
                active={showModelDetails}
                handleClose={() => {
                    setShowModelDetails(!showModelDetails);
                    setIsTrainDataForMoreDetails(false);
                }}
                modelDetails={selectedModelForMoreDetails}
                setIsTrainDataForMoreDetails={() => {
                    setIsTrainDataForMoreDetails(true);
                    setShowTrainDataDialog(true);
                    setTrainDataSet(selectedModelForMoreDetails.train_data);
                }}
            />
            <CreateModelDialog
                active={showCreateModel}
                handleClose={() => {
                    setShowCreateModel(!showCreateModel);
                }}
                title={isForGenerateModel ? "Generate Model" : isForUpdateModel ? "Update Model" : "Create Model"}
                createModelName={createModelName}
                setCreateModelName={(value) => {
                    setCreateModelName(value);
                }}
                handleCreateInCampaign={handleCreateInCampaign}
                isForUpdateModel={isForUpdateModel}
                setShowSelectCampaign={(value) => { setShowSelectCampaign(value) }}
                showSelectCampaign={showSelectCampaign}
                campaignList={campaignList}
                handleFilterCampaign={handleFilterCampaign}
                campaignListLocal={campaignListLocal}
                setSelectedCampaign={(value) => {
                    setSelectedCampaign(value)
                }}
                selectedCampaign={selectedCampaign}
                showTrainDataDialog={showTrainDataDialog}
                setShowTrainDataDialog={(value) => { setShowTrainDataDialog(value) }}
                setTrainDataSet={(value) => setTrainDataSet(value)}
                trainDataSet={trainDataSet}
                trainDataSetFinal={trainDataSetFinal}
                setTrainDataSetFinal={(value) => setTrainDataSetFinal(value)}
                isForGenerateModel={isForGenerateModel}
                setIsForGenerateModel={setIsForGenerateModel}
                isTrainDataForMoreDetails={isTrainDataForMoreDetails}

            />
            <CampaignAction
                active={showCampaignAction}
                handleClose={() => {
                    setShowCampaignAction(!showCampaignAction);
                }}
                title={campaignAction == "generate" ? "Generate" : "Transcibe"}
                campaingActionInput={campaingActionInput}
                setCampaingActionInput={(value) => {
                    setCampaingActionInput(value);
                }}
                handleAction={handleAction}
                campaignAction={campaignAction}
            />
            <DialogBox
                active={showCreateModelWarning}
                title={isForGenerateModel ? "Generate" : isForUpdateModel ? "Update" : "Create"}
                subTitle={isForGenerateModel ?
                    "Do you want to generate model?" : isForUpdateModel ? "Do you want update model?" : "Do you want to create model?"}
                twoButton={true}
                leftButtonClick={() => {
                    setShowCreateModelWarning(!showCreateModelWarning);
                    if (isForGenerateModel) {
                        dispatch(generateModel({
                            body: {
                                name: createModelName,
                                campaign: selectedCampaign.id,
                                train_data: trainDataSetFinal,
                                id: selectedModelForMoreDetails.id
                            },
                            id: selectedModelForMoreDetails.id
                        }));
                    } else if (isForUpdateModel) {

                        dispatch(updateModel({
                            body: {
                                name: createModelName,
                                campaign: selectedCampaign.id,
                                train_data: trainDataSetFinal
                            },
                            id: selectedModelForMoreDetails.id
                        }));
                    } else {
                        dispatch(createModel({
                            name: createModelName,
                            campaign: selectedCampaign.id,
                            train_data: trainDataSetFinal

                        }));
                    }

                }}
                lefttButtonTitle={isForUpdateModel ? " Update" : "Create"}
                rightButtonClick={() => {
                    setShowCreateModelWarning(!showCreateModelWarning);
                }}
                rightButtonTitle={"Close"}

            />

            <DialogBox
                subTitle={"Do you want to remove campaign?"}
                title={"Remove"}
                active={showDeleteModel}

                twoButton={true}
                lefttButtonTitle="Yes"
                rightButtonTitle="No"
                rightButtonClick={() => {
                    setShowDeleteModel(false);

                }}
                leftButtonClick={() => {
                    setShowDeleteModel(false);
                    dispatch(deleteModel(selectedModelForMoreDetails.id))


                }}

            />


            <DialogBox
                active={showCampaignActionWarning}
                title={campaignAction == "generate" ? "Generate" : "Transcribe"}
                subTitle={campaignAction == "generate" ? "Do you want generate campaign?" : "Do you want to transcribe campaing?"}
                twoButton={true}
                leftButtonClick={() => {
                    setShowCampaignActionWarning(!showCampaignActionWarning);
                    if (campaignAction == "generate") {

                        dispatch(generateModel({
                            // body: { name: campaingActionInput },
                            id: selectedModelForMoreDetails.id
                        }));
                    } else {
                        dispatch(transcribeCampaign({
                            body: { name: campaingActionInput },
                            id: selectedModelForMoreDetails.id
                        }));
                    }

                }}
                lefttButtonTitle={campaignAction == "generate" ? "Generate" : "Transcribe"}
                rightButtonClick={() => {
                    setShowCampaignActionWarning(!showCampaignActionWarning);
                }}
                rightButtonTitle={"Close"}

            />


            <Toaster />


        </div>

    );
}

export default ModelHome;