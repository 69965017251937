import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API,{setAuthToken} from '../../server/api';
import * as ApiRouter from '../../server/apiRoutes'
import API2,{setAuthToken2} from '../../server/api2';
export const getManifestList = createAsyncThunk(
  'manifest/getManifestList',
  async () => {
     const result =  await API.get(ApiRouter.getManifest);
     console.log("result",JSON.stringify(result))
     return {result:result.data}
  }              
);
export const createManifest = createAsyncThunk(
  'manifest/createManifest',
  async (body) => {
    console.log("body",body);
    const formdata = new FormData();
    formdata.append("campaign", body.campaign);
    formdata.append("agent", body.agent);
    formdata.append("constructor", body.constructor);
    formdata.append("audio_file", body.audio_file);
     const result =  await API2.post(ApiRouter.getManifest,formdata);
     console.log("result createManifest",JSON.stringify(result))
     return {result:result.data}
  }              
);
export const updateManifest = createAsyncThunk(
  'manifest/updateManifest',
  async (data) => {
    console.log("body",data);
    const formdata = new FormData();
    formdata.append("campaign", data.body.campaign);
    formdata.append("agent", data.body.agent);
    formdata.append("constructor", data.body.constructor);
     const result =  await API2.put(ApiRouter.manifestModify(data.id),formdata);
     console.log("result createManifest",JSON.stringify(result))
     return {result:result.data}
  }              
);

export const deleteManifest = createAsyncThunk(
  'manifest/deleteManifest',
  async (manifestID) => {
     const result =  await API.delete(ApiRouter.manifestModify(manifestID));
     console.log("result",JSON.stringify(result))
     return {result:result.data}
  }              
);

export const generateManifest = createAsyncThunk(
  'manifest/generateManifest',
  async (data) => {
    console.log("body",data);
    const formdata = new FormData();
    formdata.append("campaign", data.body.campaign);
    formdata.append("agent", data.body.agent);
    formdata.append("constructor", data.body.constructor);
     const result =  await API2.post(ApiRouter.generateManifest(data.id),formdata);
     console.log("result generateManifest",JSON.stringify(result))
     return {result:result.data}
  }              
);
export const transcribeManifest = createAsyncThunk(
  'manifest/transcribeManifest',
  async (data) => {
    console.log("body",data);
    const formdata = new FormData();
    formdata.append("campaign", data.body.campaign);
    formdata.append("agent", data.body.agent);
    formdata.append("constructor", data.body.constructor);
     const result =  await API2.post(ApiRouter.transcribeManifest(data.id),formdata);
     console.log("result generateManifest",JSON.stringify(result))
     return {result:result.data}
  }              
);




const manifestSlice = createSlice({
  name: 'manifest',
  initialState: {
    manifestLoader: false,
    manifestList:[],
    manifestStatus:"",
    manifestMessage:"",
    createManifestStatus:"",
    createManifestMessage:"",
    deleteManifestMessage:"",
    deleteManifestStatus:"",
    manifestActionStatus:"",
    manifestActionMessage:"",
    updateManifestStatus:"",
    updateManifestMessage:""
  
  },
  reducers: {
  cleanManifestList:(state)=>{
    state.manifestStatus="";
    state.manifestMessage="";
  },
  clearCreateManifest:(state)=>{
    state.createManifestStatus="";
    state.createManifestMessage="";
  },
  clearDeleteManifest:(state)=>{
    state.deleteManifestStatus="";
    state.deleteManifestMessage="";
  },
  clearManifestAction:(state)=>{
    state.manifestActionStatus="";
    state.manifestActionMessage="";
  },
  clearUpdateManifest:(state)=>{
    state.updateManifestStatus="";
    state.updateManifestMessage="";
  },
  

  },
  extraReducers: (builder) => {


    builder.addCase(getManifestList.pending, (state) => {
      state.manifestLoader = true;
    });
    builder.addCase(getManifestList.fulfilled, (state, action) => {
      state.manifestLoader = false;
       state.manifestList = action.payload.result;
       state.manifestStatus = "success";
    });
    builder.addCase(getManifestList.rejected, (state, action) => {
      state.manifestLoader = false;
      state.manifestStatus = "failed";
      state.manifestMessage =action.error.message;
    });

    builder.addCase(createManifest.pending, (state) => {
      state.manifestLoader = true;
    });
    builder.addCase(createManifest.fulfilled, (state, action) => {
      state.manifestLoader = false;
       state.createManifestStatus = "success";
    });
    builder.addCase(createManifest.rejected, (state, action) => {
      state.manifestLoader = false;
      state.createManifestStatus = "failed";
      state.createManifestMessage =action.error.message;
    });

    builder.addCase(updateManifest.pending, (state) => {
      state.manifestLoader = true;
    });
    builder.addCase(updateManifest.fulfilled, (state, action) => {
      state.manifestLoader = false;
       state.updateManifestStatus = "success";
    });
    builder.addCase(updateManifest.rejected, (state, action) => {
      state.manifestLoader = false;
      state.updateManifestStatus = "failed";
      state.updateManifestMessage =action.error.message; 
    });

    builder.addCase(deleteManifest.pending, (state) => {
      state.manifestLoader = true;
    });
    builder.addCase(deleteManifest.fulfilled, (state, action) => {
      state.manifestLoader = false;
       state.deleteManifestStatus = "success";
    });
    builder.addCase(deleteManifest.rejected, (state, action) => {
      state.manifestLoader = false;
      state.deleteManifestStatus = "failed";
      state.deleteManifestMessage =action.error.message; 
    });

    builder.addCase(generateManifest.pending, (state) => {
      state.manifestLoader = true;
    });
    builder.addCase(generateManifest.fulfilled, (state, action) => {
      state.manifestLoader = false;
       state.manifestActionStatus = "success";
    });
    builder.addCase(generateManifest.rejected, (state, action) => {
      state.manifestLoader = false;
      state.manifestActionStatus = "failed";
      state.manifestActionMessage =action.error.message; 
    });

    builder.addCase(transcribeManifest.pending, (state) => {
      state.manifestLoader = true;
    });
    builder.addCase(transcribeManifest.fulfilled, (state, action) => {
      state.manifestLoader = false;
       state.manifestActionStatus = "success";
    });
    builder.addCase(transcribeManifest.rejected, (state, action) => {
      state.manifestLoader = false;
      state.manifestActionStatus = "failed";
      state.manifestActionMessage =action.error.message; 
    });

    
    
  },
});
export const {
  cleanManifestList,
  clearCreateManifest,
  clearDeleteManifest,
  clearManifestAction,
  clearUpdateManifest
} = manifestSlice.actions;
export default manifestSlice.reducer;
