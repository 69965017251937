import React from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Toaster(){
  return (  
      <ToastContainer 
      closeOnClick
      draggable
      position="bottom-right"
     
      />
  );
}

export default Toaster;