import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Card } from '@mui/material';
import { colors } from '../../theme';
import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import {
    AddAgentTitle,
    AddAgentInputTitle,
    InputText,
    OrganizationItem
} from './styles';
import './index.css';
import moment from 'moment';
const ShowCampain = ({
    active,
    handleClose,
    campainDetails
}) => {
 
    return (
        <>
            <Dialog

                open={active}
            >
                <DialogTitle style={{
                    backgroundColor: colors.inputBackgroundColor,
                    color: colors.white
                }}>
                    <AddAgentTitle>Details</AddAgentTitle></DialogTitle>
                <DialogContent style={{ backgroundColor: colors.inputBackgroundColor }}>
                    <Container fluid>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>ID</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={campainDetails.id}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Name</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={campainDetails.name}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Create At</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={moment(campainDetails.created_at).format('LLL')}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Organization</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={campainDetails.organization}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Server</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={campainDetails.server ?campainDetails.server : "None" }
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Transcribe Started</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={campainDetails?.transcribe_started}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Transcribe Ended</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={campainDetails?.transcribe_ended}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Transcribe Started At</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='Date'
                                    className='agentSearchClass'
                                    value={campainDetails.transcribe_started_at != null 
                                        ? moment(campainDetails.transcribe_started_at).format('LLL'):"None" }
                                    disabled
                                />
                            </Col>
                        </Row>
                        
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Transcribe Ended At</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='Date'
                                    className='agentSearchClass'
                                    value={campainDetails.generation_ended_at != null 
                                        ? moment(campainDetails.generation_ended_at).format('LLL'):"None" }
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Generation Started</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={campainDetails?.generation_started}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Generation Ended</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={campainDetails?.generation_ended}
                                    disabled
                                />
                            </Col>
                        </Row>


                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Generation Started At</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='Date'
                                    className='agentSearchClass'
                                    value={campainDetails.generation_started_at != null 
                                        ? moment(campainDetails.generation_started_at).format('LLL'):"None" }
                                    disabled
                                />
                            </Col>
                        </Row>
                        
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Generation Ended At</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='Date'
                                    className='agentSearchClass'
                                    value={campainDetails.generation_ended_at != null 
                                        ? moment(campainDetails.generation_ended_at).format('LLL'):"None" }
                                    disabled
                                />
                            </Col>
                        </Row>
                  
                    </Container>
                </DialogContent>
                <DialogActions style={{ backgroundColor: colors.inputBackgroundColor,paddingBottom:16 }}>
                {/* <Button onClick={()=>{
                    setShowAddAgentConfimation()
                }}  
                style={{
                    color:colors.white,
                    fontWeight:"bolder"
                }}
                
                > {isForUpdateAgent ? "Update":"Add"}</Button> */}
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>

          
        </>
    );
};

export default ShowCampain;