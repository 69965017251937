import styled from "styled-components";
import {colors} from '../../theme';
export const Title = styled.label`
  font-size: 3em;
  color: ${colors.white};
  position: relative;
  //width: 10vw;
 font-weight: bolder;
 font-size: 30px;
`;
export const SearchContainerStyle = styled.div`
 background-color:${colors.inputBackgroundColor};
 border-radius: 20px;
 padding-left: 24px;
 padding-right: 24px;
 padding-top: 5px;
 padding-bottom: 5px;
 width: 500px;
`;
export const SearchInputText = styled.input`
 background-color:${colors.inputBackgroundColor};
 border-color:${colors.inputBackgroundColor} ;
color: ${colors.white};
font-size: 14px;
font-weight: bolder;
border: 0px solid;
width: 90%;
`;
export const TableHeader = styled.th`
  color: ${colors.white};
 font-weight: bolder;
 font-size: 24px;
 background-color: ${colors.inputBackgroundColor};
`;
export const TableData = styled.td`
color: ${colors.white};
font-size: 16px;
font-weight: 800; 
`;
export const CreateAgent = styled.label`
  font-size: 3em;
  color: ${colors.activeBlue};
  position: relative;
  //width: 10vw;
 font-weight: bolder;
 font-size: 16px;
 margin-left: 10px;
`;

export const AddAgentTitle = styled.label`
  font-size: 3em;
  color: ${colors.white};
 font-weight: bolder;
 font-size: 24px;
`;
export const AddAgentInputTitle = styled.label`
  font-size: 3em;
  color: ${colors.subTitleColor};
 font-weight: bolder;
 font-size: 16px;
`;

export const InputText = styled.input`
 background-color:${colors.splitLineColor};
 border-color:${colors.splitLineColor} ;
color: ${colors.white};
font-size: 14px;
font-weight: bolder;
border: 0px solid;
width: 200px;
border-radius: 10px;
`;
export const OrganizationItem = styled.li`
  font-size: 3em;
  color: ${colors.white};
 font-weight: bolder;
 font-size: 16px;
 width: 100%;
 text-align: center;
`;

export const IconMenuTitle = styled.label`
font-size: 14px;
font-weight: 800; 
margin-left: 5px;
`;

export const TextArea = styled.textarea`
 background-color:${colors.splitLineColor};
 border-color:${colors.splitLineColor} ;
color: ${colors.white};
font-size: 14px;
font-weight: bolder;
border: 0px solid;
width: 200px;
border-radius: 10px;
`;
