import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Card } from '@mui/material';
import { colors } from '../../theme';
import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import {
    AddAgentTitle,
    AddAgentInputTitle,
    InputText,
    OrganizationItem
} from './styles';
import './index.css';
const CampaignAction = ({
    active,
    handleClose,
    title,
    setCampaingActionInput,
    campaingActionInput,
    handleAction,
    campaignAction
}) => {
    
    return (
        <>
            <Dialog

                open={active}
            >
                <DialogTitle style={{
                    backgroundColor: colors.inputBackgroundColor,
                    color: colors.white
                }}>
                    <AddAgentTitle>{title}</AddAgentTitle></DialogTitle>
                <DialogContent style={{ backgroundColor: colors.inputBackgroundColor }}>
                    <Container fluid>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Name</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='Enter name'
                                    className='agentSearchClass'
                                    value={campaingActionInput}
                                    onChange={(e)=>{
                                        setCampaingActionInput(e.target.value);
                                    }}
                                />
                            </Col>
                        </Row>
                        
                    </Container>
                </DialogContent>
                <DialogActions style={{ backgroundColor: colors.inputBackgroundColor,paddingBottom:16 }}>
                <Button onClick={()=>{
                   handleAction();
                }}  
                style={{
                    color:colors.white,
                    fontWeight:"bolder"
                }}
                
                > {campaignAction == "generate" ? "Generate":"Transcribe"}</Button>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>

            {/* <Dialog

                open={showOrganizationSelection}

            >
                <DialogTitle style={{
                    backgroundColor: colors.inputBackgroundColor,
                    color: colors.white
                }}>
                    <AddAgentTitle>Choose Organization</AddAgentTitle></DialogTitle>
                <DialogContent style={{ backgroundColor: colors.inputBackgroundColor }}>
                    <Container fluid>
                        <Row style={{ width: "30vw", marginTop: 16 }} >

                            <Col>
                                <center>
                                    <InputText
                                        placeholder='Search organization'
                                        className='agentSearchClass'
                                        style={{ textAlign: "center", width: 200 }}
                                        onChange={(e) => {
                                            handleFilterOrganization(e.target.value);
                                        }}


                                    />
                                </center>

                            </Col>
                        </Row>

                        <ul style={{ width: "30vw", marginTop: 16, height: "40vh", listStyleType: "none", padding: 0 }} >

                            {localOrganizationFullList.length == 0 &&

                                <OrganizationItem>Organization not found</OrganizationItem>
                            }
                            {localOrganizationFullList.map((data, i) => {
                                return (
                                    <Card
                                        onClick={() => {
                                            setSelectedOrganizationDetails(data)
                                            setShowOrganizationSelection(!showOrganizationSelection);

                                        }}
                                        style={{
                                            backgroundColor: colors.splitLineColor,
                                            marginTop: 20,
                                            padding: 10
                                        }}
                                    >
                                        <OrganizationItem>{data.name}</OrganizationItem>
                                        <OrganizationItem style={{ color: colors.activeBlue, fontWeight: "600", fontSize: 13 }}>ID : {data.id}</OrganizationItem>
                                    </Card>
                                );
                            })}


                        </ul>

                    </Container>

                </DialogContent>
                <DialogActions style={{ backgroundColor: colors.inputBackgroundColor }}>
                    <Button onClick={() => {
                        setShowOrganizationSelection(!showOrganizationSelection)
                    }}>Close</Button>
                </DialogActions>
            </Dialog> */}
        </>
    );
};

export default CampaignAction;