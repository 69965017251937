import React, { useState, useEffect } from "react";

import {
    Container,
    Row,
    Col,

} from 'react-bootstrap';
import './index.css';
import {
    Title,
    SearchContainerStyle,
    SearchInputText,
    TableHeader,
    TableData,
    CreateAgent,
    IconMenuTitle
} from './styles';
import { Button } from '@mui/material';
import { colors } from '../../theme';
import {

    Campaign,
    Search,

    Edit, Delete,
    PostAdd,
    Article,

    Construction
} from '@mui/icons-material';
import { Loader, DialogBox, Toaster } from '../../component';
import { useSelector, useDispatch } from 'react-redux';

import {
    getCampaignList,
    cleanCampaignListStatus,
    clearCreateCampaign,
    createCampaign,
    updateCampaign,
    clearDeleteCampaign,
    deleteCampaign,
    generateCampaign,
    clearCampaignAction,
    transcribeCampaign,

} from '../../redux/reducers/campaign';
import {

    getExecutorList,
    createModel,
    clearCreateExecutor,
    updateExecutor,
    deleteExecutor,
    clearDeleteExecutor,
    clearExecutorFull

} from '../../redux/reducers/executor';

import moment from "moment";
import CreateCampaignDialog from "./createCampainDialog";
import { toast } from 'react-toastify';
import ShowConstructorDetails from "./showConstructorDetails";
import CampaignAction from "./campaignActions";
import { clearModelListStatus, getModelList } from "../../redux/reducers/model";
import { clearConstructorFullListStatus, clearCreateConstructor, clearDeleteConstructor, createConstructor, deleteConstructor, getConstructorList, updateConstructor } from "../../redux/reducers/constructordata";
const ConstructorHome = () => {
    const dispatch = useDispatch();

    const organizationStore = useSelector((state) => state.organization);
    const campaignStore = useSelector((state) => state.campaign);
    const executorStore = useSelector((state) => state.executor);
    const modelStore = useSelector((state) => state.model);
    const constructordataStore = useSelector((state) => state.constructordata);


    const {
        campaignLoader,
        campaignList,
        campaignListStatus,
        campaignListMessage,

    } = campaignStore;
    const {
        executorLoader,
        executorFullList,
        executorFullState,
    } = executorStore;

    const {
        constructorLoader,
        constructorFullList,
        createConstructorStatus,
        createConstructorMessage,
        deleteConstructorStatus,
        deleteConstructorMessage,
        constructorFullListStatus
    } = constructordataStore;

    const [showConstructorDetailsDialog, setShowConstructorDetailsDialog] = useState(false);
    const [selectedConstructorForMoreDetails, setSelectedConstructorForMoreDetails] = useState({});
    const [constructorSearchText, setConstructorSearchText] = useState("");
    const [isForUpdateConstructor, setIsForUpdateConstructor] = useState(false);
    const [showCreateExecutor, setShowCreateExecutor] = useState(false);
    const [createConstructorName, setCreateConstructorName] = useState("");
    const [selectedNextConstructor, setSelectedNextConstructor] = useState({});
    const [selectedCampaignForCreate, setSelectedCampaignForCreate] = useState({});
    const [selectedExecutors, setSelectedExecutors] = useState([]);
    const [showSelecteConstructor, setShowSelecteConstructor] = useState(false);
    const [constructorListForCreate, setConstructorListForCreate] = useState([]);
    const [selectedContructorForCreate, setSelectedContructorForCreate] = useState({});
    const [campaignListLocal, setCampaignListLocal] = useState([]);
    const [showSelectCampaign, setShowSelectCampaign] = useState(false);
    const [showSelecteExecutor, setShowSelecteExecutor] = useState(false);
    const [localExecutorList, setLocalExecutorList] = useState([]);
    const [localFinalExecutorList, setLocalFinalExecutorList] = useState([]);
    const [showCreateConstructorWarning, setShowCreateConstructorWarning] = useState(false);
    const [showDeleteConstructor, setShowDeleteConstructor] = useState(false);
    const [localConstructorList, setLocalConstructorList] = useState([]);
    const [constructorSearch,setConstructorSearch]=useState("");





    useEffect(() => {
        dispatch(getConstructorList());
    }, []);
    useEffect(()=>{
        if (constructorFullListStatus == "success") {
            setLocalConstructorList([...constructorFullList]);
           dispatch(clearConstructorFullListStatus())
        } else if (constructorFullListStatus == "failed") {
            dispatch(clearConstructorFullListStatus())
        }
    },[constructorFullListStatus])
    useEffect(() => {
        if (createConstructorStatus == "success") {
            dispatch(getConstructorList());
            dispatch(clearCreateConstructor());
            setIsForUpdateConstructor(false);
            toast.success(isForUpdateConstructor ? "Update Successfully" : `Create Successfully`);
        } else if (createConstructorStatus == "failed") {
            toast.error(`${createConstructorMessage}`);
            dispatch(clearCreateConstructor());
        }
    }, [createConstructorStatus]);
    useEffect(() => {
        if (deleteConstructorStatus == "success") {
            dispatch(getConstructorList());
            dispatch(clearDeleteConstructor());
            setIsForUpdateConstructor(false);
            toast.success("Delete Successfully");
        } else if (deleteConstructorStatus == "failed") {
            toast.error(`${deleteConstructorMessage}`);
            dispatch(clearDeleteConstructor());
        }
    }, [deleteConstructorStatus]);
    useEffect(() => {
        if (executorFullState == "success") {
            console.log("executorFullList", executorFullList);
            const modExecutorList = [...executorFullList];
            let newModList = [];
            for (let obj of modExecutorList) {
                newModList.push({ ...obj, toggle: false });
            }
            setLocalExecutorList(newModList);
            setLocalFinalExecutorList(newModList);
            dispatch(clearExecutorFull());
        } else if (executorFullState == "failed") {
            dispatch(clearExecutorFull());
        }
    }, [executorFullState]);
    useEffect(() => {
        if (campaignListStatus == "success") {
            dispatch(cleanCampaignListStatus());
            setCampaignListLocal(campaignList)
            setShowCreateExecutor(!showCreateExecutor);
        } else if (campaignListStatus == "failed") {
            toast.error(`${campaignListMessage}`);
            dispatch(cleanCampaignListStatus());
        }
    }, [campaignListStatus]);













    const handleAction = () => {

        if (selectedExecutors.length && selectedCampaignForCreate && createConstructorName) {
            setShowCreateExecutor(!showCreateExecutor);
            setShowCreateConstructorWarning(!showCreateConstructorWarning)
        } else {
            toast.error(`Empty Field Found!`);
        }

    }
    const handleFilterConstructor = (text) => {
        if (text !== "") {
            let searchedConstructorList = constructorFullList.filter((item) =>
                item.name.toLowerCase().includes(text.toLowerCase()) ? item : null
            );
            setConstructorListForCreate([...searchedConstructorList]);

        } else {
            setConstructorListForCreate([...constructorFullList]);
        }
    }
    const handleFilterCampaign = (text) => {
        if (text !== "") {
            let searchedOrganizationList = campaignList.filter((item) =>
                item.name.toLowerCase().includes(text.toLowerCase()) ? item : null
            );
            setCampaignListLocal([...searchedOrganizationList]);

        } else {
            setCampaignListLocal([...campaignList]);
        }
    }
    const handleFilterExecutor = (text) => {
        if (text !== "") {
            let searchedOrganizationList = localFinalExecutorList.filter((item) =>
                item.name.toLowerCase().includes(text.toLowerCase()) ? item : null
            );
            setLocalExecutorList([...searchedOrganizationList]);

        } else {
            setLocalExecutorList([...localFinalExecutorList]);
        }
    }
    const checkToggleInExecutor = (data) => {
        let modList = localFinalExecutorList;
        for (let obj of modList) {
            if (obj.id == data.id) {
                obj.toggle = !obj.toggle;
            }
        }
        setLocalFinalExecutorList([...modList]);
    }
    const selectionDone = () => {
        let modList = [];
        for (let obj of localFinalExecutorList) {
            if (obj.toggle) {
                modList.push(obj.id)
            }
        }
        setSelectedExecutors(modList)

    }
    const handleSearch = (text) => {
        setConstructorSearch(text);
        if (text !== "") {
            let searchedConstructorList = constructorFullList.filter((item) =>
                item.name.toLowerCase().includes(text.toLowerCase()) ? item : null
            );
            setLocalConstructorList([...searchedConstructorList]);

        } else {
            setLocalConstructorList([...constructorFullList]);
        }
    }


    return (
        <div>
            <Container fluid style={{
                backgroundColor: colors.backgroundColorTheme,
                height: '100vh',
                overflow:"auto"
            }}>

                <Row >

                </Row>
                <Row style={{ marginTop: 20, marginLeft: 20 }}>

                    <Col  >
                        <Construction
                            style={{ width: 80, height: 80, color: "#fff" }} />
                        <Title >
                            Constructor
                        </Title>
                    </Col>
                    <Col style={{ textAlign: "right" }} >
                        <SearchContainerStyle

                        >
                            <Search style={{ width: 28, height: 28, color: "#fff" }} />
                            <SearchInputText
                                id="agentSearch"
                                type="text" value={constructorSearch}
                                onChange={(e) => { handleSearch(e.target.value) }}
                                placeholder="Search executor..."
                                className="agentSearchClass"
                                

                            />
                        </SearchContainerStyle>
                    </Col>


                </Row>
                <Row style={{ margin: 20 }}>
                    <Col style={{ textAlign: "right" }} onClick={() => {
                        // setShowCreateExecutor(!showCreateExecutor);
                        setConstructorListForCreate([...constructorFullList]);
                        dispatch(getCampaignList());
                        dispatch(getExecutorList());
                        setSelectedExecutors([]);
                        setSelectedContructorForCreate({});
                        setSelectedCampaignForCreate({});
                        setCreateConstructorName("");
                        setIsForUpdateConstructor(false);
                    }} >
                        <PostAdd
                            style={{ width: 24, height: 24, color: colors.activeBlue }} />
                        <CreateAgent >Create Constructor</CreateAgent>
                    </Col>
                </Row>
                <Row >
                    <div class="row" >
                        <div class="col-xs-12">
                            <div class="table-responsive" data-pattern="priority-columns">
                                <table
                                    summary="This table shows how to create responsive tables using RWD-Table-Patterns' functionality"
                                    class="table table-bordered table-hover"
                                >

                                    <thead>
                                        <tr style={{ backgroundColor: colors.inputBackgroundColor }}>
                                            <TableHeader>ID</TableHeader>
                                            <TableHeader data-priority="2">Name</TableHeader>
                                            <TableHeader data-priority="1">Campaign</TableHeader>
                                            <TableHeader>Constructor</TableHeader>
                                            <TableHeader data-priority="2">Created</TableHeader>
                                            <TableHeader data-priority="1"></TableHeader>

                                        </tr>
                                    </thead>

                                    <tbody >
                                        {localConstructorList.map((data, i) => {
                                            return (
                                                <tr>
                                                    <TableData>{data.id}</TableData>
                                                    <TableData>{data.name}</TableData>
                                                    <TableData>{data.campaign}</TableData>
                                                    <TableData>{data.next_constructor ? data.next_constructor : "None"}</TableData>


                                                    <TableData>{moment(data.created_at).format('LLL')}</TableData>

                                                    <TableData
                                                    // onClick={()=>{
                                                    //     dispatch(getOrganizationList());
                                                    //     setSelectedOrganizationDetails({});
                                                    //     setAddAgentName("");
                                                    // }}
                                                    >
                                                        <Col>

                                                            <Article
                                                                onClick={() => {
                                                                    setSelectedConstructorForMoreDetails(data);
                                                                    setShowConstructorDetailsDialog(!showConstructorDetailsDialog)

                                                                }}
                                                                style={{ width: 20, height: 20, color: colors.activeBlue }} />

                                                            <IconMenuTitle
                                                                onClick={() => {
                                                                    setSelectedConstructorForMoreDetails(data);
                                                                    setShowConstructorDetailsDialog(!showConstructorDetailsDialog)
                                                                }}
                                                                style={{ color: colors.activeBlue }}
                                                            >Details</IconMenuTitle>
                                                            <Edit
                                                                onClick={() => {
                                                                    setConstructorListForCreate([...constructorFullList]);
                                                                    dispatch(getCampaignList());
                                                                    dispatch(getExecutorList());
                                                                    setSelectedExecutors(data.executors);
                                                                    setSelectedContructorForCreate(data.next_constructor ? { name: data.next_constructor, id: data.next_constructor } : {});
                                                                    setSelectedCampaignForCreate({ name: data.campaign, id: data.campaign });
                                                                    setCreateConstructorName(data.name);
                                                                    setSelectedConstructorForMoreDetails(data);
                                                                    setIsForUpdateConstructor(true);


                                                                }}
                                                                style={{ width: 20, height: 20, color: "orange", marginLeft: 20 }} />
                                                            <IconMenuTitle
                                                                onClick={() => {
                                                                    setConstructorListForCreate([...constructorFullList]);
                                                                    dispatch(getCampaignList());
                                                                    dispatch(getExecutorList());
                                                                    setSelectedExecutors(data.executors);
                                                                    setSelectedContructorForCreate(data.next_constructor ? { name: data.next_constructor, id: data.next_constructor } : {});
                                                                    setSelectedCampaignForCreate({ name: data.campaign, id: data.campaign });
                                                                    setCreateConstructorName(data.name);
                                                                    setSelectedConstructorForMoreDetails(data);
                                                                    setIsForUpdateConstructor(true);
                                                                }}
                                                                style={{ color: "orange" }}
                                                            >Edit</IconMenuTitle>
                                                            <Delete
                                                                onClick={() => {
                                                                    setShowDeleteConstructor(true);
                                                                    setSelectedConstructorForMoreDetails(data);
                                                                }}

                                                                style={{ width: 20, height: 20, color: colors.FailedTheme, marginLeft: 20 }} />
                                                            <IconMenuTitle
                                                                onClick={() => {
                                                                    setShowDeleteConstructor(true);
                                                                    setSelectedConstructorForMoreDetails(data);
                                                                }}
                                                                style={{ color: colors.FailedTheme }}
                                                            >Remove</IconMenuTitle>

                                                        </Col>




                                                    </TableData>

                                                </tr>
                                            );
                                        })}


                                    </tbody>

                                </table>
                            </div>
                            {localConstructorList.length == 0 ? 
                                        
                                        <center> <label style={{marginTop:5,color:colors.inputBackgroundColor,fontWeight:"bolder"}}>Constructor not found</label></center>:null}
                        </div>
                    </div>



                </Row>


            </Container>
            <Loader active={constructorLoader || campaignLoader || executorLoader} title="Loading..." />
            <ShowConstructorDetails
                active={showConstructorDetailsDialog}
                handleClose={() => { setShowConstructorDetailsDialog(!showConstructorDetailsDialog) }}
                constructorDetails={selectedConstructorForMoreDetails}
            />
            <CreateCampaignDialog
                active={showCreateExecutor}
                handleClose={() => {
                    setShowCreateExecutor(!showCreateExecutor);
                }}
                title={isForUpdateConstructor ? "Update Executor" : "Create Executor"}
                createConstructorName={createConstructorName}
                setCreateConstructorName={(value) => {
                    setCreateConstructorName(value);
                }}
                // handleCreateInExecutor={handleCreateInExecutor}
                isForUpdateConstructor={isForUpdateConstructor}
                selectedNextConstructor={selectedNextConstructor}
                setSelectedNextConstructor={(value) => {
                    setSelectedNextConstructor(value)
                }}
                selectedCampaignForCreate={selectedCampaignForCreate}
                setSelectedCampaignForCreate={(value) => {
                    setSelectedCampaignForCreate(value)
                }}
                selectedExecutors={selectedExecutors}
                setSelectedExecutors={(value) => {
                    setSelectedExecutors(value)
                }}
                showSelecteConstructor={showSelecteConstructor}
                setShowSelecteConstructor={(value) => {
                    setShowSelecteConstructor(value)
                }}
                constructorListForCreate={constructorListForCreate}
                selectedContructorForCreate={selectedContructorForCreate}
                setSelectedContructorForCreate={(value) => {
                    setSelectedContructorForCreate(value)
                }}
                handleFilterConstructor={handleFilterConstructor}
                campaignListLocal={campaignListLocal}
                handleFilterCampaign={handleFilterCampaign}
                showSelectCampaign={showSelectCampaign}
                setShowSelectCampaign={(value) => {
                    setShowSelectCampaign(value)
                }}
                showSelecteExecutor={showSelecteExecutor}
                setShowSelecteExecutor={(value) => {
                    setShowSelecteExecutor(value)
                }}
                setLocalExecutorList={(value) => {
                    setShowSelecteExecutor(value)
                }}
                localExecutorList={localExecutorList}
                handleFilterExecutor={handleFilterExecutor}
                checkToggleInExecutor={checkToggleInExecutor}
                selectionDone={selectionDone}
                handleAction={handleAction}




            />
            <DialogBox
                active={showCreateConstructorWarning}
                title={isForUpdateConstructor ? "Update" : "Create"}
                subTitle={isForUpdateConstructor ? "Do you want update constructor?" : "Do you want to create constructor?"}
                twoButton={true}
                leftButtonClick={() => {
                    setShowCreateConstructorWarning(!showCreateConstructorWarning);
                    if (isForUpdateConstructor) {

                        dispatch(updateConstructor({
                            body: {
                                "next_constructor": selectedContructorForCreate.id,
                                "executors": selectedExecutors,
                                "campaign": selectedCampaignForCreate.id,
                                "name": createConstructorName
                            },
                            id: selectedConstructorForMoreDetails.id
                        }));
                    } else {
                        dispatch(createConstructor({
                            "next_constructor": selectedContructorForCreate.id,
                            "executors": selectedExecutors,
                            "campaign": selectedCampaignForCreate.id,
                            "name": createConstructorName
                        }));
                    }

                }}
                lefttButtonTitle={isForUpdateConstructor ? " Update" : "Create"}
                rightButtonClick={() => {
                    setShowCreateConstructorWarning(!showCreateConstructorWarning);
                }}
                rightButtonTitle={"Close"}

            />

            <DialogBox
                subTitle={"Do you want to remove constructor?"}
                title={"Remove"}
                active={showDeleteConstructor}
                twoButton={true}
                lefttButtonTitle="Yes"
                rightButtonTitle="No"
                rightButtonClick={() => {
                    setShowDeleteConstructor(false);

                }}
                leftButtonClick={() => {
                    setShowDeleteConstructor(false);
                    dispatch(deleteConstructor(selectedConstructorForMoreDetails.id))
                }}

            />


            <Toaster />


        </div>

    );
}

export default ConstructorHome;