import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Card } from '@mui/material';
import { colors } from '../../theme';
import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import {
    AddAgentTitle,
    AddAgentInputTitle,
    InputText,
    OrganizationItem,
    TextArea
} from './styles';
import './index.css';
import moment from 'moment';
const ShowCampain = ({
    active,
    handleClose,
    campainDetails,
    selectedResult,
    showResultDialog,
    setshowResultDialog,
    setSelectedResult
}) => {
 
    return (
        <>
            <Dialog

                open={active}
            >
                <DialogTitle style={{
                    backgroundColor: colors.inputBackgroundColor,
                    color: colors.white
                }}>
                    <AddAgentTitle>Details</AddAgentTitle></DialogTitle>
                <DialogContent style={{ backgroundColor: colors.inputBackgroundColor }}>
                    <Container fluid>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Agent</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='agent ID'
                                    className='agentSearchClass'
                                    value={campainDetails.agent ? campainDetails.agent: "None"}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Campaign</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={campainDetails?.campaign ? campainDetails?.campaign : "None"}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>constructor</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={campainDetails.constructor}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Executor</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='Executor ID'
                                    className='agentSearchClass'
                                    value={campainDetails.executor ? campainDetails.executor:"None"}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>result</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={campainDetails.result ?campainDetails.result : "None" }
                                    onClick={()=>{
                                        setSelectedResult(campainDetails.result );
                                        setshowResultDialog(!showResultDialog);
                                    }}
                                />
                            </Col>
                        </Row>
                       
                  
                    </Container>
                </DialogContent>
                <DialogActions style={{ backgroundColor: colors.inputBackgroundColor,paddingBottom:16 }}>
                {/* <Button onClick={()=>{
                    setShowAddAgentConfimation()
                }}  
                style={{
                    color:colors.white,
                    fontWeight:"bolder"
                }}
                
                > {isForUpdateAgent ? "Update":"Add"}</Button> */}
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>


            <Dialog

open={showResultDialog}


>
<DialogTitle style={{
    backgroundColor: colors.inputBackgroundColor,
    color: colors.white,

}}>
    <AddAgentTitle>Result</AddAgentTitle></DialogTitle>
<DialogContent style={{ backgroundColor: colors.inputBackgroundColor }}>
    <Container fluid>
        <Row style={{ marginTop: 16 }} >

            <Col>
                <center>
                    <TextArea
                        placeholder='Transcript'
                        className='agentSearchClass'
                        style={{
                            textAlign: "center",
                            width: 500,
                            minHeight: 500,
                            textAlign: "left",
                            lineHeight:3

                        }}
                        
                        value={`${selectedResult}`}
                        disabled
                        



                    />
                </center>

            </Col>
        </Row>



    </Container>

</DialogContent>
<DialogActions style={{ backgroundColor: colors.inputBackgroundColor }}>
   

    <Button onClick={() => {
        setshowResultDialog(!showResultDialog)
    }}>Close</Button>
</DialogActions>
</Dialog>

          
        </>
    );
};

export default ShowCampain;