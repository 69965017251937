import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Card } from '@mui/material';
import { colors } from '../../theme';
import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import {
    AddAgentTitle,
    AddAgentInputTitle,
    InputText,
    OrganizationItem,
    TextArea
} from './styles';
import './index.css';
const CreateAgentDialog = ({
    active,
    handleClose,
    title,
    createExecutorName,
    setCreateExecutorName,
    handleCreateInExecutor,
    isForUpdateCampain,
    setPrompt,
    prompt,
    promptInput,
    setPromptInput,
    showPromptDialog,
    setShowPromptDialog,
    isPromptDataForMoreDetails,
    selectedExecutorForCreate,
    setSelectedExecutorForCreate,
    executorListForCreate,
    showSelecteModel,
    setShowSelecteModel,
    handleFilterModel,
    showSelectCampaign,
    setShowSelectCampaign,
    campaignListLocal,
    selectedCampaign,
    setSelectedCampaign,
    handleFilterCampaign,
    localModelList,
    showModelSelectDialog,
    setShowModelSelectDialog,
    selectedModel,
    setSelectedModel,
    handleFilterModel1,
    isForUpdateExecutor

}) => {

    return (
        <>
            <Dialog

                open={active}
            >
                <DialogTitle style={{
                    backgroundColor: colors.inputBackgroundColor,
                    color: colors.white
                }}>
                    <AddAgentTitle>{title}</AddAgentTitle></DialogTitle>
                <DialogContent style={{ backgroundColor: colors.inputBackgroundColor }}>
                    <Container fluid>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Name</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='Enter name'
                                    className='agentSearchClass'
                                    value={createExecutorName}
                                    onChange={(e) => {
                                        setCreateExecutorName(e.target.value);
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Prompt</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='Enter prompt'
                                    className='agentSearchClass'
                                    value={prompt}
                                    onClick={(e) => {
                                        setShowPromptDialog(!showPromptDialog)
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Executor Connect</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='(Optional)'
                                    className='agentSearchClass'
                                    value={selectedExecutorForCreate?.name != undefined ? selectedExecutorForCreate?.name : ""}
                                    onClick={(e) => {
                                        // setShowPromptDialog(!showPromptDialog)
                                        setShowSelecteModel(!showSelecteModel);
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Campaign</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='Select Campaign'
                                    className='agentSearchClass'
                                    value={selectedCampaign?.name != undefined ? selectedCampaign?.name : ""}
                                    onClick={(e) => {
                                        // setShowPromptDialog(!showPromptDialog)
                                        setShowSelectCampaign(!showSelectCampaign);
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Model</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='Select Model'
                                    className='agentSearchClass'
                                    value={selectedModel?.name != undefined ? selectedModel?.name : ""}
                                    onClick={(e) => {
                                        // setShowPromptDialog(!showPromptDialog)
                                        setShowModelSelectDialog(!showModelSelectDialog);
                                    }}
                                />
                            </Col>
                        </Row>


                    </Container>
                </DialogContent>
                <DialogActions style={{ backgroundColor: colors.inputBackgroundColor, paddingBottom: 16 }}>
                    <Button onClick={() => {
                        handleCreateInExecutor()
                    }}
                        style={{
                            color: colors.white,
                            fontWeight: "bolder"
                        }}

                    > {isForUpdateExecutor ? "Update" : "Create"}</Button>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>

            <Dialog

                open={showPromptDialog}


            >
                <DialogTitle style={{
                    backgroundColor: colors.inputBackgroundColor,
                    color: colors.white,

                }}>
                    <AddAgentTitle>Prompt</AddAgentTitle></DialogTitle>
                <DialogContent style={{ backgroundColor: colors.inputBackgroundColor }}>
                    <Container fluid>
                        <Row style={{ marginTop: 16 }} >

                            <Col>
                                <center>
                                    <TextArea
                                        placeholder='Enter Prompt'
                                        className='agentSearchClass'
                                        style={{
                                            textAlign: "center",
                                            width: 500,
                                            minHeight: 500,
                                            textAlign: "left"

                                        }}
                                        onChange={(e) => {

                                            setPromptInput(e.target.value);
                                        }}
                                        value={promptInput}
                                        disabled={isPromptDataForMoreDetails ? true : false}



                                    />
                                </center>

                            </Col>
                        </Row>



                    </Container>

                </DialogContent>
                <DialogActions style={{ backgroundColor: colors.inputBackgroundColor }}>
                    {isPromptDataForMoreDetails ? null : <Button onClick={() => {
                        setShowPromptDialog(!showPromptDialog)
                        setPrompt(promptInput);

                    }}
                        style={{
                            color: colors.white,
                            fontWeight: "bolder"
                        }}>Done</Button>}

                    <Button onClick={() => {
                        setShowPromptDialog(!showPromptDialog)
                    }}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog

                open={showSelecteModel}

            >
                <DialogTitle style={{
                    backgroundColor: colors.inputBackgroundColor,
                    color: colors.white
                }}>
                    <AddAgentTitle>Connect Model</AddAgentTitle></DialogTitle>
                <DialogContent style={{ backgroundColor: colors.inputBackgroundColor }}>
                    <Container fluid>
                        <Row style={{ width: "30vw", marginTop: 16 }} >

                            <Col>
                                <center>
                                    <InputText
                                        placeholder='Search model'
                                        className='agentSearchClass'
                                        style={{ textAlign: "center", width: 200 }}
                                        onChange={(e) => {
                                            handleFilterModel(e.target.value);
                                        }}


                                    />
                                </center>

                            </Col>
                        </Row>

                        <ul style={{ width: "30vw", marginTop: 16, height: "40vh", listStyleType: "none", padding: 0 }} >

                            {executorListForCreate.length == 0 &&

                                <OrganizationItem>Executor not found</OrganizationItem>
                            }
                            {executorListForCreate.map((data, i) => {
                                return (
                                    <Card
                                        onClick={() => {
                                            setSelectedExecutorForCreate(data)
                                            setShowSelecteModel(!showSelecteModel);

                                        }}
                                        style={{
                                            backgroundColor: colors.splitLineColor,
                                            marginTop: 20,
                                            padding: 10
                                        }}
                                    >
                                        <OrganizationItem>{data.name}</OrganizationItem>
                                        <OrganizationItem style={{ color: colors.activeBlue, fontWeight: "600", fontSize: 13 }}>ID : {data.id}</OrganizationItem>
                                    </Card>
                                );
                            })}


                        </ul>

                    </Container>

                </DialogContent>
                <DialogActions style={{ backgroundColor: colors.inputBackgroundColor }}>
                    <Button onClick={() => {
                        setSelectedExecutorForCreate({})
                        setShowSelecteModel(!showSelecteModel);

                    }}
                        style={{
                            color: colors.white,
                            fontWeight: "bolder"
                        }}>Clear</Button>
                    <Button onClick={() => {
                        setShowSelecteModel(!showSelecteModel)
                    }}>Close</Button>
                </DialogActions>
            </Dialog>

            <Dialog

                open={showSelectCampaign}

            >
                <DialogTitle style={{
                    backgroundColor: colors.inputBackgroundColor,
                    color: colors.white
                }}>
                    <AddAgentTitle>Choose Campaign</AddAgentTitle></DialogTitle>
                <DialogContent style={{ backgroundColor: colors.inputBackgroundColor }}>
                    <Container fluid>
                        <Row style={{ width: "30vw", marginTop: 16 }} >

                            <Col>
                                <center>
                                    <InputText
                                        placeholder='Search campain'
                                        className='agentSearchClass'
                                        style={{ textAlign: "center", width: 200 }}
                                        onChange={(e) => {
                                            handleFilterCampaign(e.target.value);
                                        }}


                                    />
                                </center>

                            </Col>
                        </Row>

                        <ul style={{ width: "30vw", marginTop: 16, height: "40vh", listStyleType: "none", padding: 0 }} >

                            {campaignListLocal.length == 0 &&

                                <OrganizationItem>Campaign not found</OrganizationItem>
                            }
                            {campaignListLocal.map((data, i) => {
                                return (
                                    <Card
                                        onClick={() => {
                                            setSelectedCampaign(data)
                                            setShowSelectCampaign(!showSelectCampaign);

                                        }}
                                        style={{
                                            backgroundColor: colors.splitLineColor,
                                            marginTop: 20,
                                            padding: 10
                                        }}
                                    >
                                        <OrganizationItem>{data.name}</OrganizationItem>
                                        <OrganizationItem style={{ color: colors.activeBlue, fontWeight: "600", fontSize: 13 }}>ID : {data.id}</OrganizationItem>
                                    </Card>
                                );
                            })}


                        </ul>

                    </Container>

                </DialogContent>
                <DialogActions style={{ backgroundColor: colors.inputBackgroundColor }}>
                    <Button onClick={() => {
                        setShowSelectCampaign(!showSelectCampaign)
                    }}>Close</Button>
                </DialogActions>
            </Dialog>

            <Dialog

                open={showModelSelectDialog}

            >
                <DialogTitle style={{
                    backgroundColor: colors.inputBackgroundColor,
                    color: colors.white
                }}>
                    <AddAgentTitle>Choose Model</AddAgentTitle></DialogTitle>
                <DialogContent style={{ backgroundColor: colors.inputBackgroundColor }}>
                    <Container fluid>
                        <Row style={{ width: "30vw", marginTop: 16 }} >

                            <Col>
                                <center>
                                    <InputText
                                        placeholder='Search Model'
                                        className='agentSearchClass'
                                        style={{ textAlign: "center", width: 200 }}
                                        onChange={(e) => {
                                            handleFilterModel1(e.target.value);
                                        }}


                                    />
                                </center>

                            </Col>
                        </Row>

                        <ul style={{ width: "30vw", marginTop: 16, height: "40vh", listStyleType: "none", padding: 0 }} >

                            {localModelList.length == 0 &&

                                <OrganizationItem>Model not found</OrganizationItem>
                            }
                            {localModelList.map((data, i) => {
                                return (
                                    <Card
                                        onClick={() => {
                                            setSelectedModel(data)
                                            setShowModelSelectDialog(!showModelSelectDialog);
                                        }}
                                        style={{
                                            backgroundColor: colors.splitLineColor,
                                            marginTop: 20,
                                            padding: 10
                                        }}
                                    >
                                        <OrganizationItem>{data.name}</OrganizationItem>
                                        <OrganizationItem style={{ color: colors.activeBlue, fontWeight: "600", fontSize: 13 }}>ID : {data.id}</OrganizationItem>
                                    </Card>
                                );
                            })}


                        </ul>

                    </Container>

                </DialogContent>
                <DialogActions style={{ backgroundColor: colors.inputBackgroundColor }}>
                    <Button onClick={() => {

                        setShowModelSelectDialog(!showModelSelectDialog);
                    }}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CreateAgentDialog;