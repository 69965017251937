import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Card } from '@mui/material';
import { colors } from '../../theme';
import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import {
    AddAgentTitle,
    AddAgentInputTitle,
    InputText,
    OrganizationItem,
    TextArea
} from './styles';
import './index.css';
import moment from 'moment';
const ShowCampain = ({
    active,
    handleClose,
    campainDetails,
    setShowTranscript,
    showTranscript,
    selectedTranscript,
    setSelectedTranscript
}) => {

    return (
        <>
            <Dialog

                open={active}
            >
                <DialogTitle style={{
                    backgroundColor: colors.inputBackgroundColor,
                    color: colors.white
                }}>
                    <AddAgentTitle>Details</AddAgentTitle></DialogTitle>
                <DialogContent style={{ backgroundColor: colors.inputBackgroundColor }}>
                    <Container fluid>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>ID</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={campainDetails.id}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Create At</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={moment(campainDetails.created_at).format('LLL')}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Name</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={campainDetails.name}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Agent</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={campainDetails.agent}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Campaign</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={campainDetails.campaign}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Construction</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={campainDetails.constructor}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>URL</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={campainDetails.url ? campainDetails.url : "None"}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Audio File</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={campainDetails.audio_file ? campainDetails.audio_file : "None"}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Transcript</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={campainDetails.transcript ? campainDetails.transcript : "None"}
                                    onClick={()=>{
                                        setShowTranscript(!showTranscript);
                                        setSelectedTranscript(campainDetails.transcript)
                                    }}
                                />
                            </Col>
                        </Row>




                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Transcript Done</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={campainDetails.is_transcript_done}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Total Length</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={campainDetails.total_length}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Executed</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={campainDetails.is_it_executed}
                                    disabled
                                />
                            </Col>
                        </Row>





                    </Container>
                </DialogContent>
                <DialogActions style={{ backgroundColor: colors.inputBackgroundColor, paddingBottom: 16 }}>

                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>


            <Dialog

                open={showTranscript}


            >
                <DialogTitle style={{
                    backgroundColor: colors.inputBackgroundColor,
                    color: colors.white,

                }}>
                    <AddAgentTitle>Transcript</AddAgentTitle></DialogTitle>
                <DialogContent style={{ backgroundColor: colors.inputBackgroundColor }}>
                    <Container fluid>
                        <Row style={{ marginTop: 16 }} >

                            <Col>
                                <center>
                                    <TextArea
                                        placeholder='Transcript'
                                        className='agentSearchClass'
                                        style={{
                                            textAlign: "center",
                                            width: 500,
                                            minHeight: 500,
                                            textAlign: "left"

                                        }}
                                        onChange={(e) => {

                                            setSelectedTranscript(e.target.value);
                                        }}
                                        value={selectedTranscript}
                                        disabled={false}



                                    />
                                </center>

                            </Col>
                        </Row>



                    </Container>

                </DialogContent>
                <DialogActions style={{ backgroundColor: colors.inputBackgroundColor }}>
                   

                    <Button onClick={() => {
                        setShowTranscript(!showTranscript)
                    }}>Close</Button>
                </DialogActions>
            </Dialog>


        </>
    );
};

export default ShowCampain;