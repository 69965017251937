import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Card } from '@mui/material';
import { colors } from '../../theme';
import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import {
    AddAgentTitle,
    AddAgentInputTitle,
    InputText,
    OrganizationItem
} from './styles';
import './index.css';
import moment from 'moment';
const ShowCampain = ({
    active,
    handleClose,
    executorDetails
}) => {
 
    return (
        <>
            <Dialog

                open={active}
            >
                <DialogTitle style={{
                    backgroundColor: colors.inputBackgroundColor,
                    color: colors.white
                }}>
                    <AddAgentTitle>Details</AddAgentTitle></DialogTitle>
                <DialogContent style={{ backgroundColor: colors.inputBackgroundColor }}>
                    <Container fluid>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>ID</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={executorDetails.id}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Name</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={executorDetails.name}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Create At</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={moment(executorDetails.created_at).format('LLL')}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Campaign</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={executorDetails.campaign}
                                    disabled
                                />
                            </Col>
                        </Row>
                      
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Prompt</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={executorDetails?.prompt}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Executor Connect</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='Executor Connection'
                                    className='agentSearchClass'
                                    value={executorDetails?.executor_connect ? executorDetails?.executor_connect : "None"}
                                    disabled
                                /> 
                            </Col>
                        </Row>
                        
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Model Name</AddAgentInputTitle>
                            </Col>
                            <Col>
                            <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={executorDetails?.model_name}
                                    disabled
                                />
                            </Col>
                        </Row>
                       
                        
                  
                    </Container>
                </DialogContent>
                <DialogActions style={{ backgroundColor: colors.inputBackgroundColor,paddingBottom:16 }}>
                {/* <Button onClick={()=>{
                    setShowAddAgentConfimation()
                }}  
                style={{
                    color:colors.white,
                    fontWeight:"bolder"
                }}
                
                > {isForUpdateAgent ? "Update":"Add"}</Button> */}
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>

          
        </>
    );
};

export default ShowCampain;