import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API, { setAuthToken } from '../../server/api';
import * as ApiRouter from '../../server/apiRoutes'

export const getConstructorList = createAsyncThunk(
  'constructordata/getConstructorList',
  async () => {
    const result = await API.get(ApiRouter.getConstructorList);
    console.log("result", JSON.stringify(result))
    return { result: result.data }
  }
);
export const createConstructor = createAsyncThunk(
  'constructordata/createConstructor',
  async (body) => {
    console.log("createConstructor", body)
    const result = await API.post(ApiRouter.getConstructorList, JSON.stringify(body));
    console.log("result", JSON.stringify(result))
    return { result: result.data }
  }
);
export const updateConstructor = createAsyncThunk(
  'constructordata/updateConstructor',
  async (data) => {
    console.log("createConstructor", data)
    const result = await API.put(ApiRouter.constructorModify(data.id), JSON.stringify(data.body));
    console.log("result", JSON.stringify(result))
    return { result: result.data }
  }
);

export const deleteConstructor = createAsyncThunk(
  'constructordata/deleteConstructor',
  async (constructorId) => {
    console.log("deleteConstructor", constructorId)
    const result = await API.delete(ApiRouter.constructorModify(constructorId));
    console.log("result", JSON.stringify(result))
    return { result: result.data }
  }
);





const constructordataSlice = createSlice({
  name: 'constructordata',
  initialState: {
    constructorLoader: false,
    constructorFullList: [],
    createConstructorStatus: "",
    createConstructorMessage: "",
    deleteConstructorStatus: "",
    deleteConstructorMessage: "",
    constructorFullListStatus:""

  },
  reducers: {

    clearCreateExecutor: (state) => {

    },
    clearCreateConstructor: (state) => {
      state.createConstructorStatus = "";
      state.createConstructorMessage = "";
    },
    clearDeleteConstructor: (state) => {
      state.deleteConstructorStatus = "";
      state.deleteConstructorMessage = "";
    },
    clearConstructorFullListStatus: (state) => {
      state.constructorFullListStatus = "";
    },


  },
  extraReducers: (builder) => {
    builder.addCase(getConstructorList.pending, (state) => {
      state.constructorLoader = true;
      state.constructorFullListStatus = "";
    });
    builder.addCase(getConstructorList.fulfilled, (state, action) => {
      state.constructorLoader = false;
      state.constructorFullList = action.payload.result;
      state.constructorFullListStatus = "success";
    });
    builder.addCase(getConstructorList.rejected, (state, action) => {
      state.constructorLoader = false;
      state.constructorFullListStatus = "failed";
    });
    builder.addCase(createConstructor.pending, (state) => {
      state.constructorLoader = true;
    });
    builder.addCase(createConstructor.fulfilled, (state, action) => {
      state.constructorLoader = false;
      state.createConstructorStatus = "success";
    });
    builder.addCase(createConstructor.rejected, (state, action) => {
      state.constructorLoader = false;
      state.createConstructorStatus = "failed";
      state.createConstructorMessage = action.error.message;
    });

    builder.addCase(updateConstructor.pending, (state) => {
      state.constructorLoader = true;
    });
    builder.addCase(updateConstructor.fulfilled, (state, action) => {
      state.constructorLoader = false;
      state.createConstructorStatus = "success";
    });
    builder.addCase(updateConstructor.rejected, (state, action) => {
      state.constructorLoader = false;
      state.createConstructorStatus = "failed";
      state.createConstructorMessage = action.error.message;
    });
    builder.addCase(deleteConstructor.pending, (state) => {
      state.constructorLoader = true;
    });
    builder.addCase(deleteConstructor.fulfilled, (state, action) => {
      state.constructorLoader = false;
      state.deleteConstructorStatus = "success";
    });
    builder.addCase(deleteConstructor.rejected, (state, action) => {
      state.constructorLoader = false;
      state.deleteConstructorStatus = "failed";
      state.deleteConstructorMessage = action.error.message;
    });
  },
});
export const {
  clearCreateExecutor,
  clearCreateConstructor,
  clearDeleteConstructor,
  clearConstructorFullListStatus
} = constructordataSlice.actions;
export default constructordataSlice.reducer;
