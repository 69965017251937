
const colors ={
    textColor:"#1d1b23",
    backgroundColorTheme:"#030B19",
    white:"#fff",
    inputTitleColor:"#B9BDC7",
  inputPlaceHolderColor:"#48505E",
  inputBackgroundColor:"#2B2F38",
  splitLineColor:"#383E49",
  blueWithLight:"#B8D1FC",
  blueWithBright:"#4988F6",
  activeBlue:"#1B6AF4",
  subTitleColor:"#858D9D",
  FailedTheme:"#F04438",
  generate:"#327da8",
  transcribe:"#a8325c"

}
export default colors;