import React from 'react';
import {Dialog,DialogTitle,DialogContent,DialogActions,Button} from '@mui/material';
import { colors } from '../../theme';

const DialogBox = ({
  active,
  title,
  subTitle,

  lefttButtonTitle,
  rightButtonTitle,
  leftButtonClick,
  rightButtonClick,
  twoButton
}) => {

  return (
   
      <Dialog 
    
      open={active} >
        <DialogTitle
          style={{
            backgroundColor: colors.inputBackgroundColor,
            color: colors.white,
            fontWeight:"bolder",
            fontSize:24
        }}
        >{title}</DialogTitle>
        <DialogContent
          style={{
            backgroundColor: colors.inputBackgroundColor,
            color: colors.white,
            fontSize:14
        }}
        > 
          {subTitle}
        </DialogContent>
        <DialogActions
          style={{
            backgroundColor: colors.inputBackgroundColor,
            color: colors.white
        }}
        >

{twoButton == true &&  <Button 
           style={{
            color:colors.white,
            fontWeight:"bolder"
        }}
          onClick={()=>{leftButtonClick()}}>{lefttButtonTitle}</Button>}
            
            <Button onClick={()=>{rightButtonClick()}}>{rightButtonTitle}</Button>
         
        </DialogActions>
      </Dialog>
  );
};

export default DialogBox;