import React from "react";
import {
    createBrowserRouter,
    RouterProvider,
    useA
    } from 'react-router-dom';
import * as Pages from './pages';
import * as Routing from './routes';
import Authenticated from "./authRoute";



const routes = createBrowserRouter([
    {
        path : Routing.Home,
        element:<Authenticated><Pages.Home /></Authenticated> ,
        children:[
            {
                path : Routing.agent,
                element:<Pages.AgentHome />,
            },
            {
                path : Routing.Campain,
                element:<Pages.CampainHome />,
            } ,
            {
                path : Routing.Executor,
                element:<Pages.ExecutorHome />,
            },
            {
                path : Routing.Model,
                element:<Pages.ModelHome />,
            },
            {
                path : Routing.Constructor,
                element:<Pages.ConstructorHome />,
            },
            {
                path : Routing.Manifest,
                element:<Pages.ManifestHome />,
            },
            {
                path : Routing.ManifestResult,
                element:<Pages.ManifestResultHome />,
            },
            {
                path : Routing.Profile,
                element:<Authenticated><Pages.ProfileHome /></Authenticated>,
            },
            {
                path : Routing.Organization,
                element:<Pages.OrganizationHome />,
            }
        ]
    },
    {
        path : Routing.Login,
        element:<Pages.Login />,
        errorElement:<Pages.NotFound/>
    },
   
],{
     
        basename:Routing.Login
      
});       
   
const Routers = ()=>{
    return(
        <RouterProvider router={routes} />
    );

}

export default Routers;