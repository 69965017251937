import React,{useState,useMemo} from "react";
import { Link } from 'react-router-dom';
import * as Routing from '../../routes/routes';
import {
    Stack,
    Button,
    Container,
    Row,
    Col,
    ThemeProvider
} from 'react-bootstrap';
import {

AutoStories,
ArrowBack,
AccountBox,
VoiceOverOff
} from '@mui/icons-material';
import { colors } from "../../theme";
import { useNavigate } from 'react-router-dom';
const UnauthHome = () => {

    const navigate = useNavigate();

  
    return (
        <div> 
            <Container
            
            >


                <Row >
                    <Col lg={12} >
                        <center>
                        <VoiceOverOff
                        style={{width:100,height:100,color:colors.white}}
                        />
                        <h4>Unauthorized User</h4>

                        <Button 
                            style={{
                                backgroundColor:colors.inputBackgroundColor,
                                width:200,
                                borderWidth:0,
                                marginTop:30
                            }}
                            onClick={() => {
                                navigate(Routing.Login);
                            }}>
                               
                              <label style={{fontWeight:"bolder"}}>Login Again</label>  </Button>

                        </center>
                    </Col>
                  
                </Row>
            </Container>

        </div>

    );
}

export default UnauthHome;