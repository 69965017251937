import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Card } from '@mui/material';
import { colors } from '../../theme';
import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import {
    AddAgentTitle,
    AddAgentInputTitle,
    InputText,
    OrganizationItem
} from './styles';
import './index.css';
import moment from 'moment';
const ShowCampain = ({
    active,
    handleClose,
    constructorDetails
}) => {

    return (
        <>
            <Dialog

                open={active}
            >
                <DialogTitle style={{
                    backgroundColor: colors.inputBackgroundColor,
                    color: colors.white
                }}>
                    <AddAgentTitle>Details</AddAgentTitle></DialogTitle>
                <DialogContent style={{ backgroundColor: colors.inputBackgroundColor }}>
                    <Container fluid>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>ID</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={constructorDetails.id}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Name</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={constructorDetails.name}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Create At</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={moment(constructorDetails.created_at).format('LLL')}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Campaign</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={constructorDetails.campaign}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Exectors</AddAgentInputTitle>
                            </Col>
                            <Col>
                                {constructorDetails?.executors != null ? 
                                <>
                                {constructorDetails?.executors.map((data, i) => {
                                    return (
                                        <InputText
                                        placeholder='campain ID'
                                        className='agentSearchClass'
                                        value={data}
                                        disabled
                                        style={{marginTop:10}}
                                    />
                                    )})}
                                    </>
                                 : <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={"None"}
                                    disabled
                                />}

                            </Col>
                        </Row>






                    </Container>
                </DialogContent>
                <DialogActions style={{ backgroundColor: colors.inputBackgroundColor, paddingBottom: 16 }}>
                    {/* <Button onClick={()=>{
                    setShowAddAgentConfimation()
                }}  
                style={{
                    color:colors.white,
                    fontWeight:"bolder"
                }}
                
                > {isForUpdateAgent ? "Update":"Add"}</Button> */}
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>


        </>
    );
};

export default ShowCampain;