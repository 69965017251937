export const Login ="/login/"

//Agent
export const GetAgentList = "/agents/";
export const AddAgent = "/agents/";
export const updateAgent = (id)=>`/agents/${id}/`;
export const deleteAgent = (id)=>`/agents/${id}/`;

//Organization 
export const GetOrganizationList = "/organizations/"; 

//Campain
export const getCampaigns = `/campaigns/`;
export const createCampaign = `/campaigns/`;
export const updateCampaign = (id)=>`/campaigns/${id}/`;
export const deleteCampaign = (id)=>`/campaigns/${id}/`;
export const generateCampaign = (id)=>`/campaigns/${id}/generate/`;
export const transcribeCampaign = (id)=>`/campaigns/${id}/transcribe/`;

//Executor
export const getExecutorList = '/executors/';
export const updateExecutor = (id)=>`/executors/${id}/`;

//Model
export const getModelList = '/model_structs/';
export const updateModel = (id)=>`/model_structs/${id}/`;
export const deleteModel = (id)=>`/model_structs/${id}/`;
export const generateModel = (id)=>`/model_structs/${id}/generate/`;

//constructor
export const getConstructorList = '/constructors/';
export const constructorModify = (id)=>`/constructors/${id}/`;

//Manifest
export const getManifest = '/manifests/';
export const manifestModify =(id)=> `/manifests/${id}/`;
export const generateManifest= (id)=>`/manifests/${id}/generate/`;
export const transcribeManifest= (id)=>`/manifests/${id}/transcribe/`;

//Manifest Result
export const getManifestResult = '/manifest_results/';
