import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API,{setAuthToken} from '../../server/api';
import * as ApiRouter from '../../server/apiRoutes'

export const getOrganizationList = createAsyncThunk(
  'organization/getOrganizationList',
  async () => {
     const result =  await API.get(ApiRouter.GetOrganizationList);
     console.log("result",JSON.stringify(result))
     return {result:result.data}
  }              
);



const organizationSlice = createSlice({
  name: 'organization',
  initialState: {
    organizationLoader: false,
    organizationFullList:[],
    organizationListForAddAgentStatus:"",
    organizationListForAddAgentMessage:"",
    
  },
  reducers: {
  cleanOrganizationListForAddAgentStatus:(state,action)=>{
   state.organizationListForAddAgentStatus="";
   state.organizationListForAddAgentMessage="";
  }

  },
  extraReducers: (builder) => {


    builder.addCase(getOrganizationList.pending, (state) => {
      state.organizationLoader = true;
    });
    builder.addCase(getOrganizationList.fulfilled, (state, action) => {
      state.organizationLoader = false;
       state.organizationFullList = action.payload.result;
       state.organizationListForAddAgentStatus = "success";
    });
    builder.addCase(getOrganizationList.rejected, (state, action) => {
      state.organizationLoader = false;
      state.organizationListForAddAgentStatus = action.error.message;
      state.organizationListForAddAgentStatus = "failed";
   

    });
  },
});
export const {
  cleanOrganizationListForAddAgentStatus
} = organizationSlice.actions;
export default organizationSlice.reducer;
