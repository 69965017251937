import React,{useState,useMemo} from "react";
import { Link } from 'react-router-dom';
import * as Routing from '../../routes/routes';
import {
    Stack,
    Button,
    Container,
    Row,
    Col,
    ThemeProvider
} from 'react-bootstrap';
import {

AutoStories,
ArrowBack
} from '@mui/icons-material';
import { colors } from "../../theme";
import { useNavigate } from 'react-router-dom';
const NotFound = () => {
    const [count,setCount]=useState(0);
    const navigate = useNavigate();

  
    return (
        <div> 
            <Container>


                <Row >
                    <Col lg={12} >
                        <center>
                        <AutoStories
                        style={{width:100,height:100,color:colors.white}}
                        />
                        <h4>Page not found</h4>

                        <Button 
                            style={{
                                backgroundColor:colors.inputBackgroundColor,
                                width:200,
                                borderWidth:0,
                                marginTop:30
                            }}
                            onClick={() => {
                                navigate(-1);
                            }}>
                                 <ArrowBack
                                style={{ width: 30, height: 30, color: colors.white }}
                            />
                              <label style={{fontWeight:"bolder"}}>  Go Back</label>  </Button>

                        </center>
                    </Col>
                  
                </Row>
            </Container>

        </div>

    );
}

export default NotFound;