import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API,{setAuthToken} from '../../server/api';
import * as ApiRouter from '../../server/apiRoutes'

export const getAllAgentList = createAsyncThunk(
  'agent/getAllAgentList',
  async () => {
     const result =  await API.get(ApiRouter.GetAgentList);
     console.log("result",JSON.stringify(result))
     return {result:result.data}
  }              
);
export const addAgent = createAsyncThunk(
  'agent/addAgent',
  async (body) => {
    console.log(body);
     const result =  await API.post(ApiRouter.AddAgent,JSON.stringify(body));
     console.log("result",JSON.stringify(result))
     return {result:result.data}
  }              
);
export const updateAgent = createAsyncThunk(
  'agent/updateAgent',
  async (data) => { 
    console.log(data);
     const result =  await API.put(ApiRouter.updateAgent(data.id),JSON.stringify(data.body));
     console.log("result",JSON.stringify(result))
     return {result:result.data}
  }              
);
export const deleteAgent = createAsyncThunk(
  'agent/deleteAgent',
  async (id) => {
    console.log(id);
     const result =  await API.delete(ApiRouter.deleteAgent(id));
     console.log("result",JSON.stringify(result))
     return {result:result.data}
  }              
);



const agentSlice = createSlice({
  name: 'agent',
  initialState: {
    agentLoader: false,
    agentFullList:[],
    addAgentStatus:"",
    addAgentMessage:"",
    deleteAgentStatus:"",
    deleteAgentMessage:"",
    agentFullListStatus:""
    

  },
  reducers: {
  cleanaddAgentStatus:(state,action)=>{
    state.addAgentStatus="";
    state.addAgentMessage="";
  },
  cleanDeleteAgentStatus:(state,action)=>{
    state.deleteAgentStatus="";
    state.deleteAgentMessage="";
  },
  clearAgentFullListStatus:(state,action)=>{
    state.agentFullListStatus="";
  },

  },
  extraReducers: (builder) => {


    builder.addCase(getAllAgentList.pending, (state) => {
      state.agentLoader = true;
      state.agentFullListStatus="";
    });
    builder.addCase(getAllAgentList.fulfilled, (state, action) => {
      state.agentLoader = false;
      state.agentFullListStatus="success";
       state.agentFullList = action.payload.result;

    });
    builder.addCase(getAllAgentList.rejected, (state, action) => {
      state.agentLoader = false;
      state.agentFullListStatus="failed";
    });

    builder.addCase(addAgent.pending, (state) => {
      state.agentLoader = true;
    });
    builder.addCase(addAgent.fulfilled, (state, action) => {
       state.agentLoader = false;
       state.addAgentStatus = "success";
    });
    builder.addCase(addAgent.rejected, (state, action) => {
      console.log("action.error",action);
      state.agentLoader = false;
      state.addAgentStatus = "failed";
      state.addAgentMessage = action.error.message;
    });

    builder.addCase(updateAgent.pending, (state) => {
      state.agentLoader = true;
    });
    builder.addCase(updateAgent.fulfilled, (state, action) => {
       state.agentLoader = false;
       state.addAgentStatus = "success";
    });
    builder.addCase(updateAgent.rejected, (state, action) => {
   
      state.agentLoader = false;
      state.addAgentStatus = "failed";
      state.addAgentMessage = action.error.message;
    });

    builder.addCase(deleteAgent.pending, (state) => {
      state.agentLoader = true;
    });
    builder.addCase(deleteAgent.fulfilled, (state, action) => {
       state.agentLoader = false;
       state.deleteAgentStatus = "success";
    });
    builder.addCase(deleteAgent.rejected, (state, action) => {
   
      state.agentLoader = false;
      state.deleteAgentStatus = "failed";
      state.deleteAgentMessage = action.error.message;
    });
    
  },
});
export const {
  cleanaddAgentStatus,
  cleanDeleteAgentStatus,
  clearAgentFullListStatus
} = agentSlice.actions;
export default agentSlice.reducer;
