import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Card } from '@mui/material';
import { colors } from '../../theme';
import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import {
    AddAgentTitle,
    AddAgentInputTitle,
    InputText,
    OrganizationItem,
    TextArea
} from './styles';
import './index.css';
import moment from 'moment';
const ManifestOutput = ({
    active,
    handleClose,
    selectedManifestForMoreDetails

}) => {

    return (
        <>
            <Dialog

                open={active}
            >
                <DialogTitle style={{
                    backgroundColor: colors.inputBackgroundColor,
                    color: colors.white
                }}>
                    <AddAgentTitle>Details</AddAgentTitle></DialogTitle>
                <DialogContent style={{ backgroundColor: colors.inputBackgroundColor }}>
                    <Container fluid>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>ID</AddAgentInputTitle>
                            </Col>
                           
                        </Row>
                        <Row>
                        <iframe
  id="inlineFrameExample"
  title="Inline Frame Example"
  width="300"
  height="200"
  src="https://devtelai.goivr.net/media/audio/3f0b8679-76cd-404d-88ef-7b4f8ee3521a.mp3">
</iframe>

                        </Row>
                       








                    </Container>
                </DialogContent>
                <DialogActions style={{ backgroundColor: colors.inputBackgroundColor, paddingBottom: 16 }}>

                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>


          


        </>
    );
};

export default ManifestOutput;