import { useState, useCallback } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import './index.css'
const Loader = ({active,title}) => {
 
  return (
    <LoadingOverlay
      active={active}
      spinner
      overlay={true}
      //styles={{ content: { width: "80%", minHeight: "65%" } }}
      className={active ? 'displaySpinner':''}
  
      text={`${title}...`}
    />
     
  )
}

export default Loader