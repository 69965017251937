import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Card } from '@mui/material';
import { colors } from '../../theme';
import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import {
    AddAgentTitle,
    AddAgentInputTitle,
    InputText,
    OrganizationItem,
    TextArea
} from './styles';
import './index.css';
const CreateAgentDialog = ({
    active,
    handleClose,
    title,
    handleCreateInCampaign,
    isForUpdateModel,
    campaignListLocal,
    handleFilterCampaign,
    showSelectCampaign,
    setShowSelectCampaign,
    selectedCampaign,
    setSelectedCampaign,
    createModelName,
    setCreateModelName,
    showTrainDataDialog,
    setShowTrainDataDialog,
    trainDataSet,
    setTrainDataSet,
    trainDataSetFinal,
    setTrainDataSetFinal,
    isForGenerateModel,
    isTrainDataForMoreDetails
}) => {

    return (
        <>
            <Dialog

                open={active}
            >
                <DialogTitle style={{
                    backgroundColor: colors.inputBackgroundColor,
                    color: colors.white
                }}>
                    <AddAgentTitle>{title}</AddAgentTitle></DialogTitle>
                <DialogContent style={{ backgroundColor: colors.inputBackgroundColor }}>
                    <Container fluid>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Name</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='Enter name'
                                    className='agentSearchClass'
                                    value={createModelName}
                                    onChange={(e) => {
                                        setCreateModelName(e.target.value);
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Campaign</AddAgentInputTitle>
                            </Col>
                            <Col>

                                <InputText
                                    placeholder='Enter train data'
                                    className='agentSearchClass'
                                    onClick={() => {
                                        setShowSelectCampaign(!showSelectCampaign)
                                    }}
                                    value={selectedCampaign?.name}
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Train Data</AddAgentInputTitle>
                            </Col>
                            <Col>

                                <InputText
                                    placeholder='Enter train data'
                                    className='agentSearchClass'
                                    onClick={() => {
                                        setShowTrainDataDialog(true);
                                    }}
                                 value={trainDataSetFinal}
                                />
                            </Col>
                        </Row>
                    </Container>
                </DialogContent>
                <DialogActions style={{ backgroundColor: colors.inputBackgroundColor, paddingBottom: 16 }}>
                    <Button onClick={() => {
                        handleCreateInCampaign()
                    }}
                        style={{
                            color: colors.white,
                            fontWeight: "bolder"
                        }}

                    > {isForGenerateModel ? "Generate":isForUpdateModel ? "Update" : "Create"}</Button>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>

            <Dialog

                open={showSelectCampaign}

            >
                <DialogTitle style={{
                    backgroundColor: colors.inputBackgroundColor,
                    color: colors.white
                }}>
                    <AddAgentTitle>Choose Campaign</AddAgentTitle></DialogTitle>
                <DialogContent style={{ backgroundColor: colors.inputBackgroundColor }}>
                    <Container fluid>
                        <Row style={{ width: "30vw", marginTop: 16 }} >

                            <Col>
                                <center>
                                    <InputText
                                        placeholder='Search campain'
                                        className='agentSearchClass'
                                        style={{ textAlign: "center", width: 200 }}
                                        onChange={(e) => {
                                            handleFilterCampaign(e.target.value);
                                        }}


                                    />
                                </center>

                            </Col>
                        </Row>

                        <ul style={{ width: "30vw", marginTop: 16, height: "40vh", listStyleType: "none", padding: 0 }} >

                            {campaignListLocal.length == 0 &&

                                <OrganizationItem>Model not found</OrganizationItem>
                            }
                            {campaignListLocal.map((data, i) => {
                                return (
                                    <Card
                                        onClick={() => {
                                            setSelectedCampaign(data)
                                            setShowSelectCampaign(!showSelectCampaign);

                                        }}
                                        style={{
                                            backgroundColor: colors.splitLineColor,
                                            marginTop: 20,
                                            padding: 10
                                        }}
                                    >
                                        <OrganizationItem>{data.name}</OrganizationItem>
                                        <OrganizationItem style={{ color: colors.activeBlue, fontWeight: "600", fontSize: 13 }}>ID : {data.id}</OrganizationItem>
                                    </Card>
                                );
                            })}


                        </ul>

                    </Container>

                </DialogContent>
                <DialogActions style={{ backgroundColor: colors.inputBackgroundColor }}>
                    <Button onClick={() => {
                        setShowSelectCampaign(!showSelectCampaign)
                    }}>Close</Button>
                </DialogActions>
            </Dialog>



            <Dialog

                open={showTrainDataDialog}


            >
                <DialogTitle style={{
                    backgroundColor: colors.inputBackgroundColor,
                    color: colors.white,

                }}>
                    <AddAgentTitle>Train Data</AddAgentTitle></DialogTitle>
                <DialogContent style={{ backgroundColor: colors.inputBackgroundColor }}>
                    <Container fluid>
                        <Row style={{ marginTop: 16 }} >

                            <Col>
                                <center>
                                    <TextArea
                                        placeholder='Search campain'
                                        className='agentSearchClass'
                                        style={{
                                            textAlign: "center",
                                            width: 500,
                                            minHeight: 500,
                                            textAlign: "left"

                                        }}
                                        onChange={(e) => {
                                          
                                            setTrainDataSet(e.target.value);
                                        }}
                                        value={trainDataSet}
                                        disabled={isTrainDataForMoreDetails ? true :false}



                                    />
                                </center>

                            </Col>
                        </Row>



                    </Container>

                </DialogContent>
                <DialogActions style={{ backgroundColor: colors.inputBackgroundColor }}>
                    {isTrainDataForMoreDetails ? null :  <Button onClick={() => {
                        setShowTrainDataDialog(!showTrainDataDialog)
                        setTrainDataSetFinal(trainDataSet);

                    }}
                        style={{
                            color: colors.white,
                            fontWeight: "bolder"
                        }}>Done</Button>}
                   
                    <Button onClick={() => {
                        setShowTrainDataDialog(!showTrainDataDialog)
                    }}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CreateAgentDialog;