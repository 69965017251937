import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Card } from '@mui/material';
import { colors } from '../../theme';
import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import {
    AddAgentTitle,
    AddAgentInputTitle,
    InputText,
    OrganizationItem,
    TextArea
} from './styles';
import './index.css';
import moment from 'moment';
const ShowCampain = ({
    active,
    handleClose,
    
    modelDetails,
    setIsTrainDataForMoreDetails
}) => {
 
    return (
        <>
            <Dialog

                open={active}
            >
                <DialogTitle style={{
                    backgroundColor: colors.inputBackgroundColor,
                    color: colors.white
                }}>
                    <AddAgentTitle>Details</AddAgentTitle></DialogTitle>
                <DialogContent style={{ backgroundColor: colors.inputBackgroundColor }}>
                    <Container fluid>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>ID</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='Model ID'
                                    className='agentSearchClass'
                                    value={modelDetails.id}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Name</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='Model name'
                                    className='agentSearchClass'
                                    value={modelDetails.name}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Create At</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={moment(modelDetails.created_at).format('LLL')}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>Campaign</AddAgentInputTitle>
                            </Col>
                            <Col>
                                <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={modelDetails.campaign}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row style={{ width: "30vw", marginTop: 16 }} >
                            <Col>
                                <AddAgentInputTitle>train_data</AddAgentInputTitle>
                            </Col>
                            <Col>
                            <InputText
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={modelDetails.train_data ? modelDetails.train_data : "None"}
                                    //disabled
                                    onClick={() => {
                                        setIsTrainDataForMoreDetails();
                                    }}
                                />
                                {/* <TextArea
                                    placeholder='campain ID'
                                    className='agentSearchClass'
                                    value={modelDetails.train_data ?modelDetails.train_data : "None" }
                                    
                                    multiple={40}
                                    onChange={()=>{
                                        setIsTrainDataForMoreDetails(true);
                                    }}
                            >{modelDetails.train_data }</TextArea> */}
                            </Col>
                        </Row>
                        
                    </Container>
                </DialogContent>
                <DialogActions style={{ backgroundColor: colors.inputBackgroundColor,paddingBottom:16 }}>
                {/* <Button onClick={()=>{
                    setShowAddAgentConfimation()
                }}  
                style={{
                    color:colors.white,
                    fontWeight:"bolder"
                }}
                
                > {isForUpdateAgent ? "Update":"Add"}</Button> */}
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>

          
        </>
    );
};

export default ShowCampain;